'use strict';

moment.locale('ru');

var grandclassApp = angular.module('grandclassApp', [
    'ngResource',
    'ngTouch',
    'ngAnimate',
    'ngFileUpload',
    'ngSanitize',
    'ngPatternRestrict',
    'ui.router',
    'ui.mask',
    'ui.select',
    'ui.bootstrap',
    'tableSort',
    'angular-loading-bar',
    'gridster',
    'thatisuday.dropzone',
    'mm.foundation',
    'ng.ckeditor',
    'hl.sticky',
    'angular.css.injector',
    'dragularModule',
	  'dibari.angular-ellipsis',
    'zen.video-embed',
    'ngScrollbars',
    'slickCarousel',
    'yaMap',
    'updateMeta',
    'angular-google-analytics',
    'yandex-metrika',
    'chart.js',
    'timer',
    'youtube-embed',
    'datePicker',
    'angular-img-cropper'
]);

grandclassApp.config(function ($provide, $httpProvider) {
    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
});

grandclassApp.config(['AnalyticsProvider', function (AnalyticsProvider) {
    AnalyticsProvider.setAccount(GOOGLE_ANALYTICS);
    AnalyticsProvider.setPageEvent('$stateChangeSuccess');
}]).run(['Analytics', '$rootScope', '$state', function(Analytics) {}]);

grandclassApp.config(function ($metrikaProvider) {
    $metrikaProvider.configureCounter({id: YANDEX_METRIKA, webvisor: true});
});

grandclassApp.config(function ($stateProvider, $locationProvider) {
    $locationProvider.html5Mode({enabled: true, requireBase: false});

    $stateProvider
        .state('base', {
            abstract: true,
            controller: 'BaseController',
            template: '<ui-view></ui-view>',
            resolve: {
                organization: function(Organization) {
                    return Organization.query({location: location.origin}).$promise;
                }
            }
        })
        .state('base.app', {
            url: '/',
            abstract: true,
            controller: 'AppController',
            templateUrl: '/static/views/app/app.html',
            resolve: {
                profile: function(User) {
                    return User.profile().$promise;
                },
                chats: function(Chat) {
                    return Chat.query({'unread': true}).$promise;
                },
                unreadCount: function(Message) {
                    return Message.unread_count().$promise;
                },
                notifications: function(Notification) {
                    return Notification.query({filter_unopened: true}).$promise;
                },
                pages: function(StaticPage) {
                    return StaticPage.query().$promise;
                }
            },
            onEnter: function($rootScope, profile) {
                $rootScope.isAdmin = profile.role.value == 'admin';
                $rootScope.isTeacher = profile.role.value == 'teacher';
                $rootScope.isStudent = profile.role.value == 'student';
                $rootScope.isAnonymous = profile.role.value == 'anonymous';
            }
        })
        .state('base.app.index', {
            url: '',
            onEnter: function($rootScope, $state) { $state.go('base.app.courses.list') }
        })
        .state('base.app.admin', {
            url: 'admin',
            abstract: true,
            controller: 'AdminController',
            templateUrl: '/static/views/app/admin.html',
            resolve: {
                organization: function(Organization) {
                    return Organization.my().$promise;
                }
            }
        })
        .state('base.app.admin.info', {
            url: '/info',
            controller: 'AdminInfoController',
            templateUrl: '/static/views/app/admin.info.html',
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                }
            }
        })
        .state('base.app.admin.custom', {
            url: '/custom',
            controller: 'AdminCustomController',
            templateUrl: '/static/views/app/admin.custom.html',
            resolve: {
              slides: function(Slide) {
                  return Slide.query().$promise;
              }
            },
            onEnter: function($rootScope, $state) {
              if ($rootScope.isAnonymous || $rootScope.isStudent) {
                $state.go('base.app.calendar', {activeTab: 1});
              }
            }
        })
        .state('base.app.admin.details', {
            url: '/details',
            controller: 'AdminDetailsController',
            templateUrl: '/static/views/app/admin.details.html',
            onEnter: function($rootScope, $state) {
              if ($rootScope.isAnonymous || $rootScope.isStudent) {
                  $state.go('base.app.calendar', {activeTab: 1});
              }
            }
        })
        .state('base.app.admin.seo', {
            url: '/seo',
            controller: 'AdminSeoController',
            templateUrl: '/static/views/app/admin.seo.html',
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                }
            }
        })
        .state('base.app.courses', {
            url: 'courses',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('base.app.courses.list', {
            url: '',
            controller: 'CoursesController',
            templateUrl: '/static/views/app/courses.html',
            resolve: {
                courses: function(Course) {
                    return Course.get({limit: 12, my: true, or_is_shop: true}).$promise;
                },
                courseTags: function(Tag) {
                    return Tag.query().$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous) {
                    $state.go('base.app.calendar', {activeTab: 1});
                }
            }
        })
        .state('base.app.courses.shop', {
            url: '/shop',
            controller: 'CoursesShopController',
            templateUrl: '/static/views/app/courses.shop.html',
            resolve: {
                courses: function(Course) {
                    return Course.get({limit: 12, is_shop: true}).$promise;
                },
                courseTags: function(Tag) {
                    return Tag.query().$promise;
                }
            }
        })
        .state('base.app.courses.edit', {
            url: '/:id/edit',
            abstract: true,
            controller: 'CoursesEditController',
            templateUrl: '/static/views/app/courses.edit.html',
            resolve: {
                course: function (Course, $stateParams) {
                    return Course.get({id: $stateParams.id}).$promise;
                },
                groups: function (Group, $stateParams) {
                    return Group.query({course: $stateParams.id}).$promise;
                },
                allGroups: function(Group, $stateParams) {
                    return Group.query().$promise;
                },
                anotherGroups: function(allGroups, $stateParams) {
                    var anotherGroups = [];
                    angular.forEach(allGroups, function(group) {
                        if (group.course !== $stateParams.id) {
                            anotherGroups.push(group);
                        }
                    });
                    return anotherGroups;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.courses.view', {
            url: '/:id/:slug/view',
            abstract: true,
            controller: 'CoursesViewController',
            templateUrl: '/static/views/app/courses.view.html',
            resolve: {
                course: function(Course, $stateParams) {
                    return Course.get({id: $stateParams.id}).$promise;
                },
                modules: function(Module, $stateParams) {
                    return Module.query({course: $stateParams.id}).$promise;
                }
            }
        })
        .state('base.app.courses.view.about', {
            url: '/about',
            controller: 'CoursesViewAboutController',
            templateUrl: '/static/views/app/courses.view.about.html',
        })
        .state('base.app.courses.view.content', {
            url: '/content',
            controller: 'CoursesViewContentController',
            templateUrl: '/static/views/app/courses.view.content.html',
        })
        .state('base.app.courses.view.attachments', {
            url: '/attachments',
            controller: 'CoursesViewAttachmentsController',
            templateUrl: '/static/views/app/courses.view.attachments.html',
        })
        .state('base.app.courses.edit.properties', {
            url: '/properties',
            controller: 'CoursesEditPropertiesController',
            templateUrl: '/static/views/app/courses.edit.properties.html',
            resolve: {
                tags: function (Tag) {
                    return Tag.query().$promise;
                },
                teachers: function (User) {
                    return User.get({role: 'teacher'}).$promise;
                },
                allGroups: function(Group, $stateParams) {
                    return Group.query().$promise;
                },
                groups: function (Group, $stateParams) {
                    return Group.query({course: $stateParams.id}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.courses.edit.attachments', {
            url: '/attachments',
            controller: 'CoursesEditAttachmentsController',
            templateUrl: '/static/views/app/courses.edit.attachments.html',
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            }
        })
        .state('base.app.courses.edit.content', {
            url: '/content',
            controller: 'CoursesEditContentController',
            templateUrl: '/static/views/app/courses.edit.content.html',
            resolve: {
                modules: function(Module, $stateParams) {
                    return Module.query({course: $stateParams.id}).$promise;
                },
                chapters: function(Chapter) {
                    return Chapter.query().$promise;
                },
                tests: function(Test) {
                    return Test.query().$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.courses.edit.groups', {
            url: '/groups',
            controller: 'CoursesEditGroupsController',
            templateUrl: '/static/views/app/courses.edit.groups.html',
            resolve: {
              groups: function (Group, $stateParams) {
                  return Group.query({course: $stateParams.id}).$promise;
              }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.chapters', {
            url: 'chapters/:id',
            abstract: true,
            controller: 'ChaptersController',
            template: '<ui-view></ui-view>',
            resolve: {
                chapter: function(Chapter, $stateParams) {
                    return Chapter.get({id: $stateParams.id}).$promise;
                },
                course: function(Course, chapter) {
                    return Course.get({id: chapter.course}).$promise;
                },
                modules: function(Module, course) {
                    return Module.query({course: course.id}).$promise;
                },
                attachments: function(Attachment, chapter) {
                    return Attachment.query({chapter: chapter.id}).$promise;
                }
            }
        })
        .state('base.app.chapters.edit', {
            url: '/edit',
            controller: 'ChaptersEditController',
            templateUrl: '/static/views/app/chapters.edit.html',
            resolve: {
                tests: function(Test, course) {
                    return Test.query({course: course.id}).$promise;
                },
                chapters: function(Chapter, course) {
                    return Chapter.query({course: course.id}).$promise;
                },
                homeworks: function(Homework, course) {
                    return Homework.query({course: course.id}).$promise;
                },
                module: function(Module, chapter) {
                    return Module.get({id: chapter.module}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.chapters.view', {
            url: '',
            controller: 'ChaptersViewController',
            templateUrl: '/static/views/app/chapters.view.html',
            resolve: {
                chapter: function(Chapter, $stateParams) {
                    return Chapter.get({id: $stateParams.id}).$promise;
                }
            }
        })
        .state('base.app.homeworks', {
            url: 'homeworks/:id',
            abstract: true,
            controller: 'HomeworksController',
            template: '<ui-view></ui-view>',
            resolve: {
                homework: function(Homework, $stateParams) {
                    return Homework.get({id: $stateParams.id}).$promise;
                },
                course: function(Course, homework) {
                    return Course.get({id: homework.course}).$promise;
                },
                modules: function(Module, course) {
                    return Module.query({course: course.id}).$promise;
                },
                attachments: function(Attachment, homework) {
                    return Attachment.query({homework: homework.id}).$promise;
                }
            }
        })
        .state('base.app.homeworks.edit', {
            url: '/edit',
            controller: 'HomeworksEditController',
            templateUrl: '/static/views/app/homeworks.edit.html',
            resolve: {
                tests: function(Test, course) {
                    return Test.query({course: course.id}).$promise;
                },
                chapters: function(Chapter, course) {
                    return Chapter.query({course: course.id}).$promise;
                },
                homeworks: function(Homework, course) {
                    return Homework.query({course: course.id}).$promise;
                },
                module: function(Module, homework) {
                    return Module.get({id: homework.module}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.homeworks.view', {
            url: '',
            controller: 'HomeworksViewController',
            templateUrl: '/static/views/app/homeworks.view.html',
            resolve: {
                homework: function(Homework, $stateParams) {
                    return Homework.get({id: $stateParams.id}).$promise;
                },
                attempts: function(HomeworkAttempt, $stateParams) {
                    return HomeworkAttempt.query({homework: $stateParams.id}).$promise;
                },
                comments: function(HomeworkComment, attempts) {
                    if (attempts.length) {
                        return HomeworkComment.query({attempt: attempts[0].id})
                    } else {
                        return false;
                    }
                }
            }
        })
        .state('base.app.homeworks-attempt', {
            url: 'attempt/:id',
            controller: 'HomeworksAttemptController',
            templateUrl: '/static/views/app/homeworks-attempt.html',
            resolve: {
                attempt: function(HomeworkAttempt, $stateParams) {
                    return HomeworkAttempt.get({id: $stateParams.id}).$promise;
                },
                homework: function(Homework, attempt) {
                    return Homework.get({id: attempt.homework}).$promise;
                },
                comments: function(HomeworkComment, $stateParams) {
                    return HomeworkComment.query({attempt: $stateParams.id})
                }
            }
        })
        .state('base.app.tests', {
            url: 'tests',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('base.app.tests.edit', {
            url: '/:id/edit',
            abstract: true,
            controller: 'TestsEditController',
            templateUrl: '/static/views/app/tests.edit.html',
            resolve: {
                test: function(Test, $stateParams) {
                    return Test.get({id: $stateParams.id}).$promise;
                },
                tests: function(Test) {
                    return Test.query().$promise;
                },
                course: function(Course, test) {
                    return Course.get({id: test.course}).$promise;
                },
                modules: function(Module, course) {
                    return Module.query({course: course.id}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.tests.edit.properties', {
            url: '/properties',
            controller: 'TestsEditPropertiesController',
            templateUrl: '/static/views/app/tests.edit.properties.html',
            resolve: {
                chapters: function(Chapter, test) {
                    return Chapter.query({course: test.course}).$promise;
                },
                homeworks: function(Homework, test) {
                    return Homework.query({course: test.course}).$promise;
                },
                module: function(Module, test) {
                    return Module.get({id: test.module}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.tests.edit.questions', {
            url: '/questions',
            controller: 'TestsEditQuestionsController',
            templateUrl: '/static/views/app/tests.edit.questions.html',
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.tests.view', {
            url: '/:id',
            controller: 'TestsViewController',
            templateUrl: '/static/views/app/tests.view.html',
            resolve: {
                test: function(Test, $stateParams) {
                    return Test.get({id: $stateParams.id}).$promise;
                },
                course: function(Course, test) {
                    return Course.get({id: test.course}).$promise;
                },
                modules: function(Module, course) {
                    return Module.query({course: course.id}).$promise;
                }
            }
        })
        .state('base.app.tests.result', {
            url: '/:id/result',
            controller: 'TestsResultController',
            templateUrl: '/static/views/app/tests.result.html',
            resolve: {
                test: function(Test, $stateParams) {
                    return Test.get({id: $stateParams.id}).$promise;
                },
                course: function(Course, test) {
                    return Course.get({id: test.course}).$promise;
                },
                modules: function(Module, course) {
                    return Module.query({course: course.id}).$promise;
                },
                attempts: function(Attempt, $stateParams, profile) {
                    return Attempt.query({test: $stateParams.id, user: profile.id})
                }
            }
        })
        .state('base.app.tests.admin', {
            url: '/:id/perfomance',
            controller: 'TestsAdminController',
            templateUrl: '/static/views/app/tests.admin.html',
            resolve: {
                students: function (User) {
                    return User.get({role: 'student'}).$promise;
                },
                test: function(Test, $stateParams) {
                    return Test.get({id: $stateParams.id}).$promise;
                },
                attempts: function(Attempt, $stateParams) {
                    return Attempt.query({test: $stateParams.id})
                },
                course: function(Course, test) {
                    return Course.get({id: test.course}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.events', {
            url: 'events',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('base.app.events.list', {
            url: '',
            controller: 'EventsController',
            templateUrl: '/static/views/app/events.html',
            resolve: {
                events: function(Event) {
                    return Event.get({limit: 12}).$promise;
                },
                eventTags: function(Tag) {
                    return Tag.query().$promise;
                }
            }
        })
        .state('base.app.events.edit', {
            url: '/:id/edit',
            controller: 'EventsEditController',
            templateUrl: '/static/views/app/events.edit.html',
            resolve: {
                event: function(Event, $stateParams) {
                    return Event.get({id: $stateParams.id}).$promise;
                },
                tags: function(Tag) {
                    return Tag.query().$promise;
                },
                teachers: function (User) {
                    return User.get({role: 'teacher'}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.events.view', {
            url: '/:id/:slug/view',
            controller: 'EventsViewController',
            templateUrl: '/static/views/app/events.view.html',
            resolve: {
                event: function(Event, $stateParams) {
                    return Event.get({id: $stateParams.id}).$promise;
                }
            }
        })
        .state('base.app.webinars', {
            url: 'webinars',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('base.app.webinars.list', {
            url: '',
            controller: 'WebinarsController',
            templateUrl: '/static/views/app/webinars.html',
            resolve: {
                webinars: function(Webinar) {
                    return Webinar.get({limit: 12, my: true}).$promise;
                },
                webinarTags: function(Tag) {
                    return Tag.query().$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous) {
                    $state.go('base.app.calendar', {activeTab: 3});
                };
            }
        })
        .state('base.app.webinars.edit', {
            url: '/:id/edit',
            controller: 'WebinarsEditController',
            templateUrl: '/static/views/app/webinars.edit.html',
            resolve: {
                webinar: function(Webinar, $stateParams) {
                    return Webinar.get({id: $stateParams.id}).$promise;
                },
                tags: function(Tag) {
                    return Tag.query().$promise;
                },
                teachers: function (User) {
                    return User.get({role: 'teacher'}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.webinars.view', {
            url: '/:id/:slug/view',
            controller: 'WebinarsViewController',
            templateUrl: '/static/views/app/webinars.view.html',
            resolve: {
                webinar: function(Webinar, $stateParams) {
                    return Webinar.get({id: $stateParams.id}).$promise;
                },
                webinarLink: function(WebinarLink, webinar, profile) {
                    return WebinarLink.query({webinar: webinar.id, user: profile.id}).$promise;
                }
            }
        })
        .state('base.app.glossary', {
            url: 'glossary',
            controller: 'GlossaryController',
            templateUrl: '/static/views/app/glossary.html',
            resolve: {
                terms: function(Term) {
                    return Term.query().$promise;
                }
            }
        })
        .state('base.app.calendar', {
            url: 'calendar',
            controller: 'CalendarController',
            templateUrl: '/static/views/app/calendar.html',
            params: {
                activeTab: 1
            },
            resolve: {
                events: function(Event) {
                    return Event.get({limit: 1000000}).$promise;
                },
                webinars: function(Webinar) {
                    return Webinar.get({limit: 1000000}).$promise;
                },
                items: function(events, webinars, $stateParams) {
                    return (($stateParams.activeTab === 1) ? events : webinars)
                },
                tags: function (Tag) {
                    return Tag.query().$promise;
                },
                activeTab: function($stateParams) {
                    return $stateParams.activeTab;
                }
            }
        })
        .state('base.app.messages', {
            abstract: true,
            url: 'messages',
            template: '<ui-view></ui-view>'
        })
        .state('base.app.notifications', {
            url: 'notifications',
            controller: 'NotificationListController',
            templateUrl: '/static/views/app/notification.list.html',
            resolve: {
                notifications: function (Notification) {
                    return Notification.query().$promise;
                }
            }
        })
        .state('base.app.messages.list', {
            url: '',
            controller: 'MessagesController',
            templateUrl: '/static/views/app/messages.html',
            params: {
                participant: null
            },
            resolve: {
                chats: function (Chat) {
                    return Chat.query().$promise;
                },
                messages: function (Message, chats) {
                    if (chats.length) {
                        return Message.query({chat: chats[0].id}).$promise;
                    } else {
                        return null;
                    }
                },
                users: function(User) {
                    return User.get().$promise;
                },
                participant: function($stateParams) {
                    return $stateParams.participant;
                }
            }
        })
        .state('base.app.messages.campaigns', {
            url: '/campaigns',
            controller: 'CampaignsController',
            templateUrl: '/static/views/app/campaigns.html',
            resolve: {
                courses: function(Course) {
                    return Course.get({limit: 1000000}).$promise;
                },
                groups: function(Group) {
                    return Group.query().$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            }
        })
        .state('base.app.messages.sent', {
            url: '/sent',
            controller: 'CampaignsSentController',
            templateUrl: '/static/views/app/campaigns.sent.html',
            resolve: {
                students: function (User) {
                    return User.get().$promise;
                },
                courses: function(Course) {
                    return Course.get({limit: 1000000}).$promise;
                },
                groups: function(Group) {
                    return Group.query().$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                }
                ;
            }
        })
        .state('base.app.progress', {
            abstract: true,
            url: 'progress',
            controller: 'ProgressController',
            template: '<ui-view></ui-view>',
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous) {
                    $state.go('base.app.calendar', {activeTab: 1});
                }
                ;
            }
        })
        .state('base.app.progress.all', {
            url: '',
            controller: 'ProgressAllController',
            templateUrl: '/static/views/app/progress.all.html',
            resolve: {
                students: function (User) {
                    return User.get({role: 'student'}).$promise;
                },
                courses: function(Course) {
                    return Course.get({limit: 1000000}).$promise;
                },
                groups: function(Group) {
                    return Group.query().$promise;
                },
                data: function($http) {
                    return $http({url: "/api/progress/", method: "GET"}).then(function(response) {
                        return response.data;
                    });
                }
            }
        })
        .state('base.app.progress.time', {
            url: '/time/:courseId/:userId',
            controller: 'ProgressTimeController',
            templateUrl: '/static/views/app/progress.time.html',
            resolve: {
                chapterActions: function(ChapterAction, $stateParams) {
                    return ChapterAction.query({user: $stateParams.userId, course: $stateParams.courseId}).$promise;
                },
                user: function(User, $stateParams) {
                    return User.get({id: $stateParams.userId}).$promise;
                },
                course: function(Course, $stateParams) {
                    return Course.get({id: $stateParams.courseId}).$promise;
                }
            }
        })
        .state('base.app.profile', {
            url: 'profile',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('base.app.profile.edit', {
            url: '/edit',
            abstract: true,
            controller: 'ProfileEditController',
            templateUrl: '/static/views/app/profile.edit.html',
        })
        .state('base.app.profile.edit.info', {
            url: '/info',
            controller: 'ProfileEditInfoController',
            templateUrl: '/static/views/app/profile.edit.info.html',
            resolve: {
                tags: function(Tag) {
                    return Tag.query().$promise;
                },
                diploms: function(Diploma) {
                    return Diploma.query().$promise;
                }
            }
        })
        .state('base.app.profile.edit.password', {
            url: '/password',
            controller: 'ProfileEditPasswordController',
            templateUrl: '/static/views/app/profile.edit.password.html',
        })
        .state('base.app.users', {
            abstract: true,
            url: 'users',
            template: '<ui-view></ui-view>'
        })
        .state('base.app.users.profile', {
            url: '/:id',
            controller: 'UsersProfileController',
            templateUrl: '/static/views/app/users.profile.html',
            resolve: {
                user: function(User, $stateParams) {
                    return User.get({id: $stateParams.id}).$promise;
                },
                diploms: function(Diploma, $stateParams) {
                    return Diploma.query({user: $stateParams.id}).$promise;
                },
                isMyStudent: function(User, $stateParams) {
                    return User.my_student({id: $stateParams.id}).$promise;
                },
                notes: function(Note, $stateParams) {
                    return Note.query({user: $stateParams.id}).$promise;
                },
                tags: function (Tag) {
                    return Tag.query().$promise;
                },
                shopCourses: function(Course, $stateParams) {
                    return Course.get({limit: 1000000, my: $stateParams.id, is_shop: true}).$promise;
                },
                therapies: function(Course, $stateParams) {
                    return Course.get({limit: 1000000, my: $stateParams.id, is_shop: ''}).$promise;
                },
                webinars: function(Webinar, $stateParams) {
                   return Webinar.get({limit: 1000000, my: $stateParams.id}).$promise;
                },
                news: function(News, $stateParams) {
                    return News.get({limit: 1000000, type: 'blog', my: $stateParams.id}).$promise;
                }
            }
        })
        .state('base.app.users.list', {
            url: '',
            controller: 'UsersListController',
            templateUrl: '/static/views/app/users.list.html',
            resolve: {
                users: function(User) {
                    return User.get({ limit: 10, sort_name: 'full_name', sort_order: 'asc' }).$promise;
                },
                groups: function(Group) {
                    return Group.query().$promise;
                },
                tags: function (Tag) {
                    return Tag.query().$promise;
                }
            }
        })
        .state('base.app.statistic', {
            url: 'statistic',
            controller: 'StatisticController',
            templateUrl: '/static/views/app/statistic.html',
            resolve: {
                users: function(User) {
                    return User.get().$promise;
                }
            }
        })
        .state('base.app.groups', {
            abstract: true,
            url: 'groups',
            controller: 'GroupsController',
            template: '<ui-view></ui-view>',
            resolve: {
                groups: function(Group) {
                    return Group.query().$promise;
                }
            }
        })
        .state('base.app.groups.list', {
            url: '',
            controller: 'GroupsListController',
            templateUrl: '/static/views/app/groups.list.html',
        })
        .state('base.app.groups.view', {
            url: '/:id',
            controller: 'GroupsViewController',
            templateUrl: '/static/views/app/groups.view.html',
            resolve: {
                group: function(Group, $stateParams) {
                    return Group.get({id: $stateParams.id}).$promise;
                },
                tags: function (Tag) {
                    return Tag.query().$promise;
                }
            }
        })
        .state('base.app.faq', {
            url: 'faq',
            abstract: true,
            controller: 'FaqController',
            template: '<ui-view></ui-view>',
        })
        .state('base.app.faq.list', {
            url: '',
            controller: 'FaqListController',
            templateUrl: '/static/views/app/faq.list.html',
            resolve: {
                items: function(FAQ) {
                    return FAQ.query().$promise;
                }
            }
        })
        .state('base.app.faq.view', {
            url: '/:id',
            controller: 'FaqViewController',
            templateUrl: '/static/views/app/faq.view.html',
            resolve: {
                item: function(FAQ, $stateParams) {
                    return FAQ.get({id: $stateParams.id}).$promise;
                }
            }
        })
        .state('base.app.requests', {
            url: 'requests',
            controller: 'RequestsController',
            templateUrl: '/static/views/app/requests.html',
            resolve: {
                requests: function(AccessRequest) {
                    return AccessRequest.get().$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            }
        })
        .state('base.app.payments', {
            url: 'payments',
            controller: 'PaymentsController',
            templateUrl: '/static/views/app/payments.html',
            resolve: {
                payments: function(Payment, profile) {
                    return Payment.get({user: profile.id}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            }
        })
        .state('base.app.contacts', {
            url: 'contacts',
            controller: 'ContactsController',
            templateUrl: '/static/views/app/contacts.html',
        })
        .state('base.app.agreement', {
            url: 'agreement',
            controller: 'AgreementController',
            templateUrl: '/static/views/app/agreement.html',
        })
        .state('base.app.privacy', {
            url: 'privacy',
            controller: 'PrivacyController',
            templateUrl: '/static/views/app/privacy.html',
        })
        .state('base.app.partners', {
            abstract: true,
            url: 'partners',
            controller: 'PartnersController',
            template: '<ui-view></ui-view>',
            resolve: {
                partners: function(Partner) {
                    return Partner.query().$promise;
                }
            }
        })
        .state('base.app.partners.view', {
            url: '',
            templateUrl: '/static/views/app/partners.view.html'
        })
        .state('base.app.partners.edit', {
            url: '/edit',
            controller: 'PartnersEditController',
            templateUrl: '/static/views/app/partners.edit.html',
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.pages', {
            url: 'pages',
            abstract: true,
            controller: 'PagesController',
            template: '<ui-view></ui-view>',
            resolve: {
                tags: function(Tag) {
                    return Tag.query().$promise;
                }
            }
        })
        .state('base.app.pages.list', {
            url: '',
            controller: 'PagesListController',
            templateUrl: '/static/views/app/pages.list.html',
            resolve: {
                pages: function(StaticPage) {
                    return StaticPage.query().$promise;
                }
            }
        })
        .state('base.app.pages.edit', {
            url: '/:id/edit',
            controller: 'PagesEditController',
            templateUrl: '/static/views/app/pages.edit.html',
            resolve: {
                page: function(StaticPage, $stateParams) {
                    return StaticPage.get({id: $stateParams.id}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                };
            },
        })
        .state('base.app.pages.view', {
            url: '/:id/:slug/view',
            controller: 'PagesViewController',
            templateUrl: '/static/views/app/pages.view.html',
            resolve: {
                page: function(StaticPage, $stateParams) {
                    return StaticPage.get({id: $stateParams.id}).$promise;
                },
                teachers: function (User) {
                   return User.get({role: 'teacher'}).$promise;
                }
            }
        })
        .state('base.app.blog', {
            url: 'blog',
            controller: 'BlogListController',
            templateUrl: '/static/views/app/blog.list.html',
            resolve: {
                news: function(News) {
                    return News.get({limit:100000, type: 'blog'}).$promise;
                }
            }
        })
        .state('base.app.news', {
            url: 'news',
            abstract: true,
            controller: 'NewsController',
            template: '<ui-view></ui-view>',
        })
        .state('base.app.news.list', {
            url: '',
            controller: 'NewsListController',
            templateUrl: '/static/views/app/news.list.html',
            resolve: {
                news: function(News) {
                    return News.get({limit: 12, type: 'news'}).$promise;
                }
            }
        })
        .state('base.app.news.edit', {
            url: '/:id/edit',
            controller: 'NewsEditController',
            templateUrl: '/static/views/app/news.edit.html',
            resolve: {
                news: function(News, $stateParams) {
                    return News.get({id: $stateParams.id}).$promise;
                }
            },
            onEnter: function($rootScope, $state) {
                if ($rootScope.isAnonymous || $rootScope.isStudent) {
                    $state.go('base.app.calendar', {activeTab: 1});
                }
            },
        })
        .state('base.app.news.view', {
            url: '/:id/:slug/view',
            controller: 'NewsViewController',
            templateUrl: '/static/views/app/news.view.html',
            resolve: {
                news: function(News, $stateParams) {
                    return News.get({id: $stateParams.id}).$promise;
                }
            }
        })
        .state('base.app.certificates', {
            url: 'certificates',
            controller: 'CertificatesController',
            templateUrl: '/static/views/app/certificates.html',
            resolve: {
                diploms: function(Diploma) {
                    return Diploma.query().$promise;
                }
            }
        })
        .state('base.app.requisites', {
            url: 'requisites',
            controller: 'RequisitesController',
            templateUrl: '/static/views/app/requisites.html'
        })
        .state('base.enter', {
            abstract: true,
            templateUrl: '/static/views/app/enter.html'
        })
        .state('base.enter.register', {
            url: '/register',
            controller: 'RegisterController',
            templateUrl: '/static/views/app/register.html',
            resolve: {
                tags: function (Tag) {
                    return Tag.query().$promise;
                }
            }
        })
        .state('base.enter.login', {
            url: '/login',
            controller: 'LoginController',
            templateUrl: '/static/views/app/login.html',
        })
        .state('base.enter.password_reset', {
            url: '/password_reset',
            controller: 'PasswordResetController',
            templateUrl: '/static/views/app/password-reset.html',
        })
    });

grandclassApp.controller("BaseController",
    function ($scope, $rootScope, $state, $window,
              organization) {
        var win = angular.element($window);

        $rootScope.organization = organization[0];
        $rootScope.state = $state;
        $rootScope.windowWidth = win.width();
        $rootScope.slug = function (slug) {
            return $window.getSlug(slug);
        };

        win.bind('resize', function () {
            $rootScope.windowWidth = win.width();
            $rootScope.$apply('windowWidth');
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if (toState.name != fromState.name) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
            ;
            if (toState.name === 'base.app.index') {
                $rootScope.isMainPage = true;
            } else {
                $rootScope.isMainPage = false;
            }
            ;
        });

        $rootScope.isTouchDevice = (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    });

grandclassApp.directive("bnCmdEnter", function cmdEnterDirective() {

    return{
        link: link,
        require: "^?form",
        restrict: "A"
    };
    // I bind the JavaScript events to the view-model.
    function link( scope, element, attributes, formController ) {
        // If there is neither an expression nor a parent form (as determined
        // by the existence of a form controller), then just exit out of the
        // directive; there are no actions that we can take.
        if ( ! attributes.bnCmdEnter && ! formController ) {
            return;
        }
        // Start listening for the CMD+Enter event.
        element.on( "keydown", handleKeyEvent );
        // ---
        // PRIVATE METHODS.
        // ---
        // I travel up the DOM looking for the closest form element.
        function closestForm() {
            var parent = element.parent();
            while ( parent.length && ( parent[ 0 ].tagName !== "FORM" ) ) {
                parent = parent.parent();
            }
            return( parent );
        }
        // I listen for key events, looking for the CMD+Enter combination.
        function handleKeyEvent( event ) {
            // Is CMD+Enter event.
            if ( ( event.which === 13 ) && event.ctrlKey ) {
                event.preventDefault();
                attributes.bnCmdEnter
                    ? triggerExpression( event )
                    : triggerFormEvent()
                ;
            }
        }
        // I evaluate the expression passed-into the directive.
        // --
        // NOTE: The event is exposed as "$event". While I don't think the
        // calling context should have to know about the event object, this
        // seems to be a pattern that AngularJS uses.
        function triggerExpression( keyEvent ) {
            scope.$apply(
                function changeViewModel() {
                    scope.$eval(
                        attributes.bnCmdEnter,
                        {
                            $event: keyEvent
                        }
                    );
                }
            );
        }
        // I find the closest form element and trigger the "submit" event.
        function triggerFormEvent() {
            closestForm().triggerHandler( "submit" );
        }
    }
});
grandclassApp.directive('datepicker', function () {
	return {
		require: 'ngModel',
		scope : {
			range: '@',
			disabled: '@',
			modal: '@'
		},

		link: function(scope, element, attrs, ngModel) {

			scope.$datepicker = angular.element(element);
			scope.inputEnd = scope.$datepicker.find('.datepicker__input_end');

			scope.setModel = function(dateStart, dateEnd) {
				ngModel.$setViewValue({
					date_start: dateStart,
					date_end: dateEnd
				})
			};

			scope.$datepicker.dateRangePicker({
				stickyMonths: true,
				hoveringTooltip: false,
				autoClose: true,
				startDate: false,
				singleDate: !scope.range,
				singleMonth: !scope.range,
				format: 'DD.MM.YYYY',
				customTopBar: ' ',
				customArrowPrevSymbol: '<span class="prev icon-arrow-left"></span>',
				customArrowNextSymbol: '<span class="next icon-arrow-right"></span>',
				startOfWeek: 'monday',
				container: scope.modal ? 'body' : '.main',
				setValue: function(s,s1,s2){
					if ( scope.range ) {
						scope.setModel(s1, s2);
					} else {
						scope.setModel(s1, s1);
					}
				}
			});

			scope.$datepicker.on('datepicker-first-date-selected', function(event, obj){
				scope.$datepicker.addClass('datepicker_changed');
				if ( scope.range ) {
					scope.setModel(moment(obj.date1).format('DD.MM.YYYY'), '');
					scope.inputEnd.focus();
				}
			});

			scope.$on('$destroy', function(){
				if (scope.$datepicker.data('dateRangePicker')) {
					scope.$datepicker.data('dateRangePicker').destroy();
				}
			});
		}
	};
});

grandclassApp.directive('ngDebounce', function ($timeout) {
  return {
      restrict: 'A',
      require: 'ngModel',
      priority: 99,
      link: function (scope, elm, attr, ngModelCtrl) {
          if (attr.type === 'radio' || attr.type === 'checkbox') {
              return;
          }

          var delay = parseInt(attr.ngDebounce, 10);
          if (isNaN(delay)) {
              delay = 1000;
          }

          elm.unbind('input');

          var debounce;
          elm.bind('input', function () {
              $timeout.cancel(debounce);
              debounce = $timeout(function () {
                  scope.$apply(function () {
                      ngModelCtrl.$setViewValue(elm.val());
                  });
              }, delay);
          });
          elm.bind('blur', function () {
              scope.$apply(function () {
                  ngModelCtrl.$setViewValue(elm.val());
              });
          });
      }
  };
});

grandclassApp.directive('includeReplace', function () {
    return {
        require: 'ngInclude',
        restrict: 'A', /* optional */
        link: function (scope, el, attrs) {
            el.replaceWith(el.children());
        }
    };
});
grandclassApp.directive('magnificImage', function(){
    return {
        restrict: 'A',
        link: function (scope, iElement) {
            scope.$evalAsync(function () {
                iElement.magnificPopup({
                    delegate: 'a',
                    type: 'image',
                    mainClass: 'mfp-with-zoom',
                    zoom: {
                        enabled: true,
                        duration: 200,
                        easing: 'ease-in-out'
                    },
                    closeMarkup: '<button type="button" class="mfp-close icon-close"></button>',
                    titleSrc: 'title',
                    gallery:{
                        enabled: true,
                        arrowMarkup: '<button type="button" class="mfp-arrow mfp-arrow-%dir% icon-arrow-%dir%"></button>'
                    },
                    image: {
                        cursor: null
                    },
                    closeBtnInside: false
                });
            });
        }
    };
});
grandclassApp.directive('progresss', function () {
    return {
        restrict: 'E',
        scope: {
        	value: '@',
        	class: '@'
        },
        template: '<div class="progress"><div class="progress__bar"><span class="progress__meter"></span></div><div class="progress__value"></div></div>',
        link: function ($scope, element, attrs) {

        	if ($scope.class) {
        		element.find('.progress').addClass($scope.class);
        	}
            
        	function updateProgress() {
        		element.find('.progress__meter').css('width', $scope.value + '%');
        		element.find('.progress__value').text($scope.value + ' %');
        	};

        	$scope.$watch('value', updateProgress);

        }
    };
});
grandclassApp.directive('slideToggle', function () {
	return {
		restrict: 'A',      
		scope:{},
		controller: function ($scope) {}, 
		link: function(scope, element, attr) {
			element.bind('click', function() {                  
				var $slideBox = angular.element(attr.slideToggle);
				var slideDuration = parseInt(attr.slideToggleDuration, 10) || 200;
				$slideBox.stop().slideToggle(slideDuration);
			});
		}
	};  
});
grandclassApp.filter('html', function ($sce) {
    return function (content) {
        return $sce.trustAsHtml(content);
    };
});

grandclassApp.factory("AccessRequest", ["$resource",
    function ($resource) {
        return $resource("/api/access_requests/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Answer", ["$resource",
    function ($resource) {
        return $resource("/api/test_answers/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Attachment", ["$resource",
    function ($resource) {
        return $resource("/api/attachments/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Attempt", ["$resource", "$http",
    function ($resource, $http) {
        var resource = $resource("/api/test_attempts/:id/:action", {id: "@id"},
            {
                'put': {method: 'PUT'},
                'patch': {method: 'PATCH'},
                'check': {method: 'GET', params: {action: 'check'}},
            });

        return resource;
    }]);

grandclassApp.factory("CertSlide", ["$resource",
    function ($resource) {
        return $resource("/api/certslides/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Certificate", ["$resource",
    function ($resource) {
        return $resource("/api/certificates/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Chapter", ["$resource",
    function ($resource) {
        return $resource("/api/chapters/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("ChapterAction", ["$resource",
    function ($resource) {
        return $resource("/api/chapter_actions/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Chat", ["$resource", "$http",
    function ($resource, $http) {
        var resource = $resource("/api/chats/:id/:action", {id: "@id"},
            {
                'put': {method: 'PUT'},
                'patch': {method: 'PATCH'},
                'hide': {method: 'POST', params: {action: 'hide'}},
            });

        return resource;
    }]);

grandclassApp.factory("Choice", ["$resource",
    function ($resource) {
        return $resource("/api/test_choices/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Course", ["$resource",
    function ($resource) {
        return $resource("/api/courses/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"},
            });
    }]);

grandclassApp.factory("Diploma", ["$resource",
    function ($resource) {
        return $resource("/api/diploma/:id/", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Event", ["$resource",
    function ($resource) {
        return $resource("/api/events/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                get: {method: "GET", params: {page: 1}},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("EventTag", ["$resource",
    function ($resource) {
        return $resource("/api/event_tags/:id/", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("FAQ", ["$resource",
    function ($resource) {
        return $resource("/api/faqs/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"},
                categories: {method: "GET", url: "/api/faqs/categories", isArray: true},
            });
    }]);

grandclassApp.factory("Feedback", ["$resource",
    function ($resource) {
        return $resource("/api/feedbacks/:id/", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Group", ["$resource", "$http",
    function ($resource, $http) {
        var resource = $resource("/api/groups/:id/:action", {id: "@id"},
            {
                'put': {method: 'PUT'},
                'patch': {method: 'PATCH'},
                'batch_delete': {method: 'POST', params: {action: 'batch_delete'}},
            });

        return resource;
    }]);

grandclassApp.factory("Homework", ["$resource",
    function ($resource) {
        return $resource("/api/homeworks/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("HomeworkAttempt", ["$resource",
    function ($resource) {
        return $resource("/api/homework_attempts/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("HomeworkComment", ["$resource",
    function ($resource) {
        return $resource("/api/homework_comments/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Message", ["$resource",
    function ($resource) {
        return $resource("/api/messages/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"},
                unread_count: {method: "GET", url: "/api/messages/unread_count", isArray: false, ignoreLoadingBar: true},
                campaign: {method: "POST", url: "/api/messages/campaign"},
                get_sent: {method: "GET", url: "/api/messages/get_sent", isArray: false},
            });
    }]);

grandclassApp.factory("Module", ["$resource",
    function ($resource) {
        return $resource("/api/modules/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("News", ["$resource",
    function ($resource) {
        return $resource("/api/news/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Note", ["$resource",
    function ($resource) {
        return $resource("/api/notes/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Notification", ["$resource",
    function ($resource) {
        return $resource("/api/notifications/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Offer", ["$resource",
    function ($resource) {
        return $resource("/api/offers/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"},
                last_offer: {url: "/api/offers/last_offer", method: "GET", isArray: false},
                check: {method: "GET", url: "/api/offers/check", isArray: false},
            });
    }]);

grandclassApp.factory("Organization", ["$resource", "$http",
    function ($resource, $http) {
        var resource = $resource("/api/organizations/:id/:action", {id: "@id"},
            {
                'put': {method: 'PUT'},
                'patch': {method: 'PATCH'},
                'my': {method: 'GET', params: {action: 'my'}},
                'calendar': {method: 'GET', params: {action: 'calendar'}},
                'change_tariff': {method: 'PATCH', params: {action: 'change_tariff'}},
            });

        return resource;
    }]);

grandclassApp.factory("Partner", ["$resource",
    function ($resource) {
        return $resource("/api/partners/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Payment", ["$resource",
    function ($resource) {
        return $resource("/api/payments/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Question", ["$resource",
    function ($resource) {
        return $resource("/api/test_questions/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"},
                duplicate: {url: "/api/test_questions/:id/duplicate", method: "POST"}
            });
    }]);

grandclassApp.factory("Questionnaire", ["$resource",
    function ($resource) {
        return $resource("/api/questionnaires/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Slide", ["$resource",
    function ($resource) {
        return $resource("/api/slides/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("StaticPage", ["$resource",
    function ($resource) {
        return $resource("/api/static_pages/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Tag", ["$resource",
    function ($resource) {
        return $resource("/api/tags/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Tariff", ["$resource",
    function ($resource) {
        return $resource("/api/tariffs/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Term", ["$resource",
    function ($resource) {
        return $resource("/api/terms/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("Test", ["$resource",
    function ($resource) {
        return $resource("/api/tests/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("User", ["$resource", "$http",
    function ($resource, $http) {
        var resource = $resource("/api/users/:id/:action", {id: "@id"},
            {
                'put': {method: 'PUT'},
                'patch': {method: 'PATCH'},
                'login': {method: 'POST', params: {action: 'login'}},
                'register': {method: 'POST', params: {action: 'register'}},
                'profile': {method: 'GET', params: {action: 'profile'}},
                'sales': {method: 'GET', params: {action: 'sales'}, isArray: true},
                'export': {method: 'GET', params: {action: 'export'}},
                'batch_delete': {method: 'POST', params: {action: 'batch_delete'}},
                'password_reset': {method: 'POST', params: {action: 'password_reset'}},
                'batch_reset': {method: 'POST', params: {action: 'batch_reset'}},
                'password_change': {method: 'POST', params: {action: 'password_change'}},
                'my_student': {method: 'GET', params: {action: 'my_student'}},

            });

        return resource;
    }]);

grandclassApp.factory("Webinar", ["$resource",
    function ($resource) {
        return $resource("/api/webinars/:id", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("WebinarLink", ["$resource",
    function ($resource) {
        return $resource("/api/webinar_links/:id/", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.factory("WebinarTag", ["$resource",
    function ($resource) {
        return $resource("/api/webinar_tags/:id/", {id: "@id"},
            {
                put: {method: "PUT"},
                patch: {method: "PATCH"}
            });
    }]);

grandclassApp.controller("AdminController",
    function ($scope, $rootScope, $location,
    		  organization) {

    	$scope.resetHeader();
    	$rootScope.pageTitle = 'Настройки';
    	$scope.org = organization;

    	$scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Настройки';
    });

grandclassApp.controller("AdminCustomController",
    function ($scope, $rootScope, $state, $timeout, $q, $modal,
              slides,
    		  Organization, Upload, Slide, Tag) {

        $scope.org.files = {};
        $scope.slides = slides;
        $scope.sending = false;
        $scope.cropper = {};

        $scope.speeds = [
            {title: 'Медленно (12 с.)', value: 3},
            {title: 'Средне (8 с.)', value: 2},
            {title: 'Быстро (4 с.)', value: 1}
        ];

        $scope.update = function () {
            Tag.query().$promise.then(function (data) {
                $scope.tags = data;
            })
        };
        $scope.update();

        $scope.addTag = function() {
            var modalInstance = $scope.openModalAddTags({
                title: ''
            });
            modalInstance.result.then(function (instance) {
                Tag.save(instance).$promise.then($scope.updateTags)
            }, function(){});
        };

        $scope.updateTags = function() {
            Tag.query().$promise.then(function(data) {
                $scope.tags = data;
            });
        };


        $scope.editTag = function(tag) {
            var modalInstance = $scope.openModalAddTags(tag);
            modalInstance.result.then(function (instance) {
                Tag.patch(instance).$promise.then($scope.updateTags)
            }, function(){});
        };

        $scope.deleteTag = function(tag) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить метку: \"" + tag.title + "\"",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Tag.delete({id: tag.id}).$promise.then($scope.updateTags);
            }, function(){});
        };

        $scope.openModalAddTags = function(tag) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-tags.html',
                controller: 'ModalAddTagsController',
                resolve: {
                    instance: function() {
                        return tag;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_small'
            })
        };


        $scope.openModalEditTag = function(tag) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-tag.html',
                controller: 'ModalEditTagController',
                resolve: {
                    instance: function() {
                        return tag;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_small'
            })
        };

        $scope.deleteSlide = function(slideId) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить слайд?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Slide.delete({id: slideId}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.addSlide = function() {
            Slide.save({title: 'Название слайда'}).$promise.then(function () {
                $state.reload();
            })
        };

        // создает объект настроек для dropzone
    	$scope.createDzOptions = function(files, message) {
    		var options = {
				url: '/',
				autoProcessQueue: false,
				acceptedFiles : files,
				addRemoveLinks : false,
				uploadMultiple: false,
				dictDefaultMessage: '<span class="button button_outline">Загрузить</span>',
				previewTemplate: '<div></div>'
    		};
    		return options;
    	};

        // записывает добавленный файл в scope
        $scope.addFileToScope = function(field, file) {
            $scope.org.files[field] = {
                file: file,
                filename: file.name,
                isDeleted: false
            }
        };

        // записывает название уже имеющегося файла в scope
        $scope.addFilenameToScope = function(field, filename) {
             $scope.org.files[field] = {
                filename: filename
            }
        };



        // функция для сбора результатов при отправке
        $scope.saveData = function(file, dataAdd, dataDelete, dataField) {
            if (file) {
                if (file.file && !file.isDeleted) {
                    dataAdd[dataField] = file.file;
                };
                if (file.isDeleted) {
                    dataDelete[dataField] = null;
                }
            };
        };

    	$scope.dropzoneLogoOptions = $scope.createDzOptions('image/jpeg, images/jpg, image/png');
    	$scope.dropzoneCssOptions = $scope.createDzOptions('.css');
        $scope.dropzoneOfferOptions = $scope.createDzOptions('.pdf');

        $scope.openModalImageCropper = function() {
            return $modal.open({
                templateUrl: '/static/views/app/cropper_modal.html',
                controller: 'ImageCropperCtrl',
                backdrop: 'static',
                windowClass: 'modal__content_big',
                resolve: {
                    instance: function () {
                        return $scope.cropper;
                    }
                }
            });
        };

        $scope.getFileFromImage = function(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        };

        $scope.resizeImage = function(type, img, filename) {
                $scope.cropper.sourceImage = img;
                if (type == 'fileLogo') {
                    $scope.cropper.ratio = $scope.organization.logo_big_ratio;
                } else
                if (type == 'fileLogoSmall') {
                    $scope.cropper.ratio = $scope.organization.logo_small_ratio;
                }
                var modalInstance = $scope.openModalImageCropper();
                modalInstance.result.then(function (instance) {
                    let img = instance.croppedImage;
                    let file = $scope.getFileFromImage(img, filename);
                    $scope.addFileToScope(type, file);
                });
        };

        $scope.dropzoneLogoCallbacks = {
            'thumbnail': function(file, dataUrl) {
                $scope.resizeImage('fileLogo', dataUrl, file.name);
            }
        };

        $scope.dropzoneLogoSmallCallbacks = {
            'thumbnail': function(file, dataUrl) {
                $scope.resizeImage('fileLogoSmall', dataUrl, file.name);
            }
        };

        $scope.dropzoneCssCallbacks = {
            'addedfile': function(file) {
                $scope.addFileToScope('fileCss', file);
            }
        };

        $scope.dropzoneOfferCallbacks = {
            'addedfile': function(file) {
                $scope.addFileToScope('fileOffer', file);
            }
        };

        // Проверяет, имеются ли уже загруженные файлы
        if ($scope.org.logo_big) {
            $scope.addFilenameToScope('fileLogo', $rootScope.getFilename($scope.org.logo_big));
        };

        if ($scope.org.logo_small) {
            $scope.addFilenameToScope('fileLogoSmall', $rootScope.getFilename($scope.org.logo_small));
        };

        if ($scope.org.css) {
            $scope.addFilenameToScope('fileCss', $rootScope.getFilename($scope.org.css));
        };

        if ($scope.org.offer) {
            $scope.addFilenameToScope('fileOffer', $rootScope.getFilename($scope.org.offer));
        };

        $scope.buildDropzoneOptions = function() {
            angular.forEach($scope.slides, function(slide) {
                slide.dropzoneOptions = {
                    url: '/',
                    autoProcessQueue: false,
                    acceptedFiles : 'image/jpeg, images/jpg, image/png',
                    addRemoveLinks : false,
                    uploadMultiple: true,
                    thumbnailWidth: 285,
                    thumbnailHeight: null,
                    dictDefaultMessage: '<span class="button button_white">Сменить фото</span>',
                    previewTemplate: '<img hidden data-dz-thumbnail />'
                };
                slide.dropzoneCallbacks = {
                    'thumbnail': function(file, dataUrl) {
                       slide.coverFile = file;
                       slide.coverImage = dataUrl;
                    }
                };
            });
        };

        $scope.changeOrder = function(direction, slide) {
            var slideIndex = $scope.slides.indexOf(slide);
            if (direction === 'up') {
                var nearSlide = $scope.slides[slideIndex - 1];
                slide.order--;
                nearSlide.order++;
            } else if (direction === 'down') {
                var nearSlide = $scope.slides[slideIndex + 1];
                slide.order++;
                nearSlide.order--;
            } else {
                return false;
            };
            angular.forEach([slide, nearSlide], function(element) {
                Slide.patch({id: element.id, order: element.order }).$promise.then(function() {
                    $state.reload();
                });
            });
        };

		$scope.save = function() {
            $scope.sending = true;

            var slidesPromises = [];
            angular.forEach($scope.slides, function(slide) {
                slidesPromises.push(Slide.patch({
                    id: slide.id,
                    title: slide.title,
                    description: slide.description,
                    link: slide.link,
                    order: slide.order
                }).$promise.then(function() {
                    if (slide.coverFile) {
                        Upload.upload({
                            url: '/api/slides/' + slide.id,
                            data: {cover: slide.coverFile},
                            method: 'PATCH'
                        })
                    };
                }));
            });
            $q.all(slidesPromises).then(function() {
                var dataAdd = {};
                var dataDelete = {};
                $scope.saveData($scope.org.files.fileLogo, dataAdd, dataDelete, 'logo_big');
                $scope.saveData($scope.org.files.fileLogoSmall, dataAdd, dataDelete, 'logo_small');
                $scope.saveData($scope.org.files.fileCss, dataAdd, dataDelete, 'css');
                $scope.saveData($scope.org.files.fileOffer, dataAdd, dataDelete, 'offer');

                // если трогали css, обновляем стили
                if (dataAdd.css || (!$.isEmptyObject(dataDelete) && dataDelete.css === null)) {
                    $scope.removeCssFile();
                };

                var orgFilesPromises = [];

                if (!$.isEmptyObject(dataDelete)) {
                    dataDelete.id = $scope.org.id;
                    orgFilesPromises.push(Organization.patch(dataDelete))
                };

                if (!$.isEmptyObject(dataAdd)) {
                    orgFilesPromises.push(
                        Upload.upload({
                            url: '/api/organizations/' + $scope.org.id,
                            data: dataAdd,
                            method: 'PATCH'
                        })
                    );
                };

                $q.all(orgFilesPromises).then(function() {
                    Organization.patch({
                        id: $scope.org.id,
                        slider_speed: $scope.org.slider_speed,
                        index_title: $scope.org.index_title,
                        index_description: $scope.org.index_description,
                        index_keywords: $scope.org.index_keywords
                    }).$promise.then(function() {
                        $state.reload();
                        $timeout(function() {
                            $scope.sending = false;
                            if ($rootScope.notice) {
                                $rootScope.notice.close();
                            };
                            $rootScope.notice = $rootScope.notification({
                                text: "Изменения успешно сохранены"
                            });
                            $rootScope.notice.result.then(function(){}, function(){});
                        }, 500);
                    });
                })
            });
		}
    });

grandclassApp.controller("AdminDetailsController",
    function ($scope, $rootScope, $timeout,
    		  Organization) {

		function is10Numeric(n) {
			return !isNaN(parseFloat(n)) && isFinite(n) && n.toString().length >= 8;
		}

    	$scope.update = function() {
    		Organization.my().$promise.then(function (data) {
    			$scope.org = data;
    		});
    	};

		$scope.validation = {'inn': false, 'kpp': false, 'co': false};

		function check_inn() {
			if (!$scope.org.inn || !is10Numeric($scope.org.inn)) {
				$scope.validation.inn = true;
				return true
			} else { // valid
				$scope.validation.inn = false;
				return false
			}
        }

        function check_kpp() {
			if (!$scope.org.kpp || !is10Numeric($scope.org.kpp)) {
				$scope.validation.kpp = true;
				return true
			} else {
				$scope.validation.kpp = false;
				return false
			}
        }

        function check_co() {
			if (!$scope.org.checking_account || !is10Numeric($scope.org.checking_account)) {
				$scope.validation.co = true;
				return true
			} else {
				$scope.validation.co = false;
				return false
			}
        }

    	$scope.save = function() {
    		check_inn();
    		check_kpp();
    		check_co();
			if (!$scope.validation.inn && !$scope.validation.kpp && !$scope.validation.co){
				$scope.sending = true;

				Organization.patch({
					id: $scope.org.id,
					requisites_name: $scope.org.requisites_name,
					bank: $scope.org.bank,
					inn: $scope.org.inn,
					kpp: $scope.org.kpp,
					checking_account: $scope.org.checking_account
				}).$promise.then(function() {
					$scope.update();
					$timeout(function() {
						$scope.sending = false;
						if ($rootScope.notice) {
							$rootScope.notice.close();
						};
						$rootScope.notice = $rootScope.notification({
							text: "Изменения успешно сохранены"
						});
						$rootScope.notice.result.then(function(){}, function(){});
					}, 500);
				})
			}

    	}

    });


grandclassApp.controller("AdminInfoController",
    function ($scope, $rootScope, $state, $timeout,
    		  Organization) {

    	$scope.save = function() {
            $scope.sending = true;
    		Organization.patch({
    			id: $scope.org.id,
    			title: $scope.org.title,
    			address: $scope.org.address,
    			phone: $scope.org.phone,
    			email: $scope.org.email,
    			vk: $scope.org.vk,
    			twitter: $scope.org.twitter,
    			facebook: $scope.org.facebook,
    			youtube: $scope.org.youtube,
    			webinar: $scope.org.webinar,
                coord_x: $scope.org.coord_x,
                coord_y: $scope.org.coord_y,
                hint: $scope.org.hint,
                balloon: $scope.org.balloon,
    			yandex: $scope.org.yandex,
    			google: $scope.org.google,
    			agreement: $scope.org.agreement,
    			privacy: $scope.org.privacy,
          is_protected: $scope.org.is_protected
    		}).$promise.then(function() {
    			$state.reload();
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Изменения успешно сохранены"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
    		})
    	};
    });

grandclassApp.controller("AdminSeoController",
    function ($scope, $rootScope, $timeout, $state,
    		  Organization) {

		$scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.users_title;
        $rootScope.metaObj.description = $scope.organization.users_description;
        $rootScope.metaObj.keywords = $scope.organization.users_keywords;

    	$scope.save = function() {
            $scope.sending = true;
            Organization.patch({
                id: $scope.organization.id,
                users_info: $scope.organization.users_info,
                users_title: $scope.organization.users_title,
                users_description: $scope.organization.users_description,
                users_keywords: $scope.organization.users_keywords
            }).$promise.then(function() {
                $state.reload();
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Изменения успешно сохранены"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            })
        }

    });


grandclassApp.controller("AgreementController",
    function ($scope, $rootScope,
              organization) {

        $scope.organization = organization[0];

        $scope.resetHeader();
        $rootScope.pageTitle = 'Пользовательское соглашение';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Пользовательское соглашение';
    });

grandclassApp.controller("AlertController",
    function ($scope, $modalInstance,
              instance) {

    	$scope.instance = instance;

    	$scope.save = function() {
    		$modalInstance.close($scope.instance);
    	};

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

    });

grandclassApp.controller("AppController",
    function ($scope, $rootScope, $state, $templateCache, $modal, $location, $metrika, cssInjector, $timeout, $interval,
              Attachment, Upload, FAQ, Feedback, StaticPage, Chat, Message, User, Tag, Notification,
              profile, chats, unreadCount, notifications, pages) {

        $rootScope.asideLinks = false;
        $rootScope.asideOpened = false;
        $rootScope.pages = pages;
        $rootScope.notifications = notifications;

        $scope.resetHeader = function() {
            $rootScope.pageTitle = '';
            $rootScope.pageTitleClick = false;
            $rootScope.addBtnText = '';
            $rootScope.addBtnClick = false;
            $rootScope.btnText = '';
            $rootScope.btnClick = false;
        };

        $scope.resetMeta = function() {
            $rootScope.metaObj = {
                url: $location.absUrl(),
                title: $scope.organization.domain,
                description: 'Grand Class',
                keywords: '',
                image: $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.organization.logo_big
            };
        };

        $scope.resetMeta();
        $scope.resetHeader();

        $scope.getUnreadCount = function () {
          Message.unread_count().$promise.then(function(data) {
            $rootScope.unreadCount = data.count;
          })
        }

        $scope.getUnreadCount();
        $interval(function () {$scope.getUnreadCount()}, 10000);

        $scope.toggleIndexTag = function(tagId) {
            $scope.indexActiveTag = tagId;
        };

        $scope.removeCssFile = function(path) {
            cssInjector.removeAll();
        };

      	$scope.profile = profile;
      	$rootScope.profile = profile;
      	$scope.chats = chats;

        $scope.toggleAside = function() {
            $rootScope.asideOpened = !$rootScope.asideOpened;
        };

        $scope.toggleSubnav = function() {
            if (!$rootScope.asideOpened) {
                $rootScope.asideOpened = true;
            };
        };

        $rootScope.addBtnClick = function() {
            return false;
        };

        $scope.addDay = function(date, dayCount) {
            return(moment(date).add(dayCount, 'days').format('YYYY-MM-DD'));
        };

        // открывает алерт в виде модалки
        $rootScope.alert = function(instance) {
            angular.element('body').css({
                'margin-right': $rootScope.getScrollbarWidth
            });
            return $modal.open({
                templateUrl: '/static/views/app/alert.html',
                controller: 'AlertController',
                resolve: {
                    instance: function() {
                        return instance;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_small'
            });
        };

        $rootScope.notification = function(instance) {
            angular.element('body').css({
                'margin-right': $rootScope.getScrollbarWidth
            });
            return $modal.open({
                templateUrl: '/static/views/app/notification.html',
                controller: 'NotificationController',
                resolve: {
                    instance: function() {
                        return instance;
                    }
                },
                closeOnClick: true,
                backdrop: false,
                windowClass: 'notification'
            });
        };

        $rootScope.errorNotification = function(instance) {
            angular.element('body').css({
                'margin-right': $rootScope.getScrollbarWidth
            });
            return $modal.open({
                templateUrl: '/static/views/app/error_notification.html',
                controller: 'NotificationController',
                resolve: {
                    instance: function() {
                        return instance;
                    }
                },
                closeOnClick: true,
                backdrop: false,
                windowClass: 'notification_error'
            });
        };

        $rootScope.getFilename = function(path) {
            if (path) {
                var pathArr = path.split('/');
                var filename = pathArr[pathArr.length - 1];
                return filename;
            } else return false;
        };

        // заменяем шаблоны для foundation apps
        var dropdownToggleTpl =
            '<span ' +
            'ng-class="{\'is-open\': $ctrl.active}" ' +
            'ng-click="!$ctrl.toggleOnHover && $ctrl.toggle()" ' +
            'ng-mouseover="$ctrl.toggleOnHover && $ctrl.mouseover()" ' +
            'ng-mouseleave="$ctrl.toggleOnHover && $ctrl.mouseleave($event)"></span> ' +
            '<div ' +
            'ng-style="$ctrl.css" ' +
            'ng-class="{\'is-open\': $ctrl.active}" ' +
            'ng-attr-aria-hidden="$ctrl.active" ' +
            'ng-mouseover="$ctrl.toggleOnHover && $ctrl.mouseover()" ' +
            'ng-mouseleave="$ctrl.toggleOnHover && $ctrl.mouseleave($event)" ' +
            'class="dropdown"></div>';

        var modalBgTpl =
            '<div ng-animate-children="true" ' +
            'class="modal ng-animate"' +
            'ng-click="close($event)"' +
            'style="display: block;"></div>';

        var modalConentTpl =
            '<div tabindex="-1" class="modal__content {{ windowClass }}" style="display: block; visibility: visible;">' +
            '<div ng-transclude></div>' +
            '</div>';

        $templateCache.put("template/dropdownToggle/dropdownToggle.html", dropdownToggleTpl);
        $templateCache.put("template/modal/backdrop.html", modalBgTpl);
        $templateCache.put("template/modal/window.html", modalConentTpl);



        // Функции для создания и редактирования файлов

        $scope.openModalEditFile = function(file) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-attachments.html',
                controller: 'ModalEditAttachmentsController',
                resolve: {
                    instance: function() {
                        return file;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_small'
            })
        };

        $scope.deleteFile = function(file) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить файл: \"" + file.title + "\"",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Attachment.delete({id: file.id}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.editFile = function(file) {
            file.modalTitle = 'Редактирование файла';
            var modalInstance = $scope.openModalEditFile(file);
            modalInstance.result.then(function(instance) {
                Attachment.patch({
                    id: instance.id,
                    author: instance.author,
                    title: instance.title
                }).$promise.then(function() {
                    if (instance.changedFileObj) {
                        Upload.upload({
                            url: '/api/attachments/' + instance.id,
                            data: {file: instance.changedFileObj},
                            method: 'PATCH'
                        });
                    }
                }).then(function() {
                    $state.reload();
                });
            }, function() {});
        };

        $scope.addFile = function(newFileInstance) {
            var modalInstance = $scope.openModalEditFile(newFileInstance);
            modalInstance.result.then(function(instance) {
                Attachment.save({
                    course: instance.course,
                    chapter: instance.chapter,
                    author: instance.author,
                    homework: instance.homework,
                    attempt: instance.attempt,
                    title: instance.title
                }, function (data) {
                    newId = data.id;
                }).$promise.then(function() {
                    if (instance.changedFileObj) {
                        Upload.upload({
                            url: '/api/attachments/' + newId,
                            data: {file: instance.changedFileObj},
                            method: 'PATCH'
                        });
                    }
                }).then(function() {
                    $state.reload();
                });
            }, function() {});
        };


        // Функции для попапа "помощь"

        $scope.openFaq = function() {
            var modalInstance = $scope.openModalFaq({
                type: null,
                text: ''
            });
            modalInstance.result.then(function(feedback) {
                feedback.user = $scope.profile.id;
                if ($rootScope.isAdmin) {
                    Feedback.save(feedback).$promise.then(function() {
                        $timeout(function() {
                            $rootScope.notice = $rootScope.notification({
                                text: "Ваше сообщение успешно отправлено!"
                            });
                            $rootScope.notice.result.then(function(){}, function(){});
                        }, 500)
                    })
                } else {
                    Feedback.save({
                        target: feedback.type == 2 ? feedback.teacher.id : null,
                        type: feedback.type,
                        text: feedback.text,
                        user: $scope.profile.id
                    }).$promise
                }
            }, function() {});
        };

        $scope.openModalFaq = function(feedback) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-faq.html',
                controller: 'ModalFaqController',
                resolve: {
                    items: function(FAQ) {
                        return FAQ.query().$promise;
                    },
                    feedback: function() {
                        return feedback;
                    },
                    tags: function (Tag) {
                        return Tag.query().$promise;
                    },
                    users: function (User) {
                        return User.get({role: 'teacher', limit: 1000000}).$promise;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_faq'
            })
        };

        // настройки для кастомного скроллбара
        $scope.scrollbarConfig = {
            autoHideScrollbar: false,
            axis: 'y',
            theme: 'grand-scroll',
            advanced:{
                updateOnContentResize: true
            },
            scrollInertia: 400,
            setTop: '-99999px'
        };

        $scope.createPage = function () {
            StaticPage.save({
                title: 'Заголовок',
                content: 'Контент',
                organization: $scope.organization.id,
                menu: true},
                function (data) {
                newId = data.id;
            }).$promise.then(function () {
                $state.go('base.app.pages.view', {id: newId});
            });

        };

        $scope.openNotification = function(notification) {
            Notification.patch({id: notification.id, opened: true}).$promise.then(function() {
                if (notification.link) {
                    $state.reload();
                }
            });
        };

        var path = $location.path();
        $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            if ($scope.organization.yandex) {
                if (!toState || !fromState) return;
                var newPath = $location.path();
                $metrika.hit(newPath, {referer: path});
                path = newPath;
            }
        });

        $rootScope.slickTabConfig = {
            arrows: false,
            variableWidth: true,
            infinite: false,
            focusOnSelect: true,
            method: {},
            event: {
                init: function(event, slick, arg) {
                    angular.forEach(slick.$slides, function(slide) {
                        if (angular.element(slide).hasClass('tabs__title_active')) {
                            slick.slickGoTo(angular.element(slide).attr('data-slick-index'), true);
                        }
                    });

                },
                beforeChange: function(event, slick, currentSlide, nextSlide) {
                    var $currentSlide = angular.element(slick.$slides[currentSlide]);
                    var $nextSlide = angular.element(slick.$slides[nextSlide]);
                    if (!$nextSlide.hasClass('tabs__title_active')) {
                        if ($nextSlide.find('a').attr('href')) {
                            $location.path($nextSlide.find('a').attr('href'))
                        } else if ($nextSlide.find('a').attr('ng-click')) {
                            $timeout(function() {
                                $nextSlide.find('a').trigger('click');
                            });
                        }
                    };
                }
            }
        };

        $scope.getNumEnding = function(num, endings) {
            var result;
            num = num % 100;
            if (num >= 11 && num <= 12) {
                result = endings[2];
            } else {
                var i = num % 10;
                switch (i) {
                    case (1):
                        result = endings[0];
                        break;
                    case (2):
                    case (3):
                    case (4):
                        result = endings[1];
                        break;
                    default:
                        result = endings[2];
                }
            }
            return result;
        }
    });

grandclassApp.controller("BlogListController",
    function ($scope, $rootScope, $state,
              News,
              news) {

        $scope.news = news;

        $scope.resetHeader();
        $rootScope.pageTitle = 'Блог';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Блог';

        if ($rootScope.isTeacher) {
            $rootScope.addBtnText = 'Добавить статью';
            $rootScope.addBtnClick = function() {
                News.save({title: 'Заголовок', content: 'Контент'}, function (data) {
                    newId = data.id;
                }).$promise.then(function () {
                    $state.go('base.app.news.edit', {id: newId});
                })
            };
        };

        $scope.statuses = [
            {id: 0, title: 'Черновик', value: 'Черновик'},
            {id: 1, title: 'На модерации', value: "На модерации"},
            {id: 2, title: 'Опубликованы', value: "Опубликована"}
        ];

        $scope.toggleAll = function() {
            angular.forEach($scope.statuses, function(status) {
                status.selected = $scope.isAllSelected;
            });
            $scope.buildStatusArray();
            $scope.filterByStatus();
        };

        $scope.toggleStatus = function() {
            $scope.isAllSelected = $scope.statuses.every(function(status) {
                return status.selected;
            });
            $scope.buildStatusArray();
            $scope.filterByStatus();
        };

        $scope.buildStatusArray = function() {
            $scope.statuses.active = [];
            angular.forEach($scope.statuses, function(status) {
                if (status.selected) {
                   $scope.statuses.active.push(status.value);
                };
            });
        };

        $scope.filterByDate = function(condition) {
            angular.forEach($scope.news, function(newsItem) {
                newsItem.filteredByDate = false;
                var date = moment(newsItem.published);
                switch (condition) {
                    case 'all':
                        newsItem.filteredByDate = true;
                        break;
                    case 'thisWeek':
                        if (date >= $scope.dates.thisWeek.start() && date <= $scope.dates.thisWeek.end()) {
                            newsItem.filteredByDate = true;
                        }
                        break;
                    case 'lastWeek':
                        if (date >= $scope.dates.lastWeek.start() && date <= $scope.dates.lastWeek.end()) {
                            newsItem.filteredByDate = true;
                        }
                        break;
                    case 'date':
                        var filterDateStart = moment($scope.filter.date.date_start, 'DD.MM.YYYY').startOf('date');
                        var filterDateEnd = moment($scope.filter.date.date_end, 'DD.MM.YYYY').endOf('date');
                        if (!filterDateEnd.isValid()) {
                            if (date >= filterDateStart) {
                                newsItem.filteredByDate = true;
                            }
                        } else {
                            if (date <= filterDateEnd && date >= filterDateStart) {
                                newsItem.filteredByDate = true;
                            }
                        }
                        break;
                };
            })
        };

        $scope.filterByStatus = function() {
            angular.forEach($scope.news, function(newsItem) {
                if ($scope.statuses.active.indexOf(newsItem.status) !== -1) {
                    newsItem.filteredByStatus = true;
                } else {
                    newsItem.filteredByStatus = false;
                }
            });
        };

        $scope.isAllSelected = true;
        $scope.filterByDate('all');
        $scope.toggleAll();

        $scope.filter = {
            date: null
        };

        $scope.dates = {
            thisWeek: {
                start: function() {
                    var today = moment();
                    return today.subtract('days', today.isoWeekday() - 1).startOf('date');
                },
                end: function() {
                    return this.start().add('days', 6).endOf('date');
                }
            },
            lastWeek: {
                start: function() {
                    return $scope.dates.thisWeek.start().subtract('days', 7).startOf('date');
                },
                end: function() {
                    return this.start().add('days', 6).endOf('date');
                }
            }
        };

    });

grandclassApp.controller('CalendarController',
    function ($scope, $rootScope, $timeout, $state,
              items, tags, activeTab,
              Event, Webinar) {

        $scope.state = $state.current.name;
        $scope.items = items.results;
        $scope.tags = tags;
        $scope.activeTab = activeTab;

        $scope.resetHeader();
        $rootScope.pageTitle = 'Календарь';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Календарь';

        $scope.calendarLoading = false;

        $scope.viewTypes = [
            {value: 'month', title: 'месяц'},
            {value: 'day', title: 'день'}
        ];

        $scope.view = {
            type: 'day'
        };

        $scope.current = {
            date: {
                prevDay: moment().subtract(1, 'days'),
                nextDay: moment().add(1, 'days'),
                day: moment(),
                month: moment(),
                prevMonth: moment().subtract(1, 'months'),
                nextMonth: moment().add(1, 'months'),
            },
            obj: {
                day: null,
                month: []
            }
        };

        $scope.getMonth = function(value) {
            if (value == 1) {
                $scope.current.date.month.add(1, 'months');
                $scope.current.date.prevMonth.add(1, 'months');
                $scope.current.date.nextMonth.add(1, 'months');
            } else {
                $scope.current.date.month.subtract(1, 'months');
                $scope.current.date.prevMonth.subtract(1, 'months');
                $scope.current.date.nextMonth.subtract(1, 'months');
            };
            $scope.renderMonth();
        };

        $scope.getDay = function(value) {
            if (value == 1) {
                $scope.current.date.day.add(1, 'days');
                $scope.current.date.prevDay.add(1, 'days');
                $scope.current.date.nextDay.add(1, 'days');
            } else {
                $scope.current.date.day.subtract(1, 'days');
                $scope.current.date.prevDay.subtract(1, 'days');
                $scope.current.date.nextDay.subtract(1, 'days');
            };

            $scope.renderDay();
        };

        $scope.addCalendars = function(type) {
          if (type === 'month') {
            angular.forEach($scope.items, function(calendar) {
              var calendarDate = moment(calendar.date);
              var index = $scope.current.obj.month.findIndex(function(element) {
                return (calendarDate.isSame(element.moment, 'day'));
              });
              if (index !== -1) {
                $scope.current.obj.month[index].items.push(calendar);
              };
            });
          } else {
            angular.forEach($scope.items, function(calendar) {
              var calendarDate = moment(calendar.date);
              if (calendarDate.isSame($scope.current.date.day, 'day')) {
                $scope.current.obj.day.items.push(calendar);
              }
            })
          }
        };

        $scope.renderMonth = function() {

            $scope.current.obj.month = [];
            var firstWeekDay = $scope.current.date.month.clone().startOf('month').day();

            if (firstWeekDay > 0) {
                firstWeekDay -= 1;
            } else {
                firstWeekDay = 6;
            };

            // create previous days in start week
            if (firstWeekDay != 0) {
                var prevDays = $scope.current.date.prevMonth.daysInMonth();
                for (var i = prevDays - firstWeekDay + 1; i <= prevDays; i++){
                    $scope.current.obj.month.push({
                        moment: $scope.current.date.prevMonth.clone().set('date', i),
                        nameDayOfWeek: $scope.current.date.prevMonth.clone().set('date', i).format('dddd'),
                        date: i,
                        items: []
                    });
                }
            };

            // create present days
            var presentDays = $scope.current.date.month.daysInMonth();
            for (var i = 1; i <= presentDays; i++) {
                $scope.current.obj.month.push({
                    moment: $scope.current.date.month.clone().set('date', i),
                    nameDayOfWeek: $scope.current.date.month.clone().set('date', i).format('dddd'),
                    date: i,
                    items: [],
                    visible: false,
                    isToday: moment().isSame($scope.current.date.month.clone().set('date', i), 'day')
                });
            };

            // create lost days
            var nextDays = 7 - ($scope.current.obj.month.length % 7);
            if (nextDays > 0) {
                for( var i = 1; i <= nextDays; i++){
                    $scope.current.obj.month.push({
                        moment: $scope.current.date.nextMonth.clone().set('date', i),
                        nameDayOfWeek: $scope.current.date.nextMonth.clone().set('date', i).format('dddd'),
                        date: i,
                        items: []
                    });
                }
            };

            $scope.addCalendars('month');
            $timeout(function() {
                $scope.calendarLoading = false;
            }, 1000);
        };

        $scope.renderDay = function() {
            $scope.current.obj.day = {
                moment: $scope.current.date.day,
                date: $scope.current.date.day.get('date'),
                items: []
            };
            $scope.addCalendars('day');
            $timeout(function() {
                $scope.calendarLoading = false;
            }, 800);
        };

        $scope.getEvents = function() {
            Event.get({limit: 1000000, date: $scope.current.date.day}).$promise.then(function (data) {
                $scope.items = data.results;
                $scope.renderMonth();
                $scope.renderDay();
                $scope.isAllSelected = true;
                $scope.toggleAll();
                $timeout(function() {
                    $scope.calendarLoading = false;
                }, 1000);
            });
        };

        $scope.getWebinars = function() {
            Webinar.get({limit: 1000000, date: $scope.current.date.day}).$promise.then(function (data) {
                $scope.items = data.results;
                $scope.renderMonth();
                $scope.renderDay();
                $scope.isAllSelected = true;
                $scope.toggleAll();
                $timeout(function() {
                    $scope.calendarLoading = false;
                }, 1000);
            });
        };

        $scope.toggleAll = function() {
            angular.forEach($scope.tags, function(tag) {
                tag.selected = $scope.isAllSelected;
            });
            $scope.buildTagsArray();
            $scope.filterByTags();
        };

        $scope.toggleTag = function() {
            $scope.isAllSelected = $scope.tags.every(function(tag) {
                return tag.selected;
            });
            $scope.buildTagsArray();
            $scope.filterByTags();
        };

        $scope.buildTagsArray = function() {
            $scope.tags.activeTags = [];
            angular.forEach($scope.tags, function(tag) {
                if (tag.selected) {
                   $scope.tags.activeTags.push(tag.id);
                };
            });
        };

        $scope.filterByTags = function() {
            angular.forEach($scope.items, function(calendar) {
                calendar.filteredByTags = true;
                if (calendar.tags && calendar.tags.length) {
                    calendar.filteredByTags = calendar.tags.some(function(tag) {
                        return ($scope.tags.activeTags.indexOf(tag) !== -1);
                    });
                };
            });
            $scope.renderMonth();
            $scope.renderDay();
        };

        $scope.isAllSelected = true;
        $scope.toggleAll();
});

grandclassApp.controller("CampaignsController",
    function ($scope, $rootScope, $state,
              Message,
              courses, groups) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Сообщения';
        $rootScope.btnText = 'Отправленные';
            $rootScope.btnClick = function() {
                $state.go('base.app.messages.sent');
            };
        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Сообщения';
        $scope.empty = false;

        $scope.courses = courses.results;
        $scope.groups = groups;

        $scope.campaign = {};
        $scope.allFieldsSelected = false;

        if ($rootScope.isAdmin) {
            $scope.recipients_options = ['Все', 'Все преподаватели', 'Все студенты', 'Студенты курса', 'Группа студентов'];
        } else {
            $scope.recipients_options = ['Студенты курса', 'Группа студентов'];
        }

        $scope.$watch('campaign', function(){
            res = false;
            if ($scope.campaign.recipients == 'Студенты курса') {
                if ($scope.campaign.course) {
                    res = true;
                }
            } else if ($scope.campaign.recipients == 'Группа студентов') {
                if ($scope.campaign.group) {
                    res = true;
                }
            } else if ($scope.campaign.recipients) {
                res = true;
            }
            $scope.allFieldsSelected = res;
        }, true);

        $scope.send = function () {
            if (!$scope.campaign.message || !$scope.allFieldsSelected) {
                $scope.empty = true;
                return
            } else {
                $scope.empty = false;
            }
            Message.campaign({
                recipients: $scope.campaign.recipients,
                course: $scope.campaign.course,
                group: $scope.campaign.group,
                message: $scope.campaign.message
            }).$promise.then(function (data) {
                $scope.sent = true;
                $scope.count = data.count;
            })
        }

        $scope.new = function () {
            $scope.count = 0;
            $scope.sent = false;
            $scope.campaign = {};
        }
    });

grandclassApp.controller("CampaignsSentController",
    function ($scope, $rootScope, $state,
              Message,
              students, courses, groups) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Сообщения';
        $rootScope.btnText = 'Новое сообщение';
        $rootScope.btnClick = function() {
            $state.go('base.app.messages.campaigns');
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Сообщения';

        $scope.filterTypes = [
            {type: 'all', title: 'все'},
            {type: 'course', title: 'курс'},
            {type: 'student', title: 'пользователь'},
            {type: 'group',title: 'группа'}
        ];

        $scope.filter = {
            type: 'all',
            user: null,
            group: null,
            course: null
        };

        $scope.students = students;
        $scope.courses = courses.results;
        $scope.groups = groups;

        $scope.generateMessages = function() {
            Message.get_sent({
                recipients: $scope.filter.user,
                course: $scope.filter.course,
                group: $scope.filter.group,
            }).$promise.then(function (data) {
                $scope.messages = data.messages;
            })
        };

        $scope.generateMessages();

    });

grandclassApp.controller("CertificatesController",
    function ($scope, $rootScope,
              diploms) {

        $scope.diploms = diploms;
       
    	$scope.resetHeader();
    	$rootScope.pageTitle = 'Сертификация';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Сертификация';

    	$scope.slickCertConfig = {
    		variableWidth: true,
			infinite: false,
			swipeToSlide: true,
			prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>',
			nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>'
    	};

        $scope.getRandomDiploms = function(num) {
            if (num >= $scope.diploms.length) {
                return $scope.diploms;
            } else {
                var rndDiploms = [];
                while (rndDiploms.length < 8) {
                    var randomItem = $scope.diploms[Math.floor(Math.random() * $scope.diploms.length)];
                    if (rndDiploms.indexOf(randomItem) === -1) {
                        rndDiploms.push(randomItem);
                    };
                };
                return rndDiploms;
            }
        };

        $scope.rndDiploms = $scope.getRandomDiploms(8);
    });

grandclassApp.controller("ChaptersController",
     function ($scope, $rootScope,
              course, chapter, modules, attachments) {

     	$scope.resetHeader();
    	$scope.attachments = attachments;
     	$scope.course = course;
     	$scope.modules = modules;
     	$scope.chapter = chapter;
    });

grandclassApp.controller("ChaptersEditController",
    function ($scope, $rootScope, $state, $modal, $timeout, $location,
              Chapter, Attachment, Upload,
              chapters, tests, homeworks, module) {

        $scope.isEditPage = true;
        $scope.chapters = chapters;
        $scope.tests = tests;
        $scope.homeworks = homeworks;
        $scope.chapterText = true;
        $scope.typeVideo = 'link';
        $scope.typeAudio = 'link';
        $scope.haveAttach = $scope.attachments.length ? true : false;

        $scope.my = {
            access: false,
            condition: 'chapter'
        };

        $scope.show_demo = true;

        var elements = module.elements;
        var current_index = null;
        angular.forEach(elements, function (element) {
           if ($scope.chapter.id == element.id) {
               current_index = elements.indexOf(element);
           }
        });
        if (current_index > 0) {
            if (elements[current_index - 1].is_demo == false) {
                $scope.show_demo = false;
            }
        }

        $scope.resetHeader();
        $rootScope.pageTitle = $scope.course.title;
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.view.about', {id: $scope.course.id, slug: $rootScope.slug($scope.course.title)});
        };

        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function() {
                $state.go('base.app.chapters.view', {id: $scope.chapter.id});
            };            
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.chapter.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        $scope.newFileInstance = {
            title: '',
            author: '',
            file: null,
            modalTitle: 'Новый файл',
            course: null,
            chapter: $scope.chapter.id
        };

        $scope.dragularOptions = {
            scope: $scope,
            containersModel: $scope.attachments,
            revertOnSpill: true,
            moves: function (el, container, handle) {
                return !$rootScope.isTouchDevice;
            }
        };

        $scope.$on('dragulardrop', function(e, el) {
            var index = 0;
            angular.forEach($scope.attachments, function(attachment) {
                attachment.changedOrder = index;
                if (attachment.order != attachment.changedOrder) {
                    attachment.order = attachment.changedOrder;
                    Attachment.patch({
                        id: attachment.id,
                        order: attachment.changedOrder
                    });
                };
                index++;
            });
        });

        if ($scope.chapter.body) {
            $scope.chapterText = true;
        };

        if ($scope.chapter.video) {
            $scope.chapterVideo = true;
            $scope.typeVideo = $scope.chapter.video.type;
            if ($scope.chapter.video.type === 'link') {
                $scope.chapter.video_link = $scope.chapter.video.link;
            };
        };

        if ($scope.chapter.audio) {
            $scope.chapterAudio = true;
            $scope.typeAudio = $scope.chapter.audio.type;
            if ($scope.chapter.audio.type === 'link') {
                $scope.chapter.audio_link = $scope.chapter.audio.link;
            };
        };

        if (!$scope.course.is_shop) {
            if ($scope.chapter.chapter_for_access) {
                $scope.my.access = true;
                $scope.my.condition = 'chapter';
            };

            if ($scope.chapter.test_for_access) {
                $scope.my.access = true;
                $scope.my.condition = 'test';
            };

            if ($scope.chapter.homework_for_access) {
                $scope.my.access = true;
                $scope.my.condition = 'homework';
            };
        };

        // находим все материалы курса, кроме текущего
        $scope.anotherChapters = $scope.chapters.map(function(chapter) {
            if (chapter.id != $scope.chapter.id) {
                return chapter;
            }
        });

        $scope.results = [
            {score: 3, title: 'удовлетворительно'},
            {score: 4, title: 'хорошо'},
            {score: 5,title: 'отлично'}
        ];

        // проверяет, загружено ли уже видео, и если да, то возвращает его название
        $scope.getVideoFile = function() {
            if ($scope.chapter.video && $scope.chapter.video.type === 'file') {
                return $rootScope.getFilename($scope.chapter.video.link)
            } else return false;
        };


        // проверяет, загружено ли уже видео, и если да, то возвращает его название
        $scope.getAudioFile = function() {
            if ($scope.chapter.audio && $scope.chapter.audio.type === 'file') {
                return $rootScope.getFilename($scope.chapter.audio.link)
            } else return false;
        };


        // функция строит все необходимые настройки, методы и колбэки для dropzone
        $scope.buildDropzone = function(dzName, acceptedFiles, msgMain, msgDescription, fileObj) {

            // options
            $scope[dzName + 'Options'] = {
                url: '/',
                autoProcessQueue: false,
                addRemoveLinks : false,
                uploadMultiple: false,
                acceptedFiles: acceptedFiles,
                maxFiles: 1,
                dictDefaultMessage:
                    '<p class="form-drop__main">' + msgMain + '</p>' +
                    '<p class="form-drop__description">' + msgDescription + '</p>' +
                    '<span class="button button_icon-left form-drop__add">' +
                    '<span class="icon-plus icon-left"></span>' +
                    '<span>Добавить файл</span>' +
                    '</span>',
                previewTemplate:
                    '<div class="form-drop__file">' +
                      '<span class="form-drop__remove" data-dz-remove><span class="icon-close"></span></span>' +
                      '<div class="form-drop__details">' +
                        '<div class="form-drop__filename"><span data-dz-name></span></div>' +
                      '</div>' +
                    '</div>',
            };

            // callbacks
            $scope[dzName + 'Callbacks'] = {
                'addedfile': function(file, dataUrl) {
                    angular.element($scope[dzName].element).addClass('dz-started');
                    $scope.chapter[fileObj] = file;
                    if ($scope[dzName].files.length > 1) {
                        $scope[dzName + 'Methods'].removeFile($scope[dzName].files[0]);
                    };
                },
                'removedfile': function() {
                    $scope.chapter[fileObj] = '';
                }
            };

            // methods
            $scope[dzName + 'Methods'] = {};

            $scope[dzName] = null;
            $timeout(function() {
                $scope[dzName] = $scope[dzName + 'Methods'].getDropzone();
            });
        };


        // строим dropzone для видеофайлов
        $scope.buildDropzone('dropzoneVideo', '.ogb, .mp4, .webm', 'Перетащите файл сюда', 'в формате ogb, mp4, webm', 'video_file');

        // строим dropzone для аудиофайлов
        $scope.buildDropzone('dropzoneAudio', '.mp3, .wav', 'Перетащите файл сюда', 'в формате mp3, wav', 'audio_file');


        // функция загружает файл в dropzone по умолчанию
        $scope.dzAddFile = function(dzName, filename) {
            var mockfile = {
                name: filename
            };
            $timeout(function() {
                $scope[dzName].emit('addedfile', mockfile);
                $scope[dzName].emit('complete', mockfile);
                $scope[dzName].files.push(mockfile);
            });
        };

        // загружаем в dropzone текущий файл, если такой есть
        if ($scope.getVideoFile()) {
            $scope.dzAddFile('dropzoneVideo', $scope.getVideoFile());
        };
        if ($scope.getAudioFile()) {
            $scope.dzAddFile('dropzoneAudio', $scope.getAudioFile());
        };


        $scope.save = function() {

            $scope.sending = true;

            var data = {};
            data.id = $scope.chapter.id;
            data.title = $scope.chapter.title;
            data.is_demo = $scope.chapter.is_demo;

            // сначала очищаем все данные
            data.body = data.video_link = data.audio_link = '';
            data.video_file = data.audio_file = null;
            data.chapter_for_access = data.homework_for_access = data.homework_for_access_mark = 
            data.test_for_access = data.test_for_access_mark = null;
            var uploadType = false;

            if ($scope.my.access) {
                switch ($scope.my.condition) {
                    case 'chapter':
                        data.chapter_for_access = $scope.chapter.chapter_for_access;
                        break;
                    case 'test':
                        data.test_for_access = $scope.chapter.test_for_access;
                        data.test_for_access_mark = $scope.chapter.test_for_access_mark;
                        break;
                    case 'homework':
                        data.homework_for_access = $scope.chapter.homework_for_access;
                        data.homework_for_access_mark = $scope.chapter.homework_for_access_mark;
                        break;
                }
            };

            if ($scope.chapterVideo) {
                if ($scope.typeVideo === 'link') {
                    data.video_link = $scope.chapter.video_link;
                } else uploadType = 'video_file';
            };

            if ($scope.chapterAudio) {
                if ($scope.typeAudio === 'link') {
                    data.audio_link = $scope.chapter.audio_link;
                } else uploadType = 'audio_file';
            };

            if ($scope.chapterText) {
                data.body = $scope.chapter.body;
            };

            Chapter.patch(data).$promise.then(function() {
                if (uploadType) {
                    var uploadData = {};
                    uploadData[uploadType] = $scope.chapter[uploadType];
                    Upload.upload({
                        url: '/api/chapters/' + $scope.chapter.id,
                        data: uploadData,
                        method: 'PATCH'
                    }).then(function() {
                        $state.reload();
                        $timeout(function() {
                            $scope.sending = false;
                            if ($rootScope.notice) {
                                $rootScope.notice.close();
                            };
                            $rootScope.notice = $rootScope.notification({
                                text: "Изменения успешно сохранены"
                            });
                            $rootScope.notice.result.then(function(){}, function(){});
                        }, 500);
                    })
                };
            }).then(function() {
                if (!uploadType) {
                    $state.reload();
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                }
            })
        };
    });

grandclassApp.controller("ChaptersViewController",
    function ($scope, $rootScope, $interval, $state, $location,
              chapter,
              Chapter, ChapterAction) {

        $scope.isEditPage = false;
        $scope.chapter = chapter;
        $scope.actionId = null;

        $scope.resetHeader();
        $rootScope.pageTitle = $scope.course.title;
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.view.about', {id: $scope.course.id});
        };
        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Редактировать';
            $rootScope.btnClick = function() {
                $state.go('base.app.chapters.edit', {id: $scope.chapter.id});
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.chapter.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        if ($rootScope.isStudent) {
            ChapterAction.save({user: $scope.profile.id, chapter: $scope.chapter.id}, function(data) {
                $scope.actionId = data.id;
                TimeMe.startTimer('chapterActivity-' + $scope.actionId);
            });
            var sendReadingTime = $interval(function() {
                var reading_time = moment().startOf('day').seconds(TimeMe.getTimeOnPageInSeconds('chapterActivity-' + $scope.actionId)).format('HH:mm:ss');
                ChapterAction.patch({id: $scope.actionId, reading_time: reading_time});
            }, 10000);
        };


        $scope.$on('$destroy', function() {
            $interval.cancel(sendReadingTime);
        });

        if ($scope.chapter.video && $scope.chapter.video.type === 'link') {
            var script_v = document.createElement('script');
            script_v.src = 'https://player.vimeo.com/api/player.js';
            document.getElementsByTagName('head')[0].appendChild(script_v);
            var script_y = document.createElement('script');
            script_y.src = 'https://www.youtube.com/iframe_api';
            document.getElementsByTagName('head')[0].appendChild(script_y);
        };
    });

grandclassApp.controller("ContactsController",
    function ($scope, $rootScope, $timeout,
              Feedback) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Контакты';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Контакты';

        $scope.map = {
            zoom: 13.5
        };

        $scope.feedback = {
            user: $scope.profile.id,
            type: null,
            text: ''
        };

        $scope.findCenter = function() {
            if ($scope.organization.coord_x && $scope.organization.coord_y) {
                $scope.map.center = [$scope.organization.coord_x, $scope.organization.coord_y];
            } else if ($scope.organization.address) {
                ymaps.geocode($scope.organization.address, {results: 1}).then(function(res) {
                    var firstGeoObject = res.geoObjects.get(0);
                    $scope.$apply(function(){
                        $scope.map.center = firstGeoObject.geometry.getCoordinates();
                    });
                })
            }
        };

        $scope.geoObject = {
            geometry: {
                type: 'Point',
                coordinates: [$scope.organization.coord_x, $scope.organization.coord_y]
            },
            properties: {
                balloonContent: $scope.organization.balloon,
                hintContent: $scope.organization.hint
            }
        };

        $scope.feedbackTypes = [
            {title: 'Вопрос', type: 1},
            {title: 'Проблема', type: 2},
            {title: 'Пожелание', type: 3},
            {title: 'Предложение', type: 4}
        ];

        $scope.sendFeedback = function() {
            if ($scope.feedback.text) {
                $scope.sending = true;
                Feedback.save($scope.feedback).$promise.then(function() {
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $scope.feedback = {
                            type: null,
                            user: $scope.profile.id,
                            text: ''
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Ваше сообщение успешно отправлено!"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                });
            };
        };

	});

grandclassApp.controller("CoursesController",
    function($scope, $rootScope, $state, $timeout, $q,
             Course,
             organization, profile, courses, courseTags) {

        $scope.state = $state.current.name;
        $scope.resetHeader();
        $rootScope.pageTitle = 'Курсы';
        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Курсы';
        if ($rootScope.isAdmin || $rootScope.isTeacher) {
            $rootScope.addBtnText = 'Добавить курс';
            $rootScope.addBtnClick = function() {
                $scope.addCourse();
            };
        };

        $scope.courseTags = courseTags;
        $scope.courses = courses.results;
        $scope.courses.count = courses.count;

        $scope.isAllSelected = true;
        $scope.isMySelected = false;
        $scope.pagination = {
          currentPage: 1,
          perPage: 12
        }
        $scope.filter = {
          isFree: false,
          search: ''
        };

        $scope.update = function(type) {
          if (type === 'reset') {
            $scope.pagination.currentPage = 1;
          };
          $scope.loading = true;
          Course.get({
            limit: $scope.pagination.perPage,
            page: $scope.pagination.currentPage,
            tags: $scope.courseTags.activeTags,
            my: $scope.isMySelected,
            search: $scope.filter.search ? $scope.filter.search : null,
            is_free: $scope.filter.isFree ? $scope.filter.isFree : null }).$promise.then(data => {
              $scope.courses = data.results;
              $scope.courses.count = courses.count;
              $scope.loading = false;
          })
        };

        // TAGS
        $scope.toggleAll = function() {
          $scope.isMySelected = false;
          $scope.courseTags.forEach(item => item.selected = false);
          $scope.buildTagsArray();
        };
        $scope.toggleTag = function() {
          $scope.isAllSelected = false;
          $scope.isMySelected = false;
          $scope.buildTagsArray();
        };
        $scope.toggleMy = function() {
          $scope.isAllSelected = false;
          $scope.courseTags.forEach(item => item.selected = false);
          $scope.buildTagsArray();
        }
        $scope.buildTagsArray = function() {
          $scope.courseTags.activeTags = [];
          angular.forEach($scope.courseTags, function(tag) {
            if (tag.selected) {
              $scope.courseTags.activeTags.push('' + tag.id);
            };
          });
          $scope.isAllSelected = $scope.courseTags.every(item => !item.selected) && !$scope.isMySelected
          $scope.update('reset')
        };

        $scope.dragularOptions = {
            scope: $scope,
            containersModel: $scope.courses.results,
            direction: 'horizontal',
            moves: function (el, container, handle) {
                return !$rootScope.isTouchDevice;
            }
        };

        // создает курс и редиректит на страницу редактирования
        $scope.addCourse = function() {
            Course.save({title: 'Новый курс'}, function(data) {
                newId = data.id;
            }).$promise.then(function () {
                $state.go('base.app.courses.edit.properties', {id: newId});
            })
        };

        // удаление курса
        $scope.deleteCourse = function ($event, course) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить курс \"" + course.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function(){
                Course.delete({id: course.id}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.notice = null;
        $scope.changeSize = function(course, size) {
            course.sizeX = size;
            if (!$scope.sizeNoticed) {
                if ($scope.notice) {
                     $scope.notice.close();
                }
                $scope.notice = $rootScope.notification({
                    text: "Вы изменили размер карточки курса. Чтобы применить изменения, нажите кнопку «Сохранить»"
                });
                $scope.notice.result.then(function(){}, function(){});
                $scope.sizeNoticed = true;
            }
        };

        $scope.$on('dragulardrop', function(e, el) {
            if (!$scope.posNoticed) {
                if ($scope.notice) {
                     $scope.notice.close();
                };
                $scope.notice = $rootScope.notification({
                    text: "Вы изменили позицию карточки курса. Чтобы применить изменения, нажите кнопку «Сохранить»"
                });
                $scope.posNoticed = true;
                $scope.notice.result.then(function(){}, function(){})
            }
        });

        // сохранянт порядок и размер измененных курсов
        $scope.save = function() {
            $scope.sending = true;
            var cardsPromises = [];

            var index = 0;
            angular.forEach($scope.courses.results, function(course) {
                course.changedOrder = index;
                var data = {
                    id: course.id
                };
                course.changed = false;

                // проверка на изменение размера карточки
                if (course.sizeX && (course.sizeX != course.size)) {
                    data.size = course.size = course.sizeX;
                    course.changed = true;
                };

                // проверка на изменение порядка карточки
                if (course.changedOrder != course.order) {
                    data.order = course.order = course.changedOrder;
                    course.changed = true;
                };

                if (course.changed) {
                    cardsPromises.push(Course.patch(data));
                };
                index++;
            });

            if (cardsPromises.length) {
                $q.all(cardsPromises).then(function() {
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                })
            } else {
                $scope.sending = false;
            };
        };
    });

grandclassApp.controller("CoursesEditController",
    function ($scope, $rootScope, $state, $location, $timeout, $q, $modal,
              Upload, Course, Group,
              course, groups, anotherGroups) {

        $scope.course = course;
        $scope.groups = groups;
        $scope.anotherGroups = anotherGroups;
        $scope.bannerImage = $scope.course.banner;
        $scope.selectedGroup = {};
        $scope.cropper = {};

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.course.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() + $scope.course.cover;

        $scope.updateGroups = function () {
            Group.query({course: $scope.course.id}).$promise.then(function (data) {
                $scope.groups = data;
                $scope.updateGroupsDate();
            });
        };

        $scope.updateGroupsDate = function () {
            angular.forEach($scope.groups, function (group) {
                group.date = {
                    date_start: group.date_start ? moment(group.date_start).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY'),
                    date_end: group.date_end ? moment(group.date_end).format('DD.MM.YYYY') : moment().add(7, 'days').format('DD.MM.YYYY')
                };
            });
        };

        $scope.updateGroupsDate();

        // настройки для Dropzone
        $scope.dropzoneBannerOptions = {
            url: '/',
            autoProcessQueue: false,
            acceptedFiles: 'image/jpeg, images/jpg, image/png',
            addRemoveLinks: false,
            uploadMultiple: true,
            thumbnailWidth: 960,
            thumbnailHeight: null,
            dictDefaultMessage: '<span class="button button_white button_edit-bg button_icon-left">\
                                 <span class="icon-camera icon-left"></span>\
                                 <span class="button__text">Редактировать фон</span></span>',
            previewTemplate: '<img hidden data-dz-thumbnail />'
        };

        $scope.openModalImageCropper = function() {
            return $modal.open({
                templateUrl: '/static/views/app/cropper_modal.html',
                controller: 'ImageCropperCtrl',
                backdrop: 'static',
                windowClass: 'modal__content_big',
                resolve: {
                    instance: function () {
                        return $scope.cropper;
                    }
                }
            });
        };

        $scope.getFileFromImage = function(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        };

		$scope.resizeImage = function(file, filename) {
            $scope.cropper.sourceImage = file;
            $scope.cropper.ratio = $scope.organization.banner_ratio;
            var modalInstance = $scope.openModalImageCropper();
            modalInstance.result.then(function (instance) {
                let img = instance.croppedImage;

                $scope.bannerImage = img;
                Upload.upload({
                    url: '/api/courses/' + $scope.course.id,
                    data: {
                        banner: $scope.getFileFromImage(img, filename)
                    },
                    method: 'PATCH'
                })
            });
        };

        $scope.dropzoneBannerCallbacks = {
            'thumbnail': function(file, dataUrl) {
              $scope.resizeImage(dataUrl, file.name);
            }
        };

        $scope.saveTitle = function() {
            if (!$scope.course.title) {
                return false;
            }
            ;
            Course.patch({
                id: $scope.course.id,
                title: $scope.course.title
            }).$promise;
        };

        $scope.buildGroupTitle = function () {
            var title = $scope.course.title;
            if (title.length > 45) {
                title = title.slice(0, 45);
            }
            ;
            title += ' ' + moment().format('DD.MM.YY') + ' (' + ($scope.groups.length + 1) + ')';
            return title;
        };

        $scope.addGroup = function () {
            $scope.adding = true;
            Group.save({
                title: $scope.buildGroupTitle(),
                users: [],
                course: $scope.course.id,
                date_start: moment().format('YYYY-MM-DD'),
                date_end: moment().add(7, 'days').format('YYYY-MM-DD')
            }).$promise.then(function () {
                $scope.updateGroups();
                $timeout(function () {
                    $scope.adding = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    }
                    ;
                    $rootScope.notice = $rootScope.notification({
                        text: "Новая группа успешно добавлена"
                    });
                    $rootScope.notice.result.then(function () {
                    }, function () {
                    });
                }, 500);
            });
        };

        $scope.deleteGroup = function (group) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить группу \"" + group.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function () {
                Group.delete({id: group.id}).$promise.then(function () {
                    $scope.updateGroups();
                    $timeout(function () {
                        $scope.adding = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        }
                        ;
                        $rootScope.notice = $rootScope.notification({
                            text: "Группа успешно удалена"
                        });
                        $rootScope.notice.result.then(function () {
                        }, function () {
                        });
                    }, 500);
                });
            }, function () {
            });
        };

        $scope.dublicateGroup = function ($item, $model) {
            var users = [];
            angular.forEach($item.users, function (student) {
                users.push(student.id);
            });
            Group.save({
                title: $item.title + ' (Копия)',
                users: users,
                course: $scope.course.id,
                limit_access: $item.limit_access,
                date_start: moment().format('YYYY-MM-DD'),
                date_end: moment().add(7, 'days').format('YYYY-MM-DD')
            }).$promise.then(function () {
                $scope.updateGroups();
                $timeout(function () {
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    }
                    ;
                    $rootScope.notice = $rootScope.notification({
                        text: "Новая группа успешно скопирована"
                    });
                    $rootScope.notice.result.then(function () {
                    }, function () {
                    });
                }, 500);
                $scope.selectedGroup = {};
            })
        };

        $scope.save = function () {
            var no_save_event = false;
            if ($scope.course.is_published && !$scope.profile.is_approved) {
                $scope.course.is_published = false;
                $rootScope.notice = $rootScope.errorNotification({
                    text: "Изменения успешно сохранены, но Вы не можете публиковать свой контент, пока Администрация проекта не одобрит Вашу кандидатуру."
                });
                no_save_event = true;
            }
            $scope.sending = true;
            var groupsPromises = [];
            var authorsId = [];

            angular.forEach($scope.course.authors, function (author) {
                authorsId.push(author.id);
            });

            var data = {
                id: $scope.course.id,
                description_short: $scope.course.description_short,
                description_full: $scope.course.description_full,
                tags: $scope.course.tags,
                authors: authorsId,
                price: $scope.course.price,
                price_aggregator: $scope.course.price_aggregator,
                price_shop: $scope.course.price_shop,
                is_shop: $scope.course.is_shop,
                is_published: $scope.course.is_published
            };

            Course.patch(data).$promise.then(function () {
                var uploadData = {};
                if ($scope.course.coverFile) {
                    uploadData.cover = $scope.course.coverFile;
                }
                ;
                Upload.upload({
                    url: '/api/courses/' + $scope.course.id,
                    data: uploadData,
                    method: 'PATCH'
                }).then(function () {
                    angular.forEach($scope.groups, function (group) {
                        groupsPromises.push(Group.patch({
                            id: group.id,
                            course: group.course,
                            date_start: moment(group.date.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                            date_end: moment(group.date.date_end, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                            duration: group.duration,
                            limit_access: group.limit_access,
                            title: group.title
                        }));
                    });
                    $q.all(groupsPromises).then(function () {
                        $timeout(function () {
                            $scope.sending = false;
                            if (!no_save_event) {
                                if ($rootScope.notice) {
                                    $rootScope.notice.close();
                                }
                                $rootScope.notice = $rootScope.notification({
                                    text: "Изменения успешно сохранены"
                                });
                                $rootScope.notice.result.then(function () {
                                }, function () {
                                });
                            }
                        }, 500);
                    })
                })
            })
        };
    });

grandclassApp.controller("CoursesEditAttachmentsController",
    function ($scope, $modal, $rootScope, dragularService, $state, $location,
              Attachment, Course) {

        $scope.resetHeader();
        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.course.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        if ($scope.course.user_can_edit) {
        	$rootScope.addBtnText = 'Добавить файл';
        	$rootScope.addBtnClick = function () {
            $scope.addFile($scope.newFileInstance);
          };
        }

        if ($scope.course.is_shop) {
            $rootScope.pageTitle = 'Магазин';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.shop');
            };
        } else {
            $rootScope.pageTitle = 'Курсы';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.list');
            };
        };

        $scope.newFileInstance = {
            title: '',
            author: '',
            file: null,
            modalTitle: 'Новый файл',
            course: $scope.course.id,
            chapter: null
        };

        $scope.dragularOptions = {
            scope: $scope,
            containersModel: $scope.course.attachments,
            revertOnSpill: true,
            moves: function (el, container, handle) {
                return !$rootScope.isTouchDevice;
            }
        };

        $scope.$on('dragulardrop', function(e, el) {
            var index = 0;
            angular.forEach($scope.course.attachments, function(attachment) {
                attachment.changedOrder = index;
                if (attachment.order != attachment.changedOrder) {
                    attachment.order = attachment.changedOrder;
                    Attachment.patch({
                        id: attachment.id,
                        order: attachment.changedOrder
                    });
                };
                index++;
            });
        });
    });

grandclassApp.controller("CoursesEditContentController",
    function ($scope, $rootScope, $state, $timeout, dragularService, $location,
              Chapter, Test, Module, Homework,
              course, modules, chapters, tests) {

        $scope.resetHeader();
        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.course.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        if ($scope.course.user_can_edit) {
            $rootScope.addBtnText = 'Добавить модуль';
            $rootScope.addBtnClick = function () {
                Module.save({course: $scope.course.id}).$promise.then(function() {
                    $state.reload();
                });
            };
        };

        $scope.course = course;
        $scope.modules = modules;
        $scope.chapters = chapters;
        $scope.renameModule = false;
        $scope.tests = tests;

        if ($scope.course.is_shop) {
            $rootScope.pageTitle = 'Магазин';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.shop');
            };
        } else {
            $rootScope.pageTitle = 'Курсы';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.list');
            };
        };

    	// изначально отключаем режим переименования у всех модулей и задаем новый order
        var moduleIndex = 0;
        angular.forEach($scope.modules, function (module) {
            module.renameModule = false;
            module.order = moduleIndex;
            moduleIndex++;
        });

        // изменяем порядок у модуля
        $scope.changeOrder = function(direction, module) {
            var moduleIndex = $scope.modules.indexOf(module);
            if (direction === 'up') {
                var nearModule = $scope.modules[moduleIndex - 1];
                module.order--;
                nearModule.order++;
            } else if (direction === 'down') {
                var nearModule = $scope.modules[moduleIndex + 1];
                module.order++;
                nearModule.order--;
            } else {
                return false;
            };
            angular.forEach([module, nearModule], function(element) {
                Module.patch({id: element.id, order: element.order }).$promise.then(function() {
                    $state.reload();
                });
            });
        };

		// открывает\закрывает аккордион
        $scope.toggleAccordionItem = function(module) {
            module.opened = !module.opened
        };

		$scope.deleteElement = function ($event, element) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить: \"" + element.title + "\"?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                if (element.type == 'chapter') {
                    Chapter.delete({id: element.id}).$promise.then(function() {
                        $state.reload();
                    });
                } else if (element.type == 'homework') {
                    Homework.delete({id: element.id}).$promise.then(function() {
                        $state.reload();
                    });
                } else if (element.type == 'test') {
                    Test.delete({id: element.id}).$promise.then(function() {
                        $state.reload();
                    });
                }
            }, function(){});
        };

        $scope.createChapter = function (module) {
            Chapter.save({site: module.site, module: module.id, body: ''}, function (data) {
                newId = data.id;
            }).$promise.then(function () {
                $state.go('base.app.chapters.edit', {id: newId})
            })
        };

        $scope.createTest = function (module) {
            Test.save({site: module.site, module: module.id}, function (data) {
                newId = data.id;
            }).$promise.then(function () {
                $state.go('base.app.tests.edit.properties', {id: newId})
            })
        };

        $scope.createHomework = function (module) {
            Homework.save({site: module.site, module: module.id, title: 'Название задания', description: 'Текст задания', date: moment().format('YYYY-MM-DD')}, function (data) {
                newId = data.id;
            }).$promise.then(function () {
                $state.go('base.app.homeworks.edit', {id: newId})
            })
        };

        // переименование заголовка модуля
        $scope.saveTitleModule = function(module) {
            module.renameModule = false;

            if (!module.title) {
                return false;
            };

            Module.patch({id: module.id, title: module.title });
        };

        // удаление модуля
        $scope.deleteModule = function (module) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить модуль \"" + module.title + "\"?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Module.delete({id: module.id}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.dragularOptions = {};

        angular.forEach($scope.modules, function(module) {
            $scope.dragularOptions[module.id] = {
                scope: $scope,
                containersModel: module.elements,
                nameSpace: 'common',
                revertOnSpill: true,
                moves: function (el, container, handle) {
                    return !$rootScope.isTouchDevice;
                }
            }
        });

        $scope.$on('dragulardrop', function(e, el) {
            angular.forEach($scope.modules, function(module) {
                var index = 0;
                angular.forEach(module.elements, function(element) {
                    element.changedOrder = index;
                    element.changedModule = module.id;
                    index++;

                    var data = {
                        id: element.id
                    };

                    element.changed = false;

                    // проверка на изменение порядка элемента
                    if (element.changedOrder != element.order) {
                        data.order = element.order = element.changedOrder;
                        element.changed = true;
                    };

                    // проверка на изменение модуля элемента
                    if (element.changedModule != element.module) {
                        data.module = element.module = element.changedModule;
                        element.changed = true;
                    };

                    if (element.changed) {
                        switch (element.type) {
                            case 'chapter':
                                Chapter.patch(data);
                                break;
                            case 'test':
                                Test.patch(data);
                                break;
                            case 'homework':
                                Homework.patch(data);
                                break;
                        };
                    };
                });
            });
        })
    });

grandclassApp.controller("CoursesEditGroupsController",
    function ($scope, $rootScope, $location, $modal, $timeout, $state,
              course, groups,
              Group, Course) {

        $scope.course = course;
        $scope.groups = groups;

        $scope.resetHeader();
        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.course.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        if ($scope.course.is_shop) {
            $rootScope.pageTitle = 'Магазин';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.shop');
            };
        } else {
            $rootScope.pageTitle = 'Курсы';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.list');
            };
        };

        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function() {
                $state.go('base.app.courses.view.about', {id: $scope.course.id, slug: $rootScope.slug($scope.course.title)});
            };
        };

        $scope.scopeModels = {};
        $scope.countOfChecks = 0;

        $scope.statuses = [
            {id: 0, title: 'Архив', value: false},
            {id: 1, title: 'Активные', value: true}
        ];

        $scope.checkCount = function() {
            $scope.countOfChecks = 0;
            angular.forEach($scope.groups, function (group) {
                if (group.filteredByStatus && group.filteredByDate) {
                    if (group.is_checked) {
                        $scope.countOfChecks++;
                    };
                }
            });
        };

        $scope.isAllGroupsChecked = function () {
            var result = true;

            angular.forEach($scope.groups, function(group) {
                if (group.filteredByStatus && group.filteredByDate) {
                    if (!group.is_checked) {
                        result = false;
                    }
                }
            });
            $scope.scopeModels.allGroupsChecked = result;
            return result;
        };

        $scope.checkAllGroups = function() {
            angular.forEach($scope.groups, function (group) {
                if (group.filteredByStatus && group.filteredByDate) {
                    group.is_checked = $scope.scopeModels.allGroupsChecked ? true : false;
                } else {
                    group.is_checked = false;
                }
            });
            $scope.checkCount();
        };

        $scope.scopeModels.allGroupsChecked = false;


        $scope.toggleAll = function() {
            angular.forEach($scope.statuses, function(status) {
                status.selected = $scope.scopeModels.isAllSelected;
            });
            $scope.buildStatusArray();
            $scope.filterByStatus();
        };

        $scope.toggleStatus = function() {
            $scope.scopeModels.isAllSelected = $scope.statuses.every(function(status) {
                return status.selected;
            });
            $scope.buildStatusArray();
            $scope.filterByStatus();
        };

        $scope.buildStatusArray = function() {
            $scope.statuses.active = [];
            angular.forEach($scope.statuses, function(status) {
                if (status.selected) {
                   $scope.statuses.active.push(status.value);
                };
            });
        };

        $scope.filterByStatus = function() {
            angular.forEach($scope.groups, function(group) {
                group.is_checked = false;
                $scope.scopeModels.allGroupsChecked = false;
                $scope.checkCount();
                if ($scope.statuses.active.indexOf(group.is_active) !== -1) {
                    group.filteredByStatus = true;
                } else {
                    group.filteredByStatus = false;
                }
            });
        };

        $scope.scopeModels.isAllSelected = true;
        $scope.toggleAll();

        $scope.filter = {
            date: null
        };

        $scope.update = function () {
            Group.query({course: $scope.course.id}).$promise.then(function(data) {
                $scope.groups = data;
                $scope.scopeModels.allGroupsChecked = false;
                $scope.toggleAll();
                $scope.checkCount();
            });
        };

        $scope.deleteGroup = function(group) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить группу \"" + group.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function(){
                Group.delete({id: group.id}).$promise.then($scope.update);
            }, function(){});
        };

        $scope.deleteGroups = function() {
            $rootScope.alert({
                title: "Вы действительно хотите удалить выбранные группы?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function() {
                var checkedGroups = [];
                angular.forEach($scope.groups, function(group) {
                    if (group.is_checked) {
                        checkedGroups.push(group.id);
                    };
                });
                Group.batch_delete({ids: checkedGroups}).$promise.then($scope.update);
            }, function(){});
        };

        $scope.editGroup = function(group) {
            var modalInstance = $scope.openModalEditGroup(group);
            modalInstance.result.then(function(instance) {
                var users = [];
                angular.forEach(instance.users, function(user) {
                    users.push(user.id);
                });
                Group.patch({
                    id: instance.id,
                    title: instance.title,
                    users: users,
                    course: instance.course,
                    date_start: moment(instance.date.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    date_end: moment(instance.date.date_end, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    duration: instance.duration,
                    limit_access: instance.limit_access
                }).$promise.then(function() {
                    $scope.update();
                    $timeout(function() {
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                });
            }, function() {});
        };

        $scope.openModalEditGroup = function(group) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-group.html',
                controller: 'ModalEditGroupController',
                resolve: {
                    instance: function() {
                        return group;
                    },
                    students: function(User) {
                        return User.get({role: 'student', limit: 1000000}).$promise;
                    },
                    courses: function(Course) {
                        return Course.get({ limit: 100000 }).$promise;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.buildGroupInfo = function(group) {
            group.filteredByDate = true;
            group.table_start = moment(group.date_end, 'YYYY-MM-DD').format('DD.MM.YYYY');
            group.table_end = moment(group.date_end, 'YYYY-MM-DD').add(group.duration, 'days').format('DD.MM.YYYY');
        };
    });

grandclassApp.controller("CoursesEditPropertiesController",
    function ($scope, $rootScope, $state, $modal, $location,
              Course, Tag,
              tags, teachers, course) {


        $scope.tags = tags;
        $scope.resetHeader();
        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.course.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;



    	$scope.teachers = teachers.results;
    	$scope.coverImage = $scope.course.cover;
    	$scope.teachermodel = null;
    	$scope.course = course;
        $scope.cropper = {};

        $scope.updateTags = function() {
            angular.forEach($scope.tags, function(tag) {
                tag.selected = $scope.hasTag(tag.id);
            });
        };

        $scope.hasTag = function(tagId) {
            if ($scope.course.tags.indexOf(''+tagId) != -1) {
                return true;
            } else return false;
        };

        $scope.updateTags();

        if ($scope.course.is_shop) {
            $rootScope.pageTitle = 'Магазин';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.shop');
            };
        } else {
            $rootScope.pageTitle = 'Курсы';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.list');
            };
        };

        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function() {
                $state.go('base.app.courses.view.about', {id: $scope.course.id, slug: $rootScope.slug($scope.course.title)});
            };
        };

    	// настройки для Dropzone
		$scope.dropzoneCoverOptions = {
			url: '/',
			autoProcessQueue: false,
			acceptedFiles : 'image/jpeg, images/jpg, image/png',
			addRemoveLinks : false,
			uploadMultiple: true,
			thumbnailWidth: 960,
			thumbnailHeight: null,
			dictDefaultMessage: '<span class="button button_white">Сменить фото</span>',
			previewTemplate: '<img hidden data-dz-thumbnail />'
		};

		$scope.openModalImageCropper = function() {
            return $modal.open({
                templateUrl: '/static/views/app/cropper_modal.html',
                controller: 'ImageCropperCtrl',
                backdrop: 'static',
                windowClass: 'modal__content_big',
                resolve: {
                    instance: function () {
                        return $scope.cropper;
                    }
                }
            });
        };

        $scope.getFileFromImage = function(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        };

		$scope.resizeImage = function(file, filename) {
                $scope.cropper.sourceImage = file;
                $scope.cropper.ratio = $scope.organization.cover_ratio;
                var modalInstance = $scope.openModalImageCropper();
                modalInstance.result.then(function (instance) {
                    let img = instance.croppedImage;
                    $scope.course.coverFile = $scope.getFileFromImage(img, filename);
                    $scope.coverImage = img;
                });
        };

		$scope.dropzoneCoverCallbacks = {
			'thumbnail': function(file, dataUrl) {
			    $scope.resizeImage(dataUrl, file.name);
			}
		};

		$scope.toggleTag = function(tag) {
            var index = $scope.course.tags.indexOf(''+tag.id);
            if (index != -1) {
                $scope.course.tags.splice(index, 1);
            } else {
                if (tag.selected) {
                    $scope.course.tags.push(''+tag.id);
                }
            }

        };



    });

grandclassApp.controller("CoursesShopController",
    function($scope, $rootScope, $state, $timeout, $q,
             Course,
             organization, profile, courses, courseTags) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Магазин';

        if ($rootScope.isAdmin || $rootScope.isTeacher) {
            $rootScope.addBtnText = 'Добавить курс';
            $rootScope.addBtnClick = function() {
                $scope.addCourse();
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Магазин';

        $scope.courseTags = courseTags;
        $scope.courses = courses.results;

        $scope.isShop = true;

        $scope.toggleAll = function() {
            angular.forEach($scope.courseTags, function(tag) {
                tag.selected = $scope.isAllSelected;
            });
            $scope.buildTagsArray();
            $scope.filterByTags();
        };

        $scope.toggleTag = function() {
            $scope.isAllSelected = $scope.courseTags.every(function(tag) {
                return tag.selected;
            });
            $scope.buildTagsArray();
            $scope.filterByTags();
        };

        $scope.buildTagsArray = function() {
            $scope.courseTags.activeTags = [];
            angular.forEach($scope.courseTags, function(tag) {
                if (tag.selected) {
                   $scope.courseTags.activeTags.push(tag.id);
                };
            });
        };

        $scope.filterByTags = function() {
            angular.forEach($scope.courses, function(course) {
                course.filteredByTags = true;
                if (course.tags.length) {
                    course.filteredByTags = course.tags.some(function(tag) {
                        return ($scope.courseTags.activeTags.indexOf(tag) !== -1);
                    });
                };
            });
        };

        $scope.toggleFree = function() {
            angular.forEach($scope.courses, function(course) {
                course.filteredByPrice = true;
                if ($scope.isFreeSelected && course.price !== 0) {
                    course.filteredByPrice = false;
                }
            })
        };

        $scope.dragularOptions = {
            scope: $scope,
            containersModel: $scope.courses,
            direction: 'horizontal',
            moves: function (el, container, handle) {
                return !$rootScope.isTouchDevice;
            }
        };

         // при загрузке страницы делаем все тэги активными
        $scope.isAllSelected = true;
        $scope.isFreeSelected = false;
        $scope.toggleAll();
        $scope.toggleFree();

        // создает курс и редиректит на страницу редактирования
        $scope.addCourse = function() {
            Course.save({title: 'Новый курс'}, function(data) {
                newId = data.id;
            }).$promise.then(function () {
                $state.go('base.app.courses.edit.properties', {id: newId});
            })
        };

        // удаление курса
        $scope.deleteCourse = function ($event, course) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить курс \"" + course.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function(){
                Course.delete({id: course.id}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.notice = null;
        $scope.changeSize = function(course, size) {
            course.sizeX = size;
            if (!$scope.sizeNoticed) {
                if ($scope.notice) {
                     $scope.notice.close();
                };
                $scope.notice = $rootScope.notification({
                    text: "Вы изменили размер карточки курса. Чтобы применить изменения, нажите кнопку «Сохранить»"
                });
                $scope.notice.result.then(function(){}, function(){});
                $scope.sizeNoticed = true;
            };
        };

        $scope.$on('dragulardrop', function(e, el) {
            if (!$scope.posNoticed) {
                if ($scope.notice) {
                     $scope.notice.close();
                };
                $scope.notice = $rootScope.notification({
                    text: "Вы изменили позицию карточки курса. Чтобы применить изменения, нажите кнопку «Сохранить»"
                });
                $scope.posNoticed = true;
                $scope.notice.result.then(function(){}, function(){})
            }
        });

        // сохранянт порядок и размер измененных курсов
        $scope.save = function() {
            $scope.sending = true;
            var cardsPromises = [];
            var index = 0;
            angular.forEach($scope.courses, function(course) {
                course.changedOrder = index;
                var data = {
                    id: course.id
                };
                course.changed = false;

                // проверка на изменение размера карточки
                if (course.sizeX && (course.sizeX != course.size)) {
                    data.size = course.size = course.sizeX;
                    course.changed = true;
                };

                // проверка на изменение порядка карточки
                if (course.changedOrder != course.order) {
                    data.order = course.order = course.changedOrder;
                    course.changed = true;
                };

                if (course.changed) {
                    cardsPromises.push(Course.patch(data));
                };
                index++;
            });

            if (cardsPromises.length) {
                $q.all(cardsPromises).then(function() {
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                })
            } else {
                $scope.sending = false;
            }
        };
    });

grandclassApp.controller("CoursesViewController",
    function ($scope, $rootScope, $state, $window, $location, $timeout, $modal,
              AccessRequest, Course, Certificate, User,
    		  course, modules) {

        $scope.course = course;
        $scope.modules = modules;

        $scope.currentElement = null;
        $scope.isAuthor = false;
        $scope.resetHeader();

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.course.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        if ($scope.course.is_shop) {
            $rootScope.pageTitle = 'Магазин';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.shop');
            };
        } else {
            $rootScope.pageTitle = 'Курсы';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.courses.list');
            };
        }

        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Редактировать';
            $rootScope.btnClick = function() {
                $state.go('base.app.courses.edit.properties', {id: $scope.course.id});
            };
        }

        angular.forEach($scope.course.authors, function(author) {
            $scope.isAuthor = author.email == $scope.profile.email;
        });

        var keepGoing = true;
        angular.forEach($scope.modules, function (module) {
            if (keepGoing) {
                angular.forEach(module.elements, function (element) {
                    if (!element.is_completed && keepGoing) {
                        element.is_current = true;
                        $scope.currentElement = element;
                        keepGoing = false;
                    }
                });
            }
        });

        $scope.openModalRegister = function() {
            return $modal.open({
                templateUrl: '/static/views/app/modal-register-test.html',
                controller: 'ModalRegisterTest',
                resolve: {
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.anonymous_modal = function (destination, id) {
            var modalInstance = $scope.openModalRegister();
            modalInstance.result.then(function (instance) {
                if (instance.registered) {
                    let user = instance.data;
                    User.login({'email': user.email, 'password': user.password}).$promise.then(function (data) {
                        if (data.status == 200) {
                            $state.go(destination, {id: id},  {reload: true});
                        } else if (data.status == 403) {
                          $scope.inactive = true;
                          $scope.sending = false;
                        }
                        else {
                            $scope.fail = true;
                            $scope.sending = false;
                        }
                    });
                }
            })

        };

        $scope.goToPayments = function () {
            $scope.sending = true;
            $state.go('base.app.payments');
        };

        $scope.createRequest = function (type) {

            $scope.sending = true;


            AccessRequest.save({'user': $scope.profile.id, 'course': $scope.course.id}).$promise.then(function (data) {
                if (data.email != 'invalid') {
                Course.get({id: $scope.course.id}).$promise.then(function (data) {
                    $scope.course = data;
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        }
                        $rootScope.notice = $rootScope.notification({
                            text: "Заявка на курс успешно отправлена"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                })}
            })

        }
    });

grandclassApp.controller("CoursesViewAboutController",
    function ($scope,
    		  course) {

    	$scope.course = course;


    });

grandclassApp.controller("CoursesViewAttachmentsController",
    function ($scope) {});

grandclassApp.controller("CoursesViewContentController",
    function ($scope) {
    });

grandclassApp.controller("ImageCropperCtrl", function($scope, $rootScope, $modalInstance, instance)
    {
        $scope.cropper = instance;
        $scope.bounds = {};
        $scope.crop_height = 700;
        $scope.crop_width = $scope.cropper.ratio * $scope.crop_height;

        $scope.cancel = function() {
            $modalInstance.close($scope.cropper);
        };

        $scope.save = function() {
            $modalInstance.close($scope.cropper);
        };

    });

grandclassApp.controller("EnterController",
    function ($scope) {});

grandclassApp.controller("EventsController",
    function ($scope, $rootScope, $state, $q, $timeout,
              Event,
              events, eventTags) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Мероприятия';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Мероприятия';

        if ($rootScope.isAdmin || $rootScope.isTeacher) {
            $rootScope.addBtnText = 'Добавить мероприятие';
            $rootScope.addBtnClick = function() {
                $scope.addEvent();
            };
        };

        $scope.events = events;
        $scope.eventTags = eventTags;

        $scope.resetDatepicker = true;
        $scope.tagsAreVisible = false;
        $scope.isAllSelected = true;
        $scope.isMySelected = false;
        $scope.pagination = {
          currentPage: 1,
          perPage: 12
        }
        $scope.filter = {
          isFree: false,
          search: '',
          dateFrom: null,
          dateTo: null,
          datepicker: {
            date_start: null,
            date_end: null
          }
        };

        $scope.update = function(type, datepicker) {
          if (type === 'reset') {
            $scope.pagination.currentPage = 1;
          };
          if (datepicker === 'reset_datepicker') {
            $scope.resetDatepicker = false;
            setTimeout(() => {
              $scope.resetDatepicker = true;
            }, 10)
          }
          $scope.loading = true;
          Event.get({
            limit: $scope.pagination.perPage,
            page: $scope.pagination.currentPage,
            date_from: $scope.filter.dateFrom,
            date_to: $scope.filter.dateTo,
            tags: $scope.eventTags.activeTags,
            my: $scope.isMySelected,
            search: $scope.filter.search ? $scope.filter.search : null,
            is_free: $scope.filter.isFree ? $scope.filter.isFree : null }).$promise.then(data => {
              $scope.events = data;
              $scope.loading = false;
          })
        };

        // DATES
        // type = 'all' || 'thisWeek' || 'nextWeek' || 'past' || 'dates'
        $scope.filterByDate = function(type) {
          let today = moment();
          $scope.filter.dateFrom = null;
          $scope.filter.dateTo = null;
          switch (type) {
            case 'all':
              $scope.filter.datepicker = null;
              $scope.update('reset', 'reset_datepicker');
              break;
            case 'thisWeek':
              $scope.filter.datepicker = null;
              $scope.filter.dateFrom = today.startOf('isoWeek').format('YYYY-MM-DD');
              $scope.filter.dateTo = today.endOf('isoWeek').format('YYYY-MM-DD');
              $scope.update('reset', 'reset_datepicker');
              break;
            case 'nextWeek':
              $scope.filter.datepicker = null;
              $scope.filter.dateFrom = today.add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
              $scope.filter.dateTo = today.add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
              $scope.update('reset', 'reset_datepicker');
              break;
            case 'past':
              $scope.filter.datepicker = null;
              $scope.filter.dateTo = today.format('YYYY-MM-DD');
              $scope.update('reset', 'reset_datepicker');
              break;
            case 'datepicker':
              if ($scope.filter.datepicker.date_start && $scope.filter.datepicker.date_end) {
                $scope.filter.dateFrom = moment($scope.filter.datepicker.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD');
                $scope.filter.dateTo = moment($scope.filter.datepicker.date_end, 'DD.MM.YYYY').format('YYYY-MM-DD');
                $scope.update('reset');
              }
              break;
          }
        }

        // TAGS
        $scope.toggleAll = function() {
          $scope.isMySelected = false;
          $scope.eventTags.forEach(item => item.selected = false);
          $scope.buildTagsArray();
        };
        $scope.toggleTag = function() {
          $scope.isAllSelected = false;
          $scope.isMySelected = false;
          $scope.buildTagsArray();
        };
        $scope.toggleMy = function() {
          $scope.isAllSelected = false;
          $scope.eventTags.forEach(item => item.selected = false);
          $scope.buildTagsArray();
        }
        $scope.buildTagsArray = function() {
          $scope.eventTags.activeTags = [];
          angular.forEach($scope.eventTags, function(tag) {
            if (tag.selected) {
              $scope.eventTags.activeTags.push('' + tag.id);
            };
          });
          $scope.isAllSelected = $scope.eventTags.every(item => !item.selected) && !$scope.isMySelected
          $scope.update('reset')
        };

        $scope.dragularOptions = {
            scope: $scope,
            containersModel: $scope.events.results,
            direction: 'horizontal',
            moves: function (el, container, handle) {
                return !$rootScope.isTouchDevice;
            }
        };

        // создает мероприятие и редиректит на страницу редактирования
        $scope.addEvent = function() {
            Event.save({title: 'Новое мероприятие', place: '', date: moment()}, function(data) {
                newId = data.id;
            }).$promise.then(function () {
                $state.go('base.app.events.edit', {id: newId});
            })
        };

        // удаление мероприятие
        $scope.deleteEvent = function ($event, event) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить мероприятие \"" + event.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function(){
                Event.delete({id: event.id}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.notice = null;
        $scope.changeSize = function(event, size) {
            event.sizeX = size;
            if (!$scope.sizeNoticed) {
                if ($scope.notice) {
                     $scope.notice.close();
                };
                $scope.notice = $rootScope.notification({
                    text: "Вы изменили размер карточки мероприятия. Чтобы применить изменения, нажите кнопку «Сохранить»"
                });
                $scope.notice.result.then(function(){}, function(){});
                $scope.sizeNoticed = true;
            };
        };

        $scope.$on('dragulardrop', function(e, el) {
            if (!$scope.posNoticed) {
                if ($scope.notice) {
                     $scope.notice.close();
                };
                $scope.notice = $rootScope.notification({
                    text: "Вы изменили позицию карточки мероприятия. Чтобы применить изменения, нажите кнопку «Сохранить»"
                });
                $scope.posNoticed = true;
                $scope.notice.result.then(function(){}, function(){})
            }
        });

        // сохранянт порядок и размер измененных курсов
        $scope.save = function() {
            $scope.sending = true;
            var cardsPromises = [];
            var index = 0;
            angular.forEach($scope.events.results, function(event) {
                event.changedOrder = index;
                var data = {
                    id: event.id
                };
                event.changed = false;

                // проверка на изменение размера карточки
                if (event.sizeX && (event.sizeX != event.size)) {
                    data.size = event.size = event.sizeX;
                    event.changed = true;
                };

                // проверка на изменение порядка карточки
                if (event.changedOrder != event.order) {
                    data.order = event.order = event.changedOrder;
                    event.changed = true;
                };

                if (event.changed) {
                    cardsPromises.push(Event.patch(data));
                };
                index++;
            });

            if (cardsPromises.length) {
                $q.all(cardsPromises).then(function() {
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                })
            } else {
                $scope.sending = false;
            }
        };

    });

grandclassApp.controller("EventsEditController",
    function ($scope, $rootScope, $state, $modal, $location, $timeout,
    		  Event, Upload, Tag,
              event, tags, teachers) {

        $scope.event = event;
        $scope.tags = tags;
        $scope.teachers = teachers;
        $scope.cropper = {};

        $scope.resetHeader();
        $rootScope.pageTitle = 'Мероприятия';
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.events.list');
        };

        if ($scope.event.user_can_edit) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function() {
                $state.go('base.app.events.view', {id: $scope.event.id, slug: $rootScope.slug($scope.event.title)});
            };            
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.event.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.event.cover;

        $scope.updateTags = function() {
            angular.forEach($scope.tags, function(tag) {
                tag.selected = $scope.hasTag(tag.id);
            });
        };

        $scope.hasTag = function(tagId) {
            if ($scope.event.tags.indexOf(''+tagId) != -1) {
                return true;
            } else return false;
        };

        $scope.updateTags();

        $scope.event.dateObj = {
        	date: {
        		date_start: moment($scope.event.date).format('DD.MM.YYYY')
        	},
        	time: moment($scope.event.date).format('HH:mm')
        };

        $scope.bannerImage = $scope.event.banner;
        $scope.coverImage = $scope.event.cover;

        $scope.dropzoneBannerOptions = {
            url: '/',
            autoProcessQueue: false,
            acceptedFiles : 'image/jpeg, images/jpg, image/png',
            addRemoveLinks : false,
            uploadMultiple: true,
            thumbnailWidth: 960,
            thumbnailHeight: null,
            dictDefaultMessage: '<span class="button button_white button_edit-bg button_icon-left">\
                                 <span class="icon-camera icon-left"></span>\
                                 <span class="button__text">Редактировать фон</span></span>',
            previewTemplate: '<img hidden data-dz-thumbnail />'
        };

        $scope.dropzoneBannerCallbacks = {
            'thumbnail': function(file, dataUrl) {
                $scope.resizeImage(dataUrl, file.name, 'banner');
            }
        };

		$scope.dropzoneCoverOptions = {
			url: '/',
			autoProcessQueue: false,
			acceptedFiles : 'image/jpeg, images/jpg, image/png',
			addRemoveLinks : false,
			uploadMultiple: true,
			thumbnailWidth: 960,
			thumbnailHeight: null,
			dictDefaultMessage: '<span class="button button_white">Сменить фото</span>',
			previewTemplate: '<img hidden data-dz-thumbnail />'
		};

		$scope.dropzoneCoverCallbacks = {
			'thumbnail': function(file, dataUrl) {
                $scope.resizeImage(dataUrl, file.name, 'cover');
			}
		};

        $scope.saveTitle = function() {
            if (!$scope.event.title) {
                return false;
            };
            Event.patch({
                id: $scope.event.id,
                title: $scope.event.title
            }).$promise;
        };

        $scope.save = function() {
            $scope.sending = true;
            var authorsId = [];
            angular.forEach($scope.event.authors, function (author) {
                authorsId.push(author.id);
            });
            $scope.event.date = moment($scope.event.dateObj.date.date_start + ' ' + $scope.event.dateObj.time, 'DD.MM.YYYY HH:mm');
            Event.patch({
                id: $scope.event.id,
                description_short: $scope.event.description_short,
                description_full: $scope.event.description_full,
                tags: $scope.event.tags,
                authors: authorsId,
                price: $scope.event.price,
                place: $scope.event.place,
                date: $scope.event.date,
                is_published: $scope.event.is_published
            }).$promise.then(function() {
                var uploadData = {};
                if ($scope.event.coverFile) {
                    uploadData.cover = $scope.event.coverFile;
                };
                Upload.upload({
                    url: '/api/events/' + $scope.event.id,
                    data: uploadData,
                    method: 'PATCH'
                }).then(function() {
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                })
            })
        };

        $scope.openModalImageCropper = function() {
            return $modal.open({
                templateUrl: '/static/views/app/cropper_modal.html',
                controller: 'ImageCropperCtrl',
                backdrop: 'static',
                windowClass: 'modal__content_big',
                resolve: {
                    instance: function () {
                        return $scope.cropper;
                    }
                }
            });
        };

        $scope.getFileFromImage = function(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        };

		$scope.resizeImage = function(file, filename, type) {
                $scope.cropper.sourceImage = file;
                if (type == 'banner') {
                    var ratio = $scope.organization.banner_ratio;
                } else {
                    var ratio = $scope.organization.cover_ratio;
                }
                $scope.cropper.ratio = ratio;
                var modalInstance = $scope.openModalImageCropper();
                modalInstance.result.then(function (instance) {
                    let img = instance.croppedImage;
                    if (type == 'banner') {
                        $scope.event.bannerFile = $scope.getFileFromImage(img, filename);
                        $scope.bannerImage = img;
                        Upload.upload({
                            url: '/api/events/' + $scope.event.id,
                            data: {
                                banner: $scope.getFileFromImage(img, filename)
                            },
                            method: 'PATCH'
                        });
                    } else {
                        $scope.event.coverFile = $scope.getFileFromImage(img, filename);
                        $scope.coverImage = img;
                    }
                });
        };

        $scope.toggleTag = function(tag) {
            var index = $scope.event.tags.indexOf(''+tag.id);
            if (index != -1) {
                $scope.event.tags.splice(index, 1);
            } else {
                if (tag.selected) {
                    $scope.event.tags.push(''+tag.id);
                }
            }

        };


    });

grandclassApp.controller("EventsViewController",
    function ($scope, $rootScope, $state, $location, $timeout,
              event, profile,
              AccessRequest, Event) {

        $scope.event = event;
        $scope.profile = profile;

        $scope.resetHeader();
        $rootScope.pageTitle = 'Мероприятия';
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.events.list');
        };

        if ($scope.event.user_can_edit) {
            $rootScope.btnText = 'Редактировать';
            $rootScope.btnClick = function() {
                $state.go('base.app.events.edit', {id: $scope.event.id});
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.event.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.event.cover;

        $scope.getDate = function() {
            $scope.event.dateObj = {
            	date: moment($scope.event.date).format('DD.MM.YYYY'),
            	time: moment($scope.event.date).format('HH:mm')
            };
        };

        $scope.getDate();

        $scope.goToPayments = function () {
            $scope.sending = true;
            $state.go('base.app.payments');
        };

        $scope.createRequest = function () {
            AccessRequest.save({'user': $scope.profile.id, 'event': $scope.event.id}).$promise.then(function (data) {
                if (data.email != 'invalid') {
                Event.get({id: $scope.event.id}).$promise.then(function (data) {
                    $scope.event = data;
                    $scope.getDate();
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Заявка на мероприятие успешно отправлена"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                })}
            })
        }

        $scope.event.isPast = moment($scope.event.date) > moment() ? false : true;
    });

grandclassApp.controller("FaqController",
    function ($scope, $rootScope) {

    	$scope.resetHeader();
        $rootScope.pageTitle = 'База знаний';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — База знаний';
    });

grandclassApp.controller("FaqListController",
    function ($scope, $rootScope, $modal,
              FAQ,
    		      items) {

    	$scope.items = items;
    	$scope.categories = {};

      $scope.resetHeader();
      $rootScope.pageTitle = 'База знаний';

      if ($rootScope.isAdmin || $rootScope.isTeacher) {
        $rootScope.addBtnText = 'Добавить вопрос';

        $rootScope.addBtnClick = function() {
            $scope.addFAQ();
        };
      };

      $scope.init = function() {
          $scope.categories = {};
          $scope.groupByCategories();
      };

      $scope.update = function() {
          FAQ.query().$promise.then(function(data) {
              $scope.items = data;
              $scope.init();
          });
      };

      $scope.openModalAddFAQ = function(faq) {
          return $modal.open({
              templateUrl: '/static/views/app/modal-add-faq.html',
              controller: 'ModalAddFAQController',
              resolve: {
                instance: function() {
                    return faq;
                }
              },
              closeOnClick: true,
              backdrop: true,
              windowClass: 'modal__content_big'
          })
      };

      $scope.addFAQ = function() {
        var modalInstance = $scope.openModalAddFAQ({});
          modalInstance.result.then(function (instance) {
            var data = {
              category: instance.category,
              title: instance.title,
              content: instance.content,
              is_popular: instance.is_popular,
            };
            FAQ.save(data).$promise.then(function () {
              $rootScope.notification({text: 'Вопрос создан'}).result.then(function (instance) { $scope.update }, function() {} );
              $scope.update();
            });
          }, function(){});
      };

      $scope.deleteFAQ = function(faq) {
          $rootScope.alert({
              title: "Вы действительно хотите удалить вопрос: \"" + faq.title + "\"",
              ok: 'Удалить',
              cancel: 'Отмена'
          }).result.then(function(){
              FAQ.delete({id: faq.id}).$promise.then($scope.update);
          }, function(){});
      };

      $scope.editFAQ = function(faq) {
          var modalInstance = $scope.openModalAddFAQ(faq);
          modalInstance.result.then(function (instance) {
              FAQ.patch(instance).$promise.then(function () {
                $rootScope.notification({text: 'Вопрос изменен'}).result.then(function (instance) { $scope.update }, function() {} );
                $scope.update();
              });
            }, function(){});
      };

    	// группировка всех вопросов по категориям
    	$scope.groupByCategories = function() {
            angular.forEach($scope.items, function (item) {
                if (!$scope.categories[item.category.id]) {
                    $scope.categories[item.category.id] = [];
                };
                if (!$scope.categories[item.category.id].title) {
                    $scope.categories[item.category.id].title = item.category.title;
                };
                $scope.categories[item.category.id].push({
                    id: item.id,
                    title: item.title,
                    content: item.content,
                    is_popular: item.is_popular,
                    category: item.category,
                });
            });
    	};
    });

grandclassApp.controller("FaqViewController",
    function ($scope, $rootScope, $state,
              item) {

        $scope.item = item;

        $scope.resetHeader();
        $rootScope.pageTitle = 'База знаний';
        $rootScope.pageTitleClick = function() {
        	$state.go('base.app.faq.list');
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.item.title;
    });

grandclassApp.controller("GlossaryController",
    function ($scope, $rootScope, $modal,
              Upload, Term,
              terms) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Глоссарий';

        if ($rootScope.isAdmin || $rootScope.isTeacher) {
        	$rootScope.addBtnText = 'Добавить термин';

          $rootScope.addBtnClick = function() {
              $scope.addTerms();
          };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Глоссарий';

    	$scope.terms = terms;

        $scope.init = function() {
            $scope.termsGroups = {};
            $scope.groupByChar();
        };


        $scope.update = function() {
            Term.query().$promise.then(function(data) {
                $scope.terms = data;
                $scope.init();
            });
        };


        // группировка всех терминов по букве
    	$scope.groupByChar = function() {
            angular.forEach($scope.terms, function (term) {
                var firstChar = term.title[0].toUpperCase();

                if (!$scope.termsGroups[firstChar]) {
                    $scope.termsGroups[firstChar] = [];
                };

                if (!$scope.termsGroups[firstChar].char) {
                    $scope.termsGroups[firstChar].char = firstChar;
                    $scope.termsGroups[firstChar].filtered = true;
                };

                $scope.termsGroups[firstChar].push({
                    id: term.id,
                    title: term.title,
                    definition: term.definition,
                    filtered: true
                });
            });
    	};


        $scope.addTerms = function() {
            var modalInstance = $scope.openModalAddTerms({
                title: '',
                definition: '',
            });
            modalInstance.result.then(function (instance) {
                if (instance.typeofInstance === 'term') {
                    Term.save({
                        title: instance.title,
                        definition: instance.definition
                    }).$promise.then($scope.update);
                } else if (instance.typeofInstance === 'file') {
                    Upload.upload({
                        url: '/api/glossary_import/' + instance.name,
                        file: instance,
                        method: 'POST'
                    }).then($scope.update);
                }
            }, function(){});
        };

        $scope.deleteTerm = function(term) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить термин: \"" + term.title + "\"",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Term.delete({id: term.id}).$promise.then($scope.update);
            }, function(){});
        };

        $scope.editTerm = function(term) {
            var modalInstance = $scope.openModalEditTerm(term);
            modalInstance.result.then(function (instance) {
                Term.patch(instance).$promise.then($scope.update);
            }, function(){});
        };

        $scope.openModalAddTerms = function(term) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-terms.html',
                controller: 'ModalAddTermsController',
                resolve: {
                    instance: function() {
                        return term;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.openModalEditTerm = function(term) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-term.html',
                controller: 'ModalEditTermController',
                resolve: {
                    instance: function() {
                        return term;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_small'
            })
        };

        $scope.filterTerms = function() {
            angular.forEach($scope.termsGroups, function (group) {
                group.filtered = 0;

                angular.forEach(group, function (term) {
                    var re = new RegExp($scope.searchText, 'i');
                    if (term.title.search(re) != -1 || term.definition.search(re) != -1) {
                        term.filtered = true;
                    } else {
                        term.filtered = false;
                    }
                    group.filtered += term.filtered;
                })
            })
        }
    })

    .value('duScrollActiveClass', 'nav-fixed__link_active')
    .value('duScrollGreedy', true)
    .value('duScrollCancelOnEvents', false)
    .value('duScrollOffset', 70);

grandclassApp.controller("GroupsController",
    function ($scope, $rootScope,
              groups) {

    	$scope.resetHeader();
    	$rootScope.pageTitle = 'Группы';

    	$scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Группы';

    });

grandclassApp.controller("GroupsListController",
    function ($scope, $rootScope, $modal, $state, $timeout,
              User, Group, Upload, Course,
              groups) {

        $scope.scopeModels = {};

        $scope.resetHeader();
        $rootScope.pageTitle = 'Группы';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Группы';

        $scope.groups = groups;
        $scope.countOfChecks = 0;

        $scope.statuses = [
            {id: 0, title: 'Архив', value: false},
            {id: 1, title: 'Активные', value: true}
        ];

        $scope.checkCount = function() {
            $scope.countOfChecks = 0;
            angular.forEach($scope.groups, function (group) {
                if (group.filteredByStatus && group.filteredByDate) {
                    if (group.is_checked) {
                        $scope.countOfChecks++;
                    };
                }
            });
        };

        $scope.isAllGroupsChecked = function () {
            var result = true;
            angular.forEach($scope.groups, function(group) {
                if (group.filteredByStatus && group.filteredByDate) {
                    if (!group.is_checked) {
                        result = false;
                    }
                }
            });
            $scope.scopeModels.allGroupsChecked = result;
            return result;
        };

        $scope.checkAllGroups = function() {
            angular.forEach($scope.groups, function (group) {
                if (group.filteredByStatus && group.filteredByDate) {
                    group.is_checked = $scope.scopeModels.allGroupsChecked ? true : false;
                } else {
                    group.is_checked = false;
                }
            });
            $scope.checkCount();
        };

        $scope.scopeModels.allGroupsChecked = false;


        $scope.toggleAll = function() {
            angular.forEach($scope.statuses, function(status) {
                status.selected = $scope.scopeModels.isAllSelected;
            });
            $scope.buildStatusArray();
            $scope.filterByStatus();
        };

        $scope.toggleStatus = function() {
            $scope.scopeModels.isAllSelected = $scope.statuses.every(function(status) {
                return status.selected;
            });
            $scope.buildStatusArray();
            $scope.filterByStatus();
        };

        $scope.buildStatusArray = function() {
            $scope.statuses.active = [];
            angular.forEach($scope.statuses, function(status) {
                if (status.selected) {
                   $scope.statuses.active.push(status.value);
                };
            });
        };

        $scope.filterByStatus = function() {
            angular.forEach($scope.groups, function(group) {
                group.is_checked = false;
                $scope.scopeModels.allGroupsChecked = false;
                $scope.checkCount();
                if ($scope.statuses.active.indexOf(group.is_active) !== -1) {
                    group.filteredByStatus = true;
                } else {
                    group.filteredByStatus = false;
                }
            });
        };

        $scope.scopeModels.isAllSelected = true;
        $scope.toggleAll();

        $scope.filter = {
            date: null
        };

        $scope.filterByDate = function() {
            angular.forEach($scope.groups, function(group) {
                group.filteredByDate = false;
                group.is_checked = false;
                $scope.scopeModels.allGroupsChecked = false;
                $scope.checkCount();
                var date = moment(group.created_at);
                var filterDateStart = moment($scope.filter.date.date_start, 'DD.MM.YYYY').startOf('date');
                var filterDateEnd = moment($scope.filter.date.date_end, 'DD.MM.YYYY').endOf('date');
                if (!filterDateEnd.isValid()) {
                    if (date >= filterDateStart) {
                        group.filteredByDate = true;
                    }
                } else {
                    if (date <= filterDateEnd && date >= filterDateStart) {
                        group.filteredByDate = true;
                    }
                }
            })
        };

        $scope.update = function () {
            Group.query().$promise.then(function(data) {
                $scope.groups = data;
                $scope.scopeModels.allGroupsChecked = false;
                $scope.toggleAll();
                $scope.checkCount();
            });
        };

        $scope.deleteGroup = function(group) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить группу \"" + group.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function(){
                Group.delete({id: group.id}).$promise.then($scope.update);
            }, function(){});
        };

        $scope.deleteGroups = function() {
            $rootScope.alert({
                title: "Вы действительно хотите удалить выбранные группы?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function() {
                var checkedGroups = [];
                angular.forEach($scope.groups, function(group) {
                    if (group.is_checked) {
                        checkedGroups.push(group.id);
                    };
                });
                Group.batch_delete({ids: checkedGroups}).$promise.then($scope.update);
            }, function(){});
        };

        $scope.addGroup = function() {
            var modalInstance = $scope.openModalAddGroup({
                title: 'Название группы',
                date_start: moment().format('DD.MM.YYYY'),
                date_end: moment().add(7, 'days').format('DD.MM.YYYY'),
                duration: 30,
                limit_access: 10
            });
            modalInstance.result.then(function(instance) {
                var users = [];
                angular.forEach(instance.students, function(student) {
                    users.push(student.id);
                });
                Group.save({
                    title: instance.title,
                    users: users,
                    course: instance.course,
                    date_start: moment(instance.date.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    date_end: moment(instance.date.date_end, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    duration: instance.duration,
                    limit_access: instance.limit_access
                }).$promise.then(function() {
                    $scope.update();
                    $timeout(function() {
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Группа успешно создана"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                });
            }, function() {});
        };

        $scope.editGroup = function(group) {
            var modalInstance = $scope.openModalEditGroup(group);
            modalInstance.result.then(function(instance) {
                var users = [];
                angular.forEach(instance.users, function(user) {
                    users.push(user.id);
                });
                Group.patch({
                    id: instance.id,
                    title: instance.title,
                    users: users,
                    course: instance.course,
                    date_start: moment(instance.date.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    date_end: moment(instance.date.date_end, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    duration: instance.duration,
                    limit_access: instance.limit_access
                }).$promise.then(function() {
                    $scope.update();
                    $timeout(function() {
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                });
            }, function() {});
        };

        $scope.openModalAddGroup = function(group) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-group.html',
                controller: 'ModalAddGroupController',
                resolve: {
                    instance: function() {
                        return group;
                    },
                    students: function(User) {
                        return User.get({role: 'student', limit: 1000000}).$promise;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.openModalEditGroup = function(group) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-group.html',
                controller: 'ModalEditGroupController',
                resolve: {
                    instance: function() {
                        return group;
                    },
                    students: function(User) {
                        return User.get({role: 'student', limit: 1000000}).$promise;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };


    });

grandclassApp.controller("GroupsViewController",
    function ($scope, $rootScope, $modal, $state,
              Group, Upload, User,
              group, groups, tags) {

        $scope.scopeModels = {};

        $scope.group = group;
        $scope.groups = groups;
        $scope.tags = tags;
        $scope.countOfChecks = 0;

        $scope.roles = [
            {title: 'Студент', value: 'student'},
            {title: 'Преподаватель', value: 'teacher'},
            {title: 'Администратор', value: 'admin'}
        ];

        $scope.filter = {
            role: ['admin', 'student', 'teacher']
        };

        $scope.resetHeader();
        $rootScope.pageTitle = 'Группы';
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.groups.list');
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.group.title;

        $scope.checkCount = function() {
            $scope.countOfChecks = 0;
            angular.forEach($scope.group.users, function (user) {
                if (user.filteredByTags && user.filteredByRoles) {
                    if (user.is_checked) {
                        $scope.countOfChecks++;
                    };
                }
            });
        };

        $scope.batchReset = function() {
            User.batch_reset({ids: $scope.selected_users}).$promise.then(function (data) {
                console.log(data);
            })
        };

        $scope.isAllUsersChecked = function () {
            var result = true;
            $scope.selected_users = ''

            angular.forEach($scope.group.users, function(user) {
                if (user.filteredByTags && user.filteredByRoles) {
                    if (!user.is_checked) {
                        result = false;
                    } else {
                        $scope.selected_users += 'users=' + user.id + '&'
                    }
                }
            });
            $scope.scopeModels.allUsersChecked = result;
            return result;
        };

        $scope.checkAllUsers = function() {
            angular.forEach($scope.group.users, function (user) {
                if (user.filteredByTags && user.filteredByRoles) {
                    user.is_checked = $scope.scopeModels.allUsersChecked ? true : false;
                } else {
                    user.is_checked = false;
                }
            });
            $scope.checkCount();
        };

        $scope.scopeModels.allUsersChecked = false;

        $scope.toggleAll = function() {
            angular.forEach($scope.tags, function(tag) {
                tag.selected = $scope.scopeModels.isAllSelected;
            });
            $scope.buildTagsArray();
            $scope.filterByTags();
        };

        $scope.toggleTag = function() {
            $scope.scopeModels.isAllSelected = $scope.tags.every(function(tag) {
                return tag.selected;
            });
            $scope.buildTagsArray();
            $scope.filterByTags();
        };

        $scope.buildTagsArray = function() {
            $scope.tags.activeTags = [];
            angular.forEach($scope.tags, function(tag) {
                if (tag.selected) {
                   $scope.tags.activeTags.push(tag.id);
                };
            });
        };

        $scope.filterByTags = function() {
            angular.forEach($scope.group.users, function(user) {
                user.is_checked = false;
                $scope.scopeModels.allUsersChecked = false;
                $scope.checkCount();
                user.filteredByTags = true;
                if (user.tags.length) {
                    user.filteredByTags = user.tags.some(function(tag) {
                        return ($scope.tags.activeTags.indexOf(tag) !== -1);
                    });
                };
            });
        };

        $scope.filterByRoles = function() {
            angular.forEach($scope.group.users, function(user) {
                user.is_checked = false;
                $scope.scopeModels.allUsersChecked = false;
                $scope.checkCount();
                if ($scope.filter.role.indexOf(user.role.value) !== -1) {
                    user.filteredByRoles = true;
                } else {
                    user.filteredByRoles = false;
                }
            });
        };

        $scope.scopeModels.isAllSelected = true;
        $scope.toggleAll();
        $scope.filterByRoles();

        $scope.update = function () {
            Group.get({id: $scope.group.id}).$promise.then(function(data) {
                $scope.group = data;
                $scope.filterByRoles();
                $scope.filterByTags();
            });
        };

        $scope.deleteUser = function(user) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить пользователя \"" + user.full_name + "\" из группы \"" + $scope.group.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function() {
                var users = [];
                angular.forEach($scope.group.users, function(groupUser) {
                    if (groupUser.id !== user.id) {
                        users.push(groupUser.id);
                    };
                });
                Group.patch({
                    id: $scope.group.id,
                    users: users
                }).$promise.then($scope.update);
            }, function(){});
        };

        $scope.deleteUsers = function() {
            $rootScope.alert({
                title: "Вы действительно хотите удалить выбранных пользователей из группы \"" + $scope.group.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function() {
                var checkedUsers = [];
                angular.forEach($scope.group.users, function(user) {
                    if (!user.is_checked) {
                        checkedUsers.push(user.id);
                    };
                });
                Group.patch({
                    id: $scope.group.id,
                    users: checkedUsers,
                }).$promise.then($scope.update);
            }, function(){});
        };

        $scope.addUsers = function(activeTab) {
            var modalInstance = $scope.openModalAddUsers({
                avatar: '/static/images/default-profile.jpg'
            }, activeTab);
            modalInstance.result.then(function (instance) {

                // если один пользователь
                if (instance.type === 'user') {
                    var groupArr = [];
                    angular.forEach(instance.groups, function(group) {
                        groupArr.push(group.id);
                    });

                    User.register({
                        first_name: instance.name.split(' ')[1],
                        middle_name: instance.name.split(' ')[2],
                        last_name: instance.name.split(' ')[0],
                        role: instance.role.value,
                        email: instance.email,
                        phone: instance.phone,
                        groups: groupArr
                    }).$promise.then(function(data) {
                        if (instance.avatarFile) {
                            Upload.upload({
                                url: '/api/users/' + data.id,
                                data: {avatar: instance.avatarFile},
                                method: 'PATCH'
                            })
                        };
                    }).then($scope.update);

                // если несколько пользователей
                } else if (instance.type === 'users') {
                    var groupArr = [];
                    angular.forEach(instance.users.groups, function(group) {
                        groupArr.push(group.id);
                    });
                    angular.forEach(instance.users.emails, function(email) {
                        var username = email.split('@')[0];
                        User.register({
                            first_name: username,
                            last_name: username,
                            email: email,
                            groups: groupArr,
                            role: 'student'
                        }).$promise.then($scope.update);
                    });
                // если список пользователей импортируется
                } else if (instance.type === 'import') {
                    Upload.upload({
                        url: '/api/users_import/' + instance.fileImport.name,
                        file: instance.fileImport,
                        method: 'POST'
                    }).then($scope.update);
                }

            }, function(){});
        };

        $scope.editUser = function(user) {
            var modalInstance = $scope.openModalEditUser(user);
            modalInstance.result.then(function(instance) {
                var groupArr = [];
                angular.forEach(instance.groups, function(group) {
                    groupArr.push(group.id);
                });
                User.patch({
                    id: instance.id,
                    first_name: instance.name.split(' ')[1],
                    middle_name: instance.name.split(' ')[2],
                    last_name: instance.name.split(' ')[0],
                    role: instance.role.value,
                    email: instance.email,
                    phone: instance.phone,
                    groups: groupArr
                }).$promise.then(function(data) {
                    if (instance.avatarFile) {
                        Upload.upload({
                            url: '/api/users/' + data.id,
                            data: {avatar: instance.avatarFile},
                            method: 'PATCH'
                        })
                    };
                }).then($scope.update);
            }, function() {});
        };

        $scope.openModalAddUsers = function(user, activeTab) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-users.html',
                controller: 'ModalAddUsersController',
                resolve: {
                    instance: function() {
                        return user;
                    },
                    groups: function() {
                        return groups;
                    },
                    activeTab: function() {
                        return activeTab;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.openModalEditUser = function(user) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-user.html',
                controller: 'ModalEditUserController',
                resolve: {
                    instance: function() {
                        return user;
                    },
                    groups: function() {
                        return groups;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.addToGroup = function() {
            var modalInstance = $scope.openModalAddToGroup({
                group: null
            });
            modalInstance.result.then(function(instance) {
                var currentUsers = [];
                angular.forEach($scope.groups, function(group) {
                    if (group.id === instance.group) {
                        angular.forEach(group.users, function(user) {
                            currentUsers.push(user.id);
                        })
                    };
                });
                angular.forEach($scope.group.users, function(user) {
                    if (user.is_checked) {
                        currentUsers.push(user.id);
                    };
                });
                Group.patch({
                    id: instance.group,
                    users: currentUsers
                }).$promise.then($scope.update);
            }, function() {});
        };


        $scope.openModalAddToGroup = function(instance) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-to-group.html',
                controller: 'ModalAddToGroupController',
                resolve: {
                    instance: function() {
                        return instance;
                    },
                    groups: function() {
                        return groups;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_small'
            })
        };
    });

grandclassApp.controller("HomeworksController",
     function ($scope, $rootScope, $state,
              course, homework, modules, attachments) {

    	$scope.attachments = attachments;
     	$scope.course = course;
     	$scope.modules = modules;
     	$scope.homework = homework;
     	$scope.resetHeader();
    });

grandclassApp.controller("HomeworksAttemptController",
    function ($scope, $rootScope, $state, $location, $timeout,
    		  HomeworkAttempt, HomeworkComment, Notification,
			  attempt, homework, profile, comments) {

    	$scope.attempt = attempt;
    	$scope.homework = homework;
    	$scope.comments = comments;

        $scope.resetHeader();
        $rootScope.pageTitle = $scope.homework.title;
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.homeworks.view', {id: $scope.homework.id});
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.homework.title;

    	angular.forEach($scope.comments, function(comment) {
    		comment.dateObj = {
            	date: moment(comment.date).format('DD.MM.YYYY'),
            	time: moment(comment.date).format('HH:mm')
            };
    	})

    	$scope.marks = [
	    	{score: 2, title: 'плохо'},
	    	{score: 3, title: 'удовлетворительно'},
	    	{score: 4, title: 'хорошо'},
	    	{score: 5,title: 'отлично'}
    	];

    	$scope.saveComment = function(comment) {
            $scope.commentSending = true;
            HomeworkComment.patch({
                id: comment.id,
                comment: comment.comment
            }).$promise.then(function() {
                comment.commentIsEdit = false;
                $timeout(function() {
                    $scope.commentSending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Комментарий успешно изменен"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            });
        };

        $scope.deleteComment = function(commentId) {
            HomeworkComment.delete({id: commentId}).$promise.then(function() {
            	$state.reload();
            })
        };

    	$scope.save = function(type) {
            
            if (type == 'rework') {
                $scope.attempt.mark = 0;
                $scope.reworkSending = true;
            } else {
                $scope.attempt.mark = 5;
                $scope.doneSending = true;
            }

            if ($scope.attempt.mark != 0) {
                Notification.save({
                    user: $scope.attempt.user.id,
                    message: 'Вам зачли задание "' + $scope.homework.title + '"',
                    link: 'http://'+$scope.organization.domain+'/homeworks/' + $scope.homework.id
                })
            } else {
                Notification.save({
                    user: $scope.attempt.user.id,
                    message: 'Решение задания "' + $scope.homework.title + '" было отправлено на доработку',
                    link: 'http://'+$scope.organization.domain+'/homeworks/' + $scope.homework.id
                })
            }

    		HomeworkAttempt.patch({
    			id: $scope.attempt.id,
    			mark: $scope.attempt.mark
    		}).$promise.then(function() {
    			var data = {
    				attempt: $scope.attempt.id,
	    			user: profile.id,
	    			type: 2
    			}
    			if ($scope.attempt.mark) {
    				data.comment = 'зачел решение';
    			}  else if ($scope.attempt.rework) {
    				data.comment = 'отправил решение на доработку';
    			};
    			HomeworkComment.save(data);
    		}).then(function() {
    			if ($scope.attempt.comment) {
	    			HomeworkComment.save({
	    				attempt: $scope.attempt.id,
	    				user: profile.id,
	    				comment: $scope.attempt.comment,
	    				type: 1
	    			}).$promise.then(function () {
	    			    Notification.save({
                            user: $scope.attempt.user.id,
                            message: 'Новый комментарий в задании "' + $scope.homework.title + '"',
                            link: 'http://'+$scope.organization.domain+'/homeworks/' + $scope.homework.id
                        })
	    			})
    			}
    		}).then(function() {
    			$state.reload();
                $timeout(function() {
                    $scope.reworkSending = $scope.doneSending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Изменения успешно сохранены"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
    		})
    	}
    });

grandclassApp.controller("HomeworksEditController",
    function ($scope, $rootScope, $state, $location, $timeout,
              Homework, Attachment,
              chapters, tests, homeworks, module) {

        $scope.homework.haveAttachments = $scope.attachments.length ? true : false;
        $scope.isEditPage = true;
        $scope.chapters = chapters;
    	$scope.tests = tests;

    	$scope.homeworks = homeworks;

        $scope.resetHeader();
        $rootScope.pageTitle = $scope.course.title;
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.view.about', {id: $scope.course.id, slug: $rootScope.slug($scope.course.title)});
        };

        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function() {
                $state.go('base.app.homeworks.view', {id: $scope.homework.id});
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.homework.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        $scope.my = {
            access: false,
            condition: 'chapter'
        };

        $scope.show_demo = true;

        var elements = module.elements;
        var current_index = null;
        angular.forEach(elements, function (element) {
           if ($scope.homework.id == element.id) {
               current_index = elements.indexOf(element);
           }
        });
        if (current_index > 0) {
            if (elements[current_index - 1].is_demo == false) {
                $scope.show_demo = false;
            }
        }

        $scope.homework.dateObj = {
            date_start: $scope.homework.date ? moment($scope.homework.date).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY')
        };

        $scope.newFileInstance = {
            title: '',
            author: '',
            file: null,
            modalTitle: 'Новый файл',
            course: null,
            homework: $scope.homework.id
        };

    	// проверка на доступ задания по умолчанию
    	if ($scope.homework.chapter_for_access) {
    		$scope.my.access = true;
    		$scope.my.condition = 'chapter';
    	};
    	if ($scope.homework.test_for_access) {
    		$scope.my.access = true;
    		$scope.my.condition = 'test';
    	};
    	if ($scope.homework.homework_for_access) {
    		$scope.my.access = true;
    		$scope.my.condition = 'homework';
    	};


    	// находим все задания курса, кроме текущего
    	$scope.anotherHomeworks = $scope.homeworks.map(function(homework) {
    		if ((homework.id != $scope.homework.id)  && (homework.course === $scope.homework.course)) {
    			return homework;
    		}
    	});

    	$scope.results = [
	    	{score: 3, title: 'удовлетворительно'},
	    	{score: 4, title: 'хорошо'},
	    	{score: 5,title: 'отлично'}
    	];

        $scope.dragularOptions = {
            scope: $scope,
            containersModel: $scope.attachments,
            revertOnSpill: true,
            moves: function (el, container, handle) {
                return !$rootScope.isTouchDevice;
            }
        };

        $scope.$on('dragulardrop', function(e, el) {
            var index = 0;
            angular.forEach($scope.attachments, function(attachment) {
                attachment.changedOrder = index;
                if (attachment.order != attachment.changedOrder) {
                    attachment.order = attachment.changedOrder;
                    Attachment.patch({
                        id: attachment.id,
                        order: attachment.changedOrder
                    });
                };
                index++;
            });
        });

    	$scope.save = function() {
            $scope.sending = true;
            $scope.homework.date = moment($scope.homework.dateObj.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD');
            $scope.homework.teacher = $scope.homework.teacher.id;

            var data = {
                id: $scope.homework.id,
                title: $scope.homework.title,
                date_obj: $scope.homework.date_obj,
                description: $scope.homework.description,
                is_demo: $scope.homework.is_demo
            };

            data.chapter_for_access = data.homework_for_access = data.homework_for_access_mark =
            data.test_for_access = data.test_for_access_mark = null;

    		if ($scope.my.access) {
                switch ($scope.my.condition) {
                    case 'chapter':
                        data.chapter_for_access = $scope.homework.chapter_for_access;
                        break;
                    case 'test':
                        data.test_for_access = $scope.homework.test_for_access;
                        data.test_for_access_mark = $scope.homework.test_for_access_mark;
                        break;
                    case 'homework':
                        data.homework_for_access = $scope.homework.homework_for_access;
                        data.homework_for_access_mark = $scope.homework.homework_for_access_mark;
                        break;
                };
            }

    		Homework.patch(data).$promise.then(function(){
                if (!$scope.homework.haveAttachments && $scope.attachments.length) {
                    angular.forEach($scope.attachments, function(attach) {
                        Attachment.delete({id: attach.id});
                    })
                }
            }).then(function() {
                $state.reload();
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Изменения успешно сохранены"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            })
    	};
    });

grandclassApp.controller("HomeworksViewController",
    function ($scope, $rootScope, $state, $location, $timeout,
    		  HomeworkAttempt, Attachment, Upload, Notification,
              homework, attempts, profile, comments) {

        $scope.resetHeader();
        $rootScope.pageTitle = $scope.course.title;
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.view.about', {id: $scope.course.id});
        };

        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Редактировать';
            $rootScope.btnClick = function() {
                $state.go('base.app.homeworks.edit', {id: $scope.homework.id});
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.homework.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        $scope.isEditPage = false;
        $scope.profile = profile;
        $scope.homework = homework;
        $scope.attempts = attempts;
        $scope.comments = comments;
        $scope.homework.isPast = moment($scope.homework.date) > moment() ? false : true;
        $scope.homework.date = moment($scope.homework.date).format('DD.MM.YYYY');
        $scope.attempt = {
        	id: 5
        };

        $scope.sortType = 'sortId';
        $scope.sortReverse = true;

        if ($rootScope.isStudent) {
        	if (!$scope.attempts.length) {
	        	HomeworkAttempt.save({user: $scope.profile.id, homework: $scope.homework.id, solution: 'Ваше решение'}).$promise.then(function(data) {
	        		$scope.attempt = data;
	        	})
        	} else {
        		$scope.attempt = $scope.attempts[0];
        	};
        };

        angular.forEach($scope.comments, function(comment) {
            comment.dateObj = {
                date: moment(comment.date).format('DD.MM.YYYY'),
                time: moment(comment.date).format('HH:mm')
            };
        })

        // возвращает нужный класс для вывода оценки
        $scope.addScoreClassname = function(score) {
            score = parseInt(score);
            if (score == 5) {
                return 'score_high';
            } else if (score == 4) {
                return 'score_middle';
            } else {
                return 'score_low';
            }
        };

        $scope.newFileInstance = {
            title: '',
            author: $scope.profile.short_name,
            file: null,
            modalTitle: 'Новый файл',
            attempt: $scope.attempt.id
        };

        $scope.addFile = function(newFileInstance) {
            var modalInstance = $scope.openModalEditFile(newFileInstance);
            modalInstance.result.then(function(instance) {
                Attachment.save({
                    attempt: instance.attempt,
                    author: instance.author,
                    title: instance.title
                }, function (data) {
                    newId = data.id;
                }).$promise.then(function() {
                    if (instance.changedFileObj) {
                        Upload.upload({
                            url: '/api/attachments/' + newId,
                            data: {file: instance.changedFileObj},
                            method: 'PATCH'
                        });
                    }
                }).then(function() {
                	$scope.solution = $scope.attempt.solution;
	                HomeworkAttempt.query({homework: $scope.homework.id, user: $scope.profile.id}).$promise.then(function(data) {
	                    $scope.attempt = data[0];
	                    $scope.attempt.solution = $scope.solution;
	                });
                });
            }, function() {});
        };

        $scope.save = function() {
            $scope.sending = true;
        	HomeworkAttempt.patch({
        		id: $scope.attempt.id,
        		solution: $scope.attempt.solution
        	}).$promise.then(function () {
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Решение успешно сохранено"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
        	    Notification.save({
                    link: 'http://'+$scope.organization.domain+'/attempt/' + $scope.attempt.id,
                    message: 'Новое решение вашего задания "' + $scope.homework.title + '"',
                    user: $scope.homework.teacher.id
                })
        	})
        };
    });

grandclassApp.controller("IndexController",
    function ($scope, $rootScope, $state, $location,
              slides, teachers, cities, specialities, grades, indCourses, groupCourses, shopCourses, news, partners, webinars) {

        $scope.state = $state.current.name;
        $scope.slides = slides;
        $scope.teachers = teachers;
        $scope.cities = cities;
        $scope.specialities = specialities;
        $scope.grades = grades;
        $scope.indCourses = indCourses;
        $scope.groupCourses = groupCourses;
        $scope.shopCourses = shopCourses;
        $scope.news = news;
        $scope.partners = partners;
        $scope.webinars = webinars;
        $rootScope.isMainPage = true;

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.index_title;
        $rootScope.metaObj.description = $scope.organization.index_description;
        $rootScope.metaObj.keywords = $scope.organization.index_keywords;

        $scope.filterExperts = function() {
            angular.forEach($scope.teachers, function(teacher) {
                teacher.filteredByCity = teacher.filteredBySpeciality = teacher.filteredByGrade = true;
            });
        };

        $scope.expertsFilterObj = {
            city: 'Все',
            speciality: 'Все',
            grade: 'Все'
        };

        $scope.slickMainConfig = {
            dots: true,
            arrows: false,
            method: {},
            autoplay: true,
            autoplaySpeed: $scope.organization.slider_speed * 4000
        };

        $scope.filterExpertsByCity = function() {
            angular.forEach($scope.teachers, function(teacher) {
                teacher.filteredByCity = ((teacher.city === $scope.expertsFilterObj.city) || ($scope.expertsFilterObj.city === 'Все')) ? true : false;
            });
            $scope.slickExpertsConfig.method.slickGoTo(0);
        };

        $scope.filterExpertsBySpeciality = function() {
            angular.forEach($scope.teachers, function(teacher) {
                teacher.filteredBySpeciality = ((teacher.speciality === $scope.expertsFilterObj.speciality) || ($scope.expertsFilterObj.speciality === 'Все')) ? true : false;
            });
            $scope.slickExpertsConfig.method.slickGoTo(0);
        };

        $scope.filterExpertsByGrade = function() {
            angular.forEach($scope.teachers, function(teacher) {
                teacher.filteredByGrade = ((teacher.grade === $scope.expertsFilterObj.grade) || ($scope.expertsFilterObj.grade === 'Все')) ? true : false;
            });
            $scope.slickExpertsConfig.method.slickGoTo(0);
        };

        $scope.slickExpertsConfig = {
            arrows: false,
            variableWidth: true,
            infinite: false,
            draggable: false,
            method: {}
        };

        $scope.webinarsFilterObj = {
            date: {
                date_start: moment().format('DD.MM.YYYY'),
                date_end: moment(this.date_start).add(14, 'days').format('DD.MM.YYYY')
            }
        };

        $scope.groupFilterObj = {
             date: {
                date_start: moment().format('DD.MM.YYYY'),
                date_end: moment(this.date_start).add(14, 'days').format('DD.MM.YYYY')
            }
        };

        $scope.filterWebinarsByDate = function() {
            angular.forEach($scope.webinars, function(webinar) {
                webinar.filteredByDate = false;
                var date = moment(webinar.date);
                var filterDateStart = moment($scope.webinarsFilterObj.date.date_start, 'DD.MM.YYYY');
                var filterDateEnd = moment($scope.webinarsFilterObj.date.date_end, 'DD.MM.YYYY');
                if (!filterDateEnd.isValid()) {
                    if (date >= filterDateStart) {
                        webinar.filteredByDate = true;
                    }
                } else {
                    if (date <= filterDateEnd && date >= filterDateStart) {
                        webinar.filteredByDate = true;
                    }
                }
            })
        };

        $scope.filterGroupByDate = function() {
            angular.forEach($scope.groupCourses, function(course) {
                course.filteredByDate = false;
                var date = moment(course.actual_group.end, 'YYYY-MM-DD');
                var filterDateStart = moment($scope.groupFilterObj.date.date_start, 'DD.MM.YYYY');
                var filterDateEnd = moment($scope.groupFilterObj.date.date_end, 'DD.MM.YYYY');
                if (!filterDateEnd.isValid()) {
                    if (date.isSameOrAfter(filterDateStart)) {
                        course.filteredByDate = true;
                    }
                } else {
                    if (date.isSameOrBefore(filterDateEnd) && date.isSameOrAfter(filterDateStart)) {
                        course.filteredByDate = true;
                    }
                }
            })
        };

        $scope.slickPartnersConfig = {
            arrows: false,
            variableWidth: true,
            infinite: false,
            draggable: false,
            method: {}
        };
    });

grandclassApp.controller("LoginController",
    function ($scope, $rootScope, $state, $timeout,
              User,
              organization) {

        $scope.fail = false;
        $scope.organization = organization[0];

        $scope.login = function () {
            $scope.sending = true;
            User.login({'email': $scope.email, 'password': $scope.password}).$promise.then(function (data) {
                if (data.status == 200) {
                    $state.go('base.app.calendar');
                } else if (data.status == 403) {
                    $scope.inactive = true;
                    $scope.sending = false;
                }
                else {
                    $scope.fail = true;
                    $scope.sending = false;
                }
            })
        }
    });

grandclassApp.controller("MessagesController",
    function ($scope, $rootScope, $interval, $timeout,
              Chat, Message, Upload, unreadCount,
              chats, messages, users, participant) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Сообщения';
     	$rootScope.addBtnText = 'Начать новую беседу';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Сообщения';
    
        $scope.chats = chats;
        $scope.users = users;
        $scope.message = {};
        $scope.messages = messages;
        $scope.addingChat = false;
        $scope.messagesLoading = false;
        $scope.chatIsOpened = false;
        $scope.participant = participant;
        $scope.empty = false;

        $scope.anotherUsers = $scope.users.map(function(user) {
            if (user.id != $scope.profile.id) {
                return user;
            }
        });

        if ($scope.chats.length) {
            var keepGoing = true;
            angular.forEach($scope.chats, function (chat) {
                if (!chat.hidden && keepGoing) {
                    $scope.chat = chat;
                    keepGoing = false;
                }
            })
        };

        $scope.dropzoneMessageOptions = {
            url: '/',
            autoProcessQueue: false,
            addRemoveLinks : false,
            uploadMultiple: false,
            dictDefaultMessage:
                '<div for="form-message-file" class="link_icon link_attach"><span class="icon-attach"></span>Прикрепить файл</div>',
            previewTemplate:
                '<div><a class="link_icon link_attach"><span class="icon-attach"><span data-dz-name></span></span></a>' +
                '<span class="form-drop__remove" data-dz-remove><span class="icon-close"></span></span></div>'
        };

        $scope.dropzoneMessageCallbacks = {
            'addedfile': function(file, dataUrl) {
                $scope.message.attach = file
            }
        };

        $scope.dropzoneMessageMethods = {};

        $scope.markAsRead = function () {
            angular.forEach($scope.messages, function (message) {
                if (!message.read_at && message.sender.id != $scope.profile.id) {
                    Message.patch({id: message.id, read_at: moment()}).$promise;
                }
            });
            $rootScope.unreadCount = unreadCount.count;
        }

        $scope.update = function() {
            Chat.query().$promise.then(function (chats) {
                Message.query({chat: $scope.chat.id}).$promise.then(function (data) {
                    $scope.chats = chats;
                    $scope.messages = data;
                    $scope.markAsRead();
                }).then(function() {
                    $scope.messagesLoading = false;
                    $scope.updateScrollbar('scrollTo', 'bottom');
                });
            });
        };

        $interval(function () {$scope.update()}, 30000);

        $rootScope.addBtnClick = function () {
            $scope.addingChat = true;
            $scope.chat = {
                participant: null,
                title: ''
            };
        };

        $scope.changeChat = function (chat) {
            $scope.chatIsOpened = !$scope.chatIsOpened;
            if ( ($scope.chat && ($scope.chat.id !== chat)) || !$scope.chat) {
                $scope.messagesLoading = true;
                $scope.messages = '';
                $scope.message.body = '';
                Chat.get({id: chat}).$promise.then(function (data) {
                    $scope.chat = data;
                    $scope.update();
                });
            };
        };

        $scope.deleteChat = function (chatId) {
            Chat.hide({id: chatId}).$promise.then($scope.update).then(function () {
                $scope.chat = {};
                if ($scope.chats.length) {
                    angular.forEach($scope.chats, function (chat) {
                        if (!chat.hidden) {
                            $scope.chat = chat;
                        }
                    })
                };
            });
        };

        $scope.restoreChat = function(chatId) {
            Chat.patch({
                id: chatId,
                hidden_1: false,
                hidden_2: false
            }).$promise.then($scope.update);
        };

        if ($scope.participant) {
            var chatIsExist = false;
            angular.forEach($scope.chats, function(chat) {
                if (chat.participant.id === $scope.participant) {
                    chatIsExist = true;
                    if (chat.hidden) {
                        $scope.restoreChat(chat.id);
                    };
                    $scope.changeChat(chat.id);
                }
            });
            if (!chatIsExist) {
                $scope.addingChat = true;
                $scope.chat = {
                    participant: $scope.participant,
                    title: ''
                };
            };
        };

        $scope.send = function () {
            if (!$scope.message.body) {
                $scope.empty = true;
                return
            } else {
                $scope.empty = false;
            }
            if ($scope.addingChat) {
                if ($scope.chat.participant) {
                    var chatIsExist = false;
                    angular.forEach($scope.chats, function(chat) {
                        if (chat.participant.id === $scope.chat.participant && (!chat.hidden_1 || !chat.hidden_2)) {
                            $scope.restoreChat(chat.id);
                            chatIsExist = chat.id;
                        }
                    });
                    if (chatIsExist) {
                        $scope.saveMessage(chatIsExist);
                    } else {
                        Chat.save({participant: $scope.chat.participant}).$promise.then(function(data) {
                            $scope.saveMessage(data.id);
                        });
                    }
                    $scope.addingChat = false;
                }
            } else {
                $scope.saveMessage($scope.chat.id);
            }
        };

        $scope.saveMessage = function(chatId) {
            $scope.messagesLoading = true;
            Message.save({chat: chatId, body: $scope.message.body}).$promise.then(function(data) {
                if ($scope.message.attach) {
                    Upload.upload({
                        url: '/api/messages/' + data.id,
                        data: {attachment: $scope.message.attach},
                        method: 'PATCH'
                    }).then(function() {
                        Chat.get({id: data.chat}).$promise.then(function (chat) {
                            $scope.chat = chat;
                            $scope.message.body = $scope.message.attach = '';
                            $scope.dropzoneMessageMethods.removeAllFiles();
                            $scope.update();
                        });
                    })
                } else {
                    Chat.get({id: data.chat}).$promise.then(function (chat) {
                        $scope.chat = chat;
                        $scope.message.body = $scope.message.attach = '';
                        $scope.dropzoneMessageMethods.removeAllFiles();
                        $scope.update();
                    });
                }
            })
        };


    });

grandclassApp.controller("ModalAddFAQController",
   	function ($scope, $modalInstance,
              instance) {

    	$scope.instance = instance;

    	$scope.save = function() {
    		$modalInstance.close($scope.instance);
    	};

    	$scope.cancel = function() {
          $modalInstance.dismiss('cancel');
      };

      $scope.categoriesArray = [
      	{value: 1, title: 'О платформе'},
      	{value: 2, title: 'Регистрация'},
      	{value: 3, title: 'Управление материалами'},
      	{value: 4, title: 'Управление пользователями'}
      ];

    });

grandclassApp.controller("ModalAddGroupController",
    function ($scope, $modalInstance,
              instance, students, courses) {

        console.log(instance);

        $scope.instance = instance;
        $scope.students = students.results;
        $scope.courses = courses.results;

        $scope.instance.date = {
            date_start: $scope.instance.date_start ? moment($scope.instance.date_start, 'DD.MM.YYYY').format('DD.MM.YYYY') : moment().format('DD.MM.YYYY'),
            date_end: $scope.instance.date_end ? moment($scope.instance.date_end, 'DD.MM.YYYY').format('DD.MM.YYYY') : moment().add(7, 'days').format('DD.MM.YYYY')
        };

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function(type) {
            $modalInstance.close($scope.instance);
        };
    });

grandclassApp.controller("ModalAddPartnerController",
    function ($scope, $modalInstance,
              instance) {
    	
    	$scope.instance = instance;

    	$scope.save = function() {
    		$modalInstance.close($scope.instance);
    	};

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.dropzonePartnerOptions = {
            url: '/',
            autoProcessQueue: false,
            acceptedFiles : 'image/jpeg, images/jpg, image/png',
            addRemoveLinks : false,
            uploadMultiple: true,
            thumbnailWidth: 285,
            thumbnailHeight: null,
            dictDefaultMessage: '<span class="button button_white">Сменить фото</span>',
            previewTemplate: '<img hidden data-dz-thumbnail />'
        };

        $scope.dropzonePartnerCallbacks = {
            'thumbnail': function(file, dataUrl) {
               $scope.instance.coverFile = file;
               $scope.instance.coverImage = dataUrl;
            }
        };

    });

grandclassApp.controller("ModalAddQuestionController",
    function ($scope, $modalInstance,
              instance) {

        $scope.instance = instance;

        $scope.questionTypes = [
            {id: 1, title: 'Развернутый ответ'},
            {id: 2, title: 'Выбор из нескольких ответов'},
            {id: 3, title: 'Да/нет'},
        ];

        $scope.instance.answers = [{
            answer: '',
            is_right: false,
            is_new: true
        }];

        $scope.instance.defaultAnswers = [
            {answer: 'Да', is_right: true, is_new: true},
            {answer: 'Нет', is_right: false, is_new: true}
        ];

        $scope.checkAnswer = function(answer) {
            angular.forEach($scope.instance.defaultAnswers, function(defaultAnswer) {
                defaultAnswer.is_right = false;
            });
            answer.is_right = true;
        };

        $scope.addAnswer = function() {
            $scope.instance.answers.push({
                answer: '',
                is_right: false,
                is_new: true
            });
            $scope.buildDropzoneOptions($scope.instance.answers[$scope.instance.answers.length - 1]);
        };

        $scope.removeAnswer = function(index) {
            $scope.instance.answers[index].is_delete = true;
        };

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            $modalInstance.close($scope.instance);
        };

        $scope.instance.dropzoneOptions = {
            url: '/',
            autoProcessQueue: false,
            addRemoveLinks : false,
            uploadMultiple: false,
            dictDefaultMessage:
                '<div for="form-message-file" class="link_icon link_attach"><span class="icon-attach"></span>Добавить изображение</div>',
            previewTemplate:
                '<div><a class="link_icon link_attach"><span class="icon-attach"><span data-dz-name></span></span></a>' + 
                '<span class="form-drop__remove" data-dz-remove><span class="icon-close"></span></span></div>'
        };
        $scope.instance.dropzoneCallbacks = {
            'addedfile': function(file, dataUrl) {
                $scope.instance.fileImage = file
            }
        };
        $scope.instance.dropzoneMethods = {};

        $scope.buildDropzoneOptions = function(answer) {
            answer.dropzoneOptions = {
                url: '/',
                autoProcessQueue: false,
                addRemoveLinks : false,
                uploadMultiple: false,
                dictDefaultMessage:
                    '<div for="form-message-file" class="link_icon link_attach"><span class="icon-attach"></span>Добавить изображение</div>',
                previewTemplate:
                    '<div><a class="link_icon link_attach"><span class="icon-attach"><span data-dz-name></span></span></a>' + 
                    '<span class="form-drop__remove" data-dz-remove><span class="icon-close"></span></span></div>'
            };
            answer.dropzoneCallbacks = {
                'addedfile': function(file, dataUrl) {
                    answer.fileImage = file
                }
            };
            answer.dropzoneMethods = {};
        };

        angular.forEach($scope.instance.answers, function(answer) {
            $scope.buildDropzoneOptions(answer);
        });
    });

grandclassApp.controller("ModalAddRequestController",
   	function ($scope, $modalInstance,
              instance, courses, webinars, events, students, groups) {

    	$scope.instance = instance;
    	$scope.courses = courses.results;
    	$scope.events = events.results;
    	$scope.webinars = webinars;
    	$scope.students = students.results;
    	$scope.groups = groups;

    	$scope.save = function() {
    		if ($scope.instance.type != 'course_group') {
    			$scope.instance.group = null;
			}
    		$modalInstance.close($scope.instance);
    	};

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.typesArray = [
        	{value: 'course_ind', title: 'Курс (инд.)'},
        	{value: 'course_group', title: 'Курс (групп.)'},
        	{value: 'event', title: 'Мероприятие'},
        	{value: 'webinar', title: 'Вебинар'}
        ];


    });

grandclassApp.controller("ModalAddTagsController",
    function ($scope, $modalInstance,
              instance) {
    	
    	$scope.instance = instance;

    	$scope.save = function() {
    		$modalInstance.close($scope.instance);
    	};

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

    });

grandclassApp.controller("ModalAddTermsController",
    function ($scope, $rootScope, $modalInstance,
              instance) {
    	
    	$scope.instance = instance;
        $scope.activeTab = 1;
        $scope.file = null;

    	$scope.save = function() {
            $scope.instance.typeofInstance = 'term';
            if (instance.title == '' || instance.definition == '') {
                $rootScope.notice = $rootScope.notification({
                                text: "Термин не может содержать пустое название или опредение!"
                            });
                $rootScope.notice.result.then(function (instance) {}, function(){});
                return false;
            };
    		$modalInstance.close($scope.instance);
    	};

        $scope.import = function() {
            if ($scope.file) {
                $scope.file.typeofInstance = 'file';
                $modalInstance.close($scope.file);
            }
        };

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.dropzoneImportTermsOptions = {
            url: '/',
            autoProcessQueue: false,
            addRemoveLinks : false,
            acceptedFiles: '.xlsx',
            uploadMultiple: false,
            maxFiles: 1,
            dictDefaultMessage: '<span class="button">Загрузить файл</span>',
            previewTemplate: '<div dz-file-preview><div class="modal__filename">Файл загружен: "<span data-dz-name></span>"</div></div>'
        };

        $scope.dropzoneImportTermsCallbacks = {
            'addedfile': function(file) {
                if ($scope.file) {
                     $scope.dropzoneImportTermsMethods.removeFile($scope.file);
                };
                $scope.file = file;
            }
        };

        $scope.dropzoneImportTermsMethods = {};

    });

grandclassApp.controller("ModalAddToGroupController",
    function ($scope, $rootScope, $modalInstance,
              instance, groups) {

        $scope.instance = instance;
        $scope.groups = groups;

        if ($rootScope.isTeacher) {
            $scope.teacherGroups = [];
            angular.forEach($scope.groups, function(group) {
                if (group.can_edit) {
                    $scope.teacherGroups.push(group);
                };
            });
            $scope.groups = $scope.teacherGroups;
        };

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            $modalInstance.close($scope.instance);
        };
    });

grandclassApp.controller("ModalAddUsersController",
    function ($scope, $rootScope, $modalInstance, $modal,
              activeTab, instance, groups) {

        $scope.activeTab = activeTab;
        $scope.instance = instance;
        $scope.instance.avatarImage = $scope.instance.avatar;
        $scope.instance.fileImport = null;
        $scope.groups = groups;
        $scope.emails = [];
        $scope.q_result = {};
        $scope.cropper = {};
        $scope.validation = {email_format: false};
        function check_email(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase())
        }
        if ($rootScope.isTeacher) {
            $scope.teacherGroups = [];
            angular.forEach($scope.groups, function(group) {
                if (group.can_edit) {
                    $scope.teacherGroups.push(group);
                };
            });
            $scope.groups = $scope.teacherGroups;
        };

        $scope.specialities = ['Психолог', 'Клинический психолог', 'Врач-психотерапевт', 'Врач-психиатр'];
        $scope.grades = ['Нет', 'Кандидат психологических наук', 'Доктор медицинских наук'];

        $scope.changeTab = function(tab) {
          $scope.validation.email_format = false;
          $scope.activeTab = tab;
        }

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function(type) {
            $scope.validation.email_format = false;
            if (type === 'user') {
                if (!check_email(instance.email)){
                    $scope.validation.email_format = true;
                    return false
                } else {
                    $scope.validation.email_format = false;
                }
            } else if (type === 'users') {
                angular.forEach($scope.instance.users.emails, function (email) {
                    if (!check_email(email)) {
                        $scope.validation.email_format = true;
                        return false
                    }
                });
            }

            if (!$scope.validation.email_format) {
                instance.type = type;
                $scope.sending = true;
                $scope.instance.custom_fields = check_custom_fields();
                $modalInstance.close($scope.instance);
                return false;
            };
        }

        if ($rootScope.isAdmin) {
            $scope.roles = [
                {value:"student", title: 'Студент'},
                {value:"teacher", title: 'Преподаватель'},
                {value:"admin", title: 'Администратор'}
            ];
        } else {
            $scope.roles = [{value:"student", title: 'Студент'}];
            $scope.instance.role = $scope.roles[0];
        }
        $scope.openModalImageCropper = function() {
            return $modal.open({
                templateUrl: '/static/views/app/cropper_modal.html',
                controller: 'ImageCropperCtrl',
                backdrop: 'static',
                windowClass: 'modal__content_big',
                resolve: {
                    instance: function () {
                        return $scope.cropper;
                    }
                }
            });
        };

        $scope.getFileFromImage = function(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        };

		$scope.resizeImage = function(filename, file) {
                $scope.cropper.sourceImage = file;
                $scope.cropper.ratio = $scope.organization.avatar_ratio;
                var modalInstance = $scope.openModalImageCropper();
                modalInstance.result.then(function (instance) {
                    $scope.instance.avatarFile = $scope.getFileFromImage(instance.croppedImage, filename);
                    $scope.instance.avatarImage = instance.croppedImage;
                });
        };

        function check_custom_fields() {
            let custom_fields = [];
            let i = 0;
            $scope.organization.custom_fields.values.forEach(function(item){
                let value = $scope.q_result['cf'+item.id];
                if (value) {
                    custom_fields[i] = [item.name, value];
                    i += 1;
                }
                else if (item.field_type == 1) { // if checkbox is not checked, do this
                    custom_fields[i] = [item.name, 'False'];
                    i += 1;
                }
            });
            return custom_fields;
        }

        // настройки для Dropzone
        $scope.dropzoneUserOptions = {
            url: '/',
            autoProcessQueue: false,
            acceptedFiles : 'image/jpeg, images/jpg, image/png',
            addRemoveLinks : false,
            uploadMultiple: false,
            thumbnailWidth: 230,
            thumbnailHeight: null,
            dictDefaultMessage: '<span class="button button_outline">Сменить фото</span>',
            previewTemplate: '<img hidden data-dz-thumbnail />'
        };

        $scope.dropzoneUserCallbacks = {
            'thumbnail': function(file, dataUrl) {
                $scope.resizeImage(file.name, dataUrl);

            }
        };

        $scope.dropzoneImportUsersOptions = {
            url: '/',
            autoProcessQueue: false,
            addRemoveLinks : false,
            acceptedFiles: '.xlsx',
            uploadMultiple: false,
            maxFiles: 1,
            dictDefaultMessage: '<span class="button">Загрузить файл</span>',
            previewTemplate: '<div dz-file-preview><div class="modal__filename">Файл загружен: "<span data-dz-name></span>"</div></div>'
        };

        $scope.dropzoneImportUsersCallbacks = {
            'addedfile': function(file) {
                if ($scope.instance.fileImport) {
                    $scope.dropzoneImportTermsMethods.removeFile($scope.instance.fileImport);
                };
                $scope.instance.fileImport = file;
            }
        };

        $scope.dropzoneImportUsersMethods = {};

    });

grandclassApp.controller("ModalEditAttachmentsController",
    function ($scope, $rootScope, $modalInstance, $timeout,
              instance) {

        $scope.instance = instance;

        $scope.instance.changedFile = instance.file;
        $scope.instance.changedFileObj = instance.fileObj;
        $scope.instance.changedTitle = instance.title;
        $scope.instance.changedAuthor = instance.author;

        $scope.cancel = function() {
            $scope.dropzone.destroy();
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            // проверка на наличие названия
            if ($scope.instance.changedTitle == '') {
                $rootScope.notice = $rootScope.notification({
                                text: "Файл должен содержать название!"
                            });
                $rootScope.notice.result.then(function() {}, function() {});
                return false;
            };

            // проверка на удаление файла
            if ($scope.instance.changedFile == null && $scope.instance.changedFileObj == null) {
                $rootScope.notice = $rootScope.notification({
                                text: "Файл должен содержать файл!"
                            });
                $rootScope.notice.result.then(function() {}, function() {});
                return false;
            };

            $scope.instance.title = $scope.instance.changedTitle;
            $scope.instance.author = $scope.instance.changedAuthor;
            $modalInstance.close($scope.instance);
            $rootScope.notice = $rootScope.notification({
                text: "Файл успешно добавлен"
            });
            $rootScope.notice.result.then(function () {}, function () {});
            return false;
        };

        $scope.dropzoneFileOptions = {
            url: '/',
            autoProcessQueue: false,
            addRemoveLinks : false,
            uploadMultiple: false,
            acceptedFiles: '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, image/*',
            dictDefaultMessage:
                '<p class="form-drop__main">Перетащите файл сюда</p>' +
                '<p class="form-drop__description">в формате doc, docx, pdf, xls, xlsx, ppt, pptx, jpg, jpeg, png</p>' +
                '<span class="button button_icon-left form-drop__add">' +
                '<span class="icon-plus icon-left"></span>' +
                '<span>Добавить файл</span>' +
                '</span>',
            previewTemplate:
                '<div class="form-drop__file">' +
                  '<span class="form-drop__remove" data-dz-remove><span class="icon-close"></span></span>' +
                  '<div class="form-drop__details">' +
                    '<div class="form-drop__filename"><span data-dz-name></span></div>' +
                  '</div>' +
                '</div>',
        };

        $scope.dropzoneFileCallbacks = {
            'addedfile': function(file, dataUrl) {
                if ($scope.instance.changedFileObj) {
                    $scope.dropzoneFileMethods.removeFile($scope.instance.changedFileObj);
                };
                $scope.instance.changedFileObj = file;
                angular.element($scope.dropzone.element).addClass('dz-started');
            },
            'removedfile': function() {
                $scope.instance.changedFile = null;
                $scope.instance.changedFileObj = null;
            }
        };

        $scope.dropzoneFileMethods = {};

        // получаем ссылку на dropzone (для загрузки существующих файлов и выполнения метода destroy)
        $scope.dropzone = null;
        $timeout(function() {
            $scope.dropzone = $scope.dropzoneFileMethods.getDropzone();
        });

        // загружаем в dropzone текущий файл, если такой есть
        if ($scope.instance.changedFile || $scope.instance.changedFileObj) {

            var existFileName;

            if ($scope.instance.changedFile) {
                existFileName = $rootScope.getFilename($scope.instance.changedFile);
            } else if ($scope.instance.changedFileObj) {
                existFileName = $scope.instance.changedFileObj.name;
            };

            $scope.mockfile = {
                name: existFileName
            };

            $timeout(function() {
                $scope.dropzone.emit('addedfile', $scope.mockfile);
                $scope.dropzone.emit('complete', $scope.mockfile);
            });
        };

    });

grandclassApp.controller("ModalEditGroupController",
    function ($scope, $modalInstance,
              instance, students) {

        $scope.instance = instance;
        $scope.students = students.results;

        $scope.instance.date = {
            date_start: $scope.instance.date_start ? moment($scope.instance.date_start).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY'),
            date_end: $scope.instance.date_end ? moment($scope.instance.date_end).format('DD.MM.YYYY') : moment().add(7, 'days').format('DD.MM.YYYY')
        };

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function(type) {
            $modalInstance.close($scope.instance);
        };
    });

grandclassApp.controller("ModalEditPartnerController",
    function ($scope, $modalInstance,
              instance) {
    	
    	$scope.instance = instance;

    	$scope.save = function() {
    		$modalInstance.close($scope.instance);
    	};

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.dropzonePartnerOptions = {
            url: '/',
            autoProcessQueue: false,
            acceptedFiles : 'image/jpeg, images/jpg, image/png',
            addRemoveLinks : false,
            uploadMultiple: true,
            thumbnailWidth: 285,
            thumbnailHeight: null,
            dictDefaultMessage: '<span class="button button_white">Сменить фото</span>',
            previewTemplate: '<img hidden data-dz-thumbnail />'
        };

        $scope.dropzonePartnerCallbacks = {
            'thumbnail': function(file, dataUrl) {
               $scope.instance.coverFileEdited = file;
               $scope.instance.coverImage = dataUrl;
            }
        };

    });

grandclassApp.controller("ModalEditQuestionController",
    function ($scope, $modalInstance, $timeout, $rootScope,
              instance, answers) {

        $scope.instance = instance;

        $scope.instance.changedDescription = $scope.instance.description;
        $scope.instance.changedType = $scope.instance.type;
        $scope.instance.changedAnswer = $scope.instance.answer;
        $scope.instance.changedScore = $scope.instance.score;

        $scope.instance.answers = [{
            answer: '',
            is_right: false,
            is_new: true
        }];

        $scope.instance.defaultAnswers = [
            {answer: 'Да', is_right: true, is_new: true},
            {answer: 'Нет', is_right: false, is_new: true}
        ];

        if ($scope.instance.type === 2) {
            $scope.instance.answers = answers;
        } else if ($scope.instance.type === 3) {
            $scope.instance.defaultAnswers = answers;
        }

        $scope.questionTypes = [
            {id: 1, title: 'Развернутый ответ'},
            {id: 2, title: 'Выбор из нескольких ответов'},
            {id: 3, title: 'Да/нет'},
        ];

        $scope.checkAnswer = function(answer) {
            angular.forEach($scope.instance.defaultAnswers, function(defaultAnswer) {
                defaultAnswer.is_right = false;
            });
            answer.is_right = true;
        };

        $scope.addAnswer = function() {
            $scope.instance.answers.push({
                answer: '',
                is_right: false,
                is_new: true
            });
            $scope.buildDropzoneOptions($scope.instance.answers[$scope.instance.answers.length - 1]);
        };

        $scope.removeAnswer = function(index) {
            $scope.instance.answers[index].is_delete = true;
        }

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            $modalInstance.close($scope.instance);
        };

        $scope.instance.dropzoneOptions = {
            url: '/',
            autoProcessQueue: false,
            addRemoveLinks : false,
            uploadMultiple: false,
            dictDefaultMessage:
                '<div for="form-message-file" class="link_icon link_attach"><span class="icon-attach"></span>Добавить изображение</div>',
            previewTemplate:
                '<div><a class="link_icon link_attach"><span class="icon-attach"><span data-dz-name></span></span></a>' + 
                '<span class="form-drop__remove" data-dz-remove><span class="icon-close"></span></span></div>'
        };
        $scope.instance.dropzoneCallbacks = {
            'addedfile': function(file, dataUrl) {
                $scope.instance.fileImage = file
            }
        };
        $scope.instance.dropzoneMethods = {};

        $scope.instance.dropzone = null;

        $timeout(function() {
            $scope.instance.dropzone = $scope.instance.dropzoneMethods.getDropzone();
        });

        // загружаем в dropzone текущий файл, если такой есть
        if ($scope.instance.image) {
            var mockfile = {
                name: $rootScope.getFilename($scope.instance.image)
            };
            $timeout(function() {
                $scope.instance.dropzone.emit('addedfile', mockfile);
                $scope.instance.dropzone.emit('complete', mockfile);
                $scope.instance.dropzone.files.push(mockfile);
            });
        };

        $scope.buildDropzoneOptions = function(answer) {
            answer.dropzoneOptions = {
                url: '/',
                autoProcessQueue: false,
                addRemoveLinks : false,
                uploadMultiple: false,
                dictDefaultMessage:
                    '<div for="form-message-file" class="link_icon link_attach"><span class="icon-attach"></span>Добавить изображение</div>',
                previewTemplate:
                    '<div><a class="link_icon link_attach"><span class="icon-attach"><span data-dz-name></span></span></a>' + 
                    '<span class="form-drop__remove" data-dz-remove><span class="icon-close"></span></span></div>'
            };
            answer.dropzoneCallbacks = {
                'addedfile': function(file, dataUrl) {
                    answer.fileImage = file
                }
            };
            answer.dropzoneMethods = {};

            answer.dropzone = null;

            $timeout(function() {
                answer.dropzone = answer.dropzoneMethods.getDropzone();
            });

            // загружаем в dropzone текущий файл, если такой есть
            if (answer.image) {
                var mockfile = {
                    name: $rootScope.getFilename(answer.image)
                };
                $timeout(function() {
                    answer.dropzone.emit('addedfile', mockfile);
                    answer.dropzone.emit('complete', mockfile);
                    answer.dropzone.files.push(mockfile);
                });
            };
        };

        angular.forEach($scope.instance.answers, function(answer) {
            $scope.buildDropzoneOptions(answer);
        });
    });

grandclassApp.controller("ModalEditTagController",
    function ($scope, $rootScope, $modalInstance,
              instance) {

        $scope.instance = instance;

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            $modalInstance.close($scope.instance);
        };
    });

grandclassApp.controller("ModalAddTagsController",
    function ($scope, $modalInstance, $rootScope,
              instance) {
    	
    	$scope.instance = instance;

    	$scope.save = function() {
    		$modalInstance.close($scope.instance);
    		$rootScope.notification({
                    text: 'Создана новая метка',
            }).result.then(function (instance) {
            }, function(){});
            return false;
    	};

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

    });

grandclassApp.controller("ModalEditTermController",
    function ($scope, $rootScope, $modalInstance,
              instance) {

        $scope.instance = instance;
        $scope.instance.changedTitle = instance.title;
    	$scope.instance.changedDefinition = instance.definition;

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            if (instance.changedTitle == '' || instance.changedDefinition == '') {
                $rootScope.notice = $rootScope.notification({
                                text: "Термин не может содержать пустое название или опредение!"
                            });
                $rootScope.notice.result.then(function (instance) {}, function(){});
                return false;
            };
            $scope.instance.title = $scope.instance.changedTitle;
            $scope.instance.definition = $scope.instance.changedDefinition;  
            $modalInstance.close($scope.instance);
        };
    });

grandclassApp.controller("ModalEditUserController",
    function ($scope, $rootScope, $modalInstance,
              instance, groups) {

        $scope.specialities = ['Психолог', 'Клинический психолог', 'Врач-психотерапевт', 'Врач-психиатр'];
        $scope.grades = ['Нет', 'Кандидат психологических наук', 'Доктор медицинских наук'];
        $scope.validation = {email_format: false};
        function check_email(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase())
        }
        $scope.instance = instance;
        $scope.instance.avatarImage = $scope.instance.avatar;
        $scope.groups = groups;
        $scope.instance.name =
            $scope.instance.last_name + ' '
            + $scope.instance.first_name + ' '
            + $scope.instance.middle_name;

        $scope.roles = [
           {value:"student", title: 'Студент'},
           {value:"teacher", title: 'Преподаватель'},
           {value:"admin", title: 'Администратор'}
        ];

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            if (check_email($scope.instance.email)) {
                $scope.validation.email_format = false;
                $modalInstance.close($scope.instance);
            } else {
                $scope.validation.email_format = true;
            }
        };

        // настройки для Dropzone
        $scope.dropzoneUserOptions = {
            url: '/',
            autoProcessQueue: false,
            acceptedFiles : 'image/jpeg, images/jpg, image/png',
            addRemoveLinks : false,
            uploadMultiple: false,
            thumbnailWidth: 230,
            thumbnailHeight: null,
            dictDefaultMessage: '<span class="button button_outline">Сменить фото</span>',
            previewTemplate: '<img hidden data-dz-thumbnail />'
        };

        $scope.dropzoneUserCallbacks = {
            'thumbnail': function(file, dataUrl) {
               $scope.instance.avatarFile = file;
               $scope.instance.avatarImage = dataUrl;
            }
        };
    });

grandclassApp.controller("ModalFaqController",
    function ($scope, $rootScope, $modalInstance,
              items, feedback, users, tags) {

        $scope.tags = tags;
        $scope.items = items;
        $scope.users = users;
        $scope.feedback = feedback;
        $scope.filteredUsers = [];

        $scope.getTeachersByTag = function (tagId) {
            $scope.filteredUsers = [];
            angular.forEach($scope.users, function (user) {
                user_filtered = false;
                angular.forEach(user.tags, function (tag) {
                    if (tag == tagId) {
                        user_filtered = true;
                    }
                })
                if (user_filtered) {
                    $scope.filteredUsers.push(user);
                }
            })
        }

        $scope.save = function() {
            $modalInstance.close($scope.feedback);
        };

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.feedbackTypes = [
            {title: 'Вопрос преподавателю', type: 2},
            {title: 'Технический вопрос', type: 1},
        ];
    });

grandclassApp.controller("ModalRegisterTest",
    function ($scope, $modalInstance, $state, $window,
              User) {

        $scope.user = {
            role: 'Студент',
            grade: 'Нет',
            speciality: 'Психолог',
            examination: 'Не проводилось'
        };

        $scope.validation = {'email': false, 'passwords': false};
        var instance = {};
        instance.registered = false;
        $scope.register = function () {
            if ($scope.user.password === $scope.user.password_confirm && $scope.user.password.length >= 6) {
                $scope.sending = true;
                $scope.validation.password = false;
                $scope.validation.password_min = false;

                $scope.user.name = $scope.user.name || '';

                $scope.user.last_name = $scope.user.name.split(' ')[0];
                $scope.user.first_name = $scope.user.name.split(' ')[1];
                $scope.user.middle_name = $scope.user.name.split(' ')[2];
                $scope.user.role = 'student';

                User.register($scope.user).$promise.then(function (data) {
                    if (data.status === 209) {
                        $scope.validation.email = true;
                    } else {
                        instance.registered = true;
                        instance.data = $scope.user;
                        $modalInstance.close(instance);
                    }
                })
            }
            else if ($scope.user.password !== $scope.user.password_confirm) {
                $scope.validation.password = true;
                $scope.validation.password_min = false;
            }
            else if ($scope.user.password.length < 6) {
                $scope.validation.password = false;
                $scope.validation.password_min = true;

            }
            $scope.sending = false;


        };

        $scope.cancel = function() {
            $modalInstance.close(false);
        };

    });

grandclassApp.controller("ModalTermsController",
    function ($scope, $modalInstance,
              instance) {

    	$scope.instance = instance;

    	$scope.save = function() {
    		$modalInstance.close($scope.instance);
    	};

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

    });

grandclassApp.controller("NewsController",
    function ($scope, $rootScope) {
        $scope.resetHeader();
	    $rootScope.pageTitle = 'Новости';
    });

grandclassApp.controller("NewsEditController",
    function ($scope, $rootScope, $state, $timeout, $q, $location, $modal,
              Upload, News, Notification,
    		  news) {

        $scope.news = news;
        $scope.typeVideo = 'link';
        $scope.cropper = {};

        $scope.resetHeader();

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.news.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.news.image;

        if ($scope.news.is_blog) {
            $rootScope.pageTitle = 'Блог';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.blog');
            };
        } else {
            $rootScope.pageTitle = 'Новости';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.news.list');
            };
        };

        if ($rootScope.isAdmin || $rootScope.isTeacher && $scope.news.is_blog) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function () {
                $state.go('base.app.news.view', {id: $scope.news.id});
            };
        };

        // проверяет, загружено ли уже видео, и если да, то возвращает его название
        $scope.getVideoFile = function() {
            if ($scope.news.video && $scope.news.video.type === 'file') {
                return $rootScope.getFilename($scope.news.video.link)
            } else return false;
        };

        if ($scope.news.video) {
            $scope.hasVideo = true;
            $scope.typeVideo = $scope.news.video.type;
            if ($scope.news.video.type === 'link') {
                $scope.news.video_link = $scope.news.video.link;
            };
        };

        $scope.openModalImageCropper = function() {
            return $modal.open({
                templateUrl: '/static/views/app/cropper_modal.html',
                controller: 'ImageCropperCtrl',
                backdrop: 'static',
                windowClass: 'modal__content_big',
                resolve: {
                    instance: function () {
                        return $scope.cropper;
                    }
                }
            });
        };

        $scope.getFileFromImage = function(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        };

        $scope.resizeImage = function(file, filename) {
            $scope.cropper.sourceImage = file;
            $scope.cropper.ratio = $scope.organization.cover_ratio;
            var modalInstance = $scope.openModalImageCropper();
            modalInstance.result.then(function (instance) {
                let img = instance.croppedImage;
                $scope.coverImage = img;
                $scope.news.coverFile = $scope.getFileFromImage(img, filename);
                })
        };

        $scope.coverImage = $scope.news.image;

        $scope.dropzoneCoverOptions = {
			url: '/',
			autoProcessQueue: false,
			acceptedFiles : 'image/jpeg, images/jpg, image/png',
			addRemoveLinks : false,
			uploadMultiple: true,
			thumbnailWidth: 960,
			thumbnailHeight: null,
			dictDefaultMessage: '<span class="button button_white">Сменить фото</span>',
			previewTemplate: '<img hidden data-dz-thumbnail />'
		};

		$scope.dropzoneCoverCallbacks = {
			'thumbnail': function(file, dataUrl) {
              $scope.resizeImage(dataUrl, file.name);
            }
        };

        $scope.dropzoneVideoOptions = {
            url: '/',
            autoProcessQueue: false,
            addRemoveLinks : false,
            uploadMultiple: false,
            acceptedFiles: '.ogb, .mp4, .webm',
            maxFiles: 1,
            dictDefaultMessage:
                '<p class="form-drop__main">Перетащите файл сюда</p>' +
                '<p class="form-drop__description">в формате ogb, mp4, webm</p>' +
                '<span class="button button_icon-left form-drop__add">' +
                '<span class="icon-plus icon-left"></span>' +
                '<span>Добавить файл</span>' +
                '</span>',
            previewTemplate:
                '<div class="form-drop__file">' +
                  '<span class="form-drop__remove" data-dz-remove><span class="icon-close"></span></span>' +
                  '<div class="form-drop__details">' +
                    '<div class="form-drop__filename"><span data-dz-name></span></div>' +
                  '</div>' +
                '</div>',
        };

        // callbacks
        $scope.dropzoneVideoCallbacks = {
            'addedfile': function(file, dataUrl) {
                angular.element($scope.dropzoneVideo.element).addClass('dz-started');
                $scope.news.video_file = file;
                if ($scope.dropzoneVideo.files.length > 1) {
                    $scope.dropzoneVideoMethods.removeFile($scope.dropzoneVideo.files[0]);
                };
            },
            'removedfile': function() {
                $scope.news.video_file = '';
            }
        };

        // methods
        $scope.dropzoneVideoMethods = {};

        $scope.dropzoneVideo = null;
        $timeout(function() {
            $scope.dropzoneVideo = $scope.dropzoneVideoMethods.getDropzone();
        });

        // функция загружает файл в dropzone по умолчанию
        $scope.dzAddFile = function(filename) {
            var mockfile = {
                name: filename
            };
            $timeout(function() {
                $scope.dropzoneVideo.emit('addedfile', mockfile);
                $scope.dropzoneVideo.emit('complete', mockfile);
                $scope.dropzoneVideo.files.push(mockfile);
            });
        };

        // загружаем в dropzone текущий файл, если такой есть
        if ($scope.getVideoFile()) {
            $scope.dzAddFile($scope.getVideoFile());
        };

        $scope.save = function(status) {

            if (status) {
                $scope.statusSending = true;
            } else {
                $scope.saveSending = true;
            }


            var data = {
                id: $scope.news.id,
                title: $scope.news.title,
                description: $scope.news.description,
                content: $scope.news.content,
                status: $scope.news.status
            };

            data.video_link = '';
            data.video_file = null;

            var uploadVideo = false;

            if ($scope.hasVideo) {
                if ($scope.typeVideo === 'link') {
                    data.video_link = $scope.news.video_link;
                } else {
                    uploadVideo = true;
                };
            };

            News.patch(data).$promise.then(function() {
                var uploadPromises = [];
                if (uploadVideo) {
                    var uploadData = {
                        video_file: $scope.news.video_file
                    };
                    uploadPromises.push(Upload.upload({
                        url: '/api/news/' + $scope.news.id,
                        data: uploadData,
                        method: 'PATCH'
                    }));
                };
                if ($scope.news.coverFile) {
                    var uploadData = {
                        image: $scope.news.coverFile
                    };
                    uploadPromises.push(Upload.upload({
                        url: '/api/news/' + $scope.news.id,
                        data: uploadData,
                        method: 'PATCH'
                    }));
                };
                if (uploadPromises.length) {
                    $q.all(uploadPromises).then(function() {
                        $state.go('base.app.news.view', {id: $scope.news.id});
                        if (!status) {
                            $timeout(function() {
                                $scope.saveSending = false;
                                $scope.statusSending = false;
                                if ($rootScope.notice) {
                                    $rootScope.notice.close();
                                };
                                $rootScope.notice = $rootScope.notification({
                                    text: "Изменения успешно сохранены"
                                });
                                $rootScope.notice.result.then(function(){}, function(){});
                            }, 500);
                        } else {
                            $scope.setStatus(status);
                        };
                    })
                } else {
                    $state.go('base.app.news.view', {id: $scope.news.id});
                    if (!status) {
                        $timeout(function() {
                            $scope.saveSending = false;
                            $scope.statusSending = false;
                            if ($rootScope.notice) {
                                $rootScope.notice.close();
                            };
                            $rootScope.notice = $rootScope.notification({
                                text: "Изменения успешно сохранены"
                            });
                            $rootScope.notice.result.then(function(){}, function(){});
                        }, 500);
                    } else {
                        $scope.setStatus(status);
                    };
                };
            });
        };

        $scope.setStatus = function (status) {
            if (status === 'На модерации' || status === 'Черновик') {
                $scope.statusSending = true;
            } else {
                $scope.publishSending = true;
            };
            News.patch({
                id: $scope.news.id,
                status: status
            }).$promise.then(function () {
                if (status == 'На модерации') {
                    if ($scope.news.is_blog) {
                        message = 'Статья отправлена на модерацию';
                    } else {
                        message = 'Новость отправлена на модерацию';
                    };
                    Notification.save({
                        user: ADMIN_USER_ID,
                        message: message,
                        link: 'http://'+$scope.organization.domain+'/news/' + $scope.news.id + '/edit'
                    }).$promise.then(function () {
                        $state.reload();
                        $timeout(function() {
                            $scope.statusSending = false;
                            if ($rootScope.notice) {
                                $rootScope.notice.close();
                            };
                            $rootScope.notice = $rootScope.notification({
                                text: "Изменения успешно сохранены"
                            });
                            $rootScope.notice.result.then(function(){}, function(){});
                        }, 500);
                    })
                } else {
                    $state.reload();
                    $timeout(function() {
                        $scope.statusSending = false;
                        $scope.publishSending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                }

            })
        }
    });

grandclassApp.controller("NewsListController",
    function ($scope, $rootScope, $state,
              News,
    		  news) {

      $scope.resetHeader();
      $rootScope.pageTitle = 'Новости';

      $scope.resetMeta();
      $rootScope.metaObj.title = $scope.organization.title + ' — Новости';

      if ($rootScope.isAdmin) {
        $rootScope.addBtnText = 'Добавить новость';
        $rootScope.addBtnClick = function() {
          News.save({title: 'Заголовок', content: ''}, function (data) {
            newId = data.id;
          }).$promise.then(function () {
            $state.go('base.app.news.edit', {id: newId});
          })
        };
      };

      $scope.news = news;

      $scope.resetDatepicker = true;
      $scope.isAllSelected = true;
      $scope.pagination = {
        currentPage: 1,
        perPage: 12
      }
      $scope.filter = {
        search: '',
        dateFrom: null,
        dateTo: null,
        datepicker: {
          date_start: null,
          date_end: null
        }
      };

      $scope.update = function(type, datepicker) {
        if (type === 'reset') {
          $scope.pagination.currentPage = 1;
        };
        if (datepicker === 'reset_datepicker') {
          $scope.resetDatepicker = false;
          setTimeout(() => {
            $scope.resetDatepicker = true;
          }, 10)
        }
        $scope.loading = true;
        News.get({
          limit: $scope.pagination.perPage,
          page: $scope.pagination.currentPage,
          published_from: $scope.filter.dateFrom,
          published_to: $scope.filter.dateTo,
          statuses: $scope.statuses.active,
          search: $scope.filter.search ? $scope.filter.search : null }).$promise.then(data => {
            $scope.news = data;
            $scope.loading = false;
        })
      };

      // DATES
      // type = 'all' || 'thisWeek' || 'nextWeek' || 'past' || 'dates'
      $scope.filterByDate = function(type) {
        let today = moment();
        $scope.filter.dateFrom = null;
        $scope.filter.dateTo = null;
        switch (type) {
          case 'all':
            $scope.filter.datepicker = null;
            $scope.update('reset', 'reset_datepicker');
            break;
          case 'thisWeek':
            $scope.filter.datepicker = null;
            $scope.filter.dateFrom = today.startOf('isoWeek').format('YYYY-MM-DD');
            $scope.filter.dateTo = today.endOf('isoWeek').format('YYYY-MM-DD');
            $scope.update('reset', 'reset_datepicker');
            break;
          case 'lastWeek':
            $scope.filter.datepicker = null;
            $scope.filter.dateFrom = today.subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
            $scope.filter.dateTo = today.subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
            $scope.update('reset', 'reset_datepicker');
            break;
          case 'datepicker':
            if ($scope.filter.datepicker.date_start && $scope.filter.datepicker.date_end) {
              $scope.filter.dateFrom = moment($scope.filter.datepicker.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD');
              $scope.filter.dateTo = moment($scope.filter.datepicker.date_end, 'DD.MM.YYYY').format('YYYY-MM-DD');
              $scope.update('reset');
            }
            break;
        }
      }

      // STATUSES
      $scope.statuses = [
        {id: 0, title: 'Черновик', value: 'Черновик'},
        {id: 1, title: 'На модерации', value: "На модерации"},
        {id: 2, title: 'Опубликованы', value: "Опубликована"}
      ];
      $scope.toggleAll = function() {
        $scope.statuses.forEach(item => item.selected = false);
        $scope.buildStatusArray();
      };
      $scope.toggleStatus = function() {
        $scope.isAllSelected = false;
        $scope.buildStatusArray();
      };
      $scope.buildStatusArray = function() {
        $scope.statuses.active = [];
        angular.forEach($scope.statuses, function(status) {
          if (status.selected) {
            $scope.statuses.active.push(status.value);
          };
        });
        $scope.isAllSelected = $scope.statuses.every(item => !item.selected)
        $scope.update('reset')
      };

      // Удаление новости
      $scope.deleteNews = function ($event, news) {
          $rootScope.alert({
              title: "Вы действительно хотите удалить новость \"" + news.title + "\"?",
              ok: 'Удалить',
              cancel: 'отмена'
          }).result.then(function(){
              News.delete({id: news.id}).$promise.then(function() {
                  $state.reload();
              });
          }, function(){});
      };
    });

grandclassApp.controller("NewsViewController",
    function ($scope, $rootScope, $state, $location,
              News,
    		  news) {

        $scope.news = news;

        $scope.resetHeader();

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.news.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.news.image;

        if ($scope.news.is_blog) {
            $rootScope.pageTitle = 'Блог';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.blog');
            };
        } else {
            $rootScope.pageTitle = 'Новости';
            $rootScope.pageTitleClick = function() {
                $state.go('base.app.news.list');
            };
        };
        if ((($scope.news.status === 'На модерации' || $scope.news.status === 'Черновик') && $rootScope.isAdmin) ||
            ($scope.news.status === 'Черновик' && $rootScope.isTeacher && $scope.news.is_blog)) {
                $rootScope.btnText = 'Редактировать';
                $rootScope.btnClick = function() {
                     $state.go('base.app.news.edit', {id: $scope.news.id});
                };
        };

        if ($scope.news.video && $scope.news.video.type === 'link') {
            var script = document.createElement('script');
            script.src = 'https://www.youtube.com/iframe_api';
            document.getElementsByTagName('head')[0].appendChild(script);
        };
    });

grandclassApp.controller("NotificationController",
    function ($scope, $modalInstance, $timeout,
              instance) {

    	$scope.instance = instance;

    	$scope.save = function() {
    		$modalInstance.close($scope.instance);
    	};

    	$scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $timeout(function() {
            $scope.cancel();
        }, 5000);

    });

grandclassApp.controller("NotificationListController",
    function ($scope, $rootScope,
              notifications) {

      $scope.notifications = notifications;

      $scope.resetHeader();
      $rootScope.pageTitle = 'Уведомления';

      $scope.resetMeta();
      $rootScope.metaObj.title = $scope.organization.title + ' — Уведомления';

});

grandclassApp.controller("PagesController",
    function ($scope, $rootScope,
    		  tags) {
    	$scope.tags = tags;
    	$scope.resetHeader();
    });

grandclassApp.controller("PagesEditController",
    function ($scope, $rootScope, $state, $timeout,
              StaticPage,
    		  page) {

        $scope.page = page;

        if ($scope.page.tag) {
            $scope.page.hasTag = true;
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.page.seo_title;
        $rootScope.metaObj.description = $scope.page.description;
        $rootScope.metaObj.keywords = $scope.page.keywords;

        $scope.resetHeader();
        $rootScope.pageTitle = 'Страницы';
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.index');
        };

        if ($rootScope.isAdmin) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function () {
                $state.go('base.app.pages.view', {id: $scope.page.id});
            };
        };

        $scope.save = function () {

            $scope.sending = true;

            var data = {
                id: $scope.page.id,
                title: $scope.page.title,
                content: $scope.page.content,
                seo_title: $scope.page.seo_title,
                description: $scope.page.description,
                keywords: $scope.page.keywords,
                menu: $scope.page.menu
            };

            if ($scope.page.hasTag) {
                data.tag = $scope.page.tag;
            } else {
                data.tag = null;
            };

            StaticPage.patch(data).$promise.then(function () {
                $state.go('base.app.pages.list');
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Изменения успешно сохранены"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            });
        };
    });

grandclassApp.controller("PagesListController",
    function ($scope, $stateParams, $state, $rootScope,
    		  pages,
    		  StaticPage) {

    	$scope.pages = pages;

    	$scope.resetHeader();
    	$rootScope.pageTitle = 'Страницы';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Страницы';

        if ($rootScope.isAdmin) {
            $rootScope.addBtnText = 'Добавить страницу';
            $rootScope.addBtnClick = function() {
            	StaticPage.save({title: 'Заголовок', content: 'Контент'}, function (data) {
                    newId = data.id;
                }).$promise.then(function () {
                    $state.go('base.app.pages.view', {id: newId});
                });
            };
        };
    });

grandclassApp.controller("PagesViewController",
    function ($scope, $rootScope, $state,
              StaticPage,
    		  page, teachers) {

        $scope.page = page;
        $scope.teachers = teachers;

        $scope.resetHeader();
        $rootScope.pageTitle = 'Страницы';
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.index');
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.page.seo_title;
        $rootScope.metaObj.description = $scope.page.description;
        $rootScope.metaObj.keywords = $scope.page.keywords;

        if ($rootScope.isAdmin) {
            $rootScope.btnText = 'Редактировать';
          	$rootScope.btnClick = function () {
                $state.go('base.app.pages.edit', {id: $scope.page.id});
            };
        };

        $scope.getTeachers = function() {
            $scope.page.teachers = [];
            angular.forEach($scope.teachers, function(teacher) {
                if (teacher.tags.indexOf($scope.page.tag) !== -1) {
                    $scope.page.teachers.push(teacher);
                };
            })
        };
    });

grandclassApp.controller("PartnersController",
    function ($scope, $rootScope,
              partners) {
    	$scope.resetHeader();
    	$rootScope.pageTitle = 'Партнеры';
        $scope.partners = partners;

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.partners_title;
        $rootScope.metaObj.description = $scope.organization.partners_description;
        $rootScope.metaObj.keywords = $scope.organization.partners_keywords;
	});

grandclassApp.controller("PartnersEditController",
    function ($scope, $rootScope, $modal, $state, $timeout,
              Partner, Upload, Organization) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Партнеры';

        if ($rootScope.isAdmin) {
            $rootScope.addBtnText = 'Добавить партнера';
            $rootScope.addBtnClick = function() {
                $scope.addPartner();
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.partners_title;
        $rootScope.metaObj.description = $scope.organization.partners_description;
        $rootScope.metaObj.keywords = $scope.organization.partners_keywords;

        $scope.save = function() {
            $scope.sending = true;
            Organization.patch({
                id: $scope.organization.id,
                partners_info: $scope.organization.partners_info,
                partners_title: $scope.organization.partners_title,
                partners_description: $scope.organization.partners_description,
                partners_keywords: $scope.organization.partners_keywords
            }).$promise.then(function() {
                $state.reload();
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Изменения успешно сохранены"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            })
        }

        $scope.update = function() {
            Partner.query().$promise.then(function(data) {
                $scope.partners = data;
            })
        };

        $scope.deletePartner = function(partnerId) {
            $rootScope.alert({
                title: "Вы действительно хотите информацию о партнере?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Partner.delete({id: partnerId}).$promise.then($scope.update);
            }, function(){});
        };

        $scope.addPartner = function() {
            var modalInstance = $scope.openModalAddPartner({
                cover: '/static/images/default_cover.jpg'
            });
            modalInstance.result.then(function (instance) {
                Partner.save({
                    title: instance.title,
                    address: instance.address,
                    link: instance.link,
                    phone: instance.phone
                }).$promise.then(function(data) {
                    Upload.upload({
                        url: '/api/partners/' + data.id,
                        data: {cover: instance.coverFile},
                        method: 'PATCH'
                    }).then($scope.update);
                });
            }, function(){});
        };

        $scope.editPartner = function(partner) {
            var modalInstance = $scope.openModalEditPartner(partner);
            modalInstance.result.then(function (instance) {
                Partner.patch({
                    id: instance.id,
                    title: instance.title,
                    address: instance.address,
                    link: instance.link,
                    phone: instance.phone
                }).$promise.then(function() {
                    if (instance.coverFileEdited) {
                        Upload.upload({
                            url: '/api/partners/' + instance.id,
                            data: {cover: instance.coverFileEdited},
                            method: 'PATCH'
                        }).then($scope.update);
                    } else {
                        $scope.update();
                    }
                });
            }, function(){});
        };

        $scope.openModalAddPartner = function(partner) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-partner.html',
                controller: 'ModalAddPartnerController',
                resolve: {
                    instance: function() {
                        return partner;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.openModalEditPartner = function(partner) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-partner.html',
                controller: 'ModalEditPartnerController',
                resolve: {
                    instance: function() {
                        return partner;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

	});

grandclassApp.controller("PasswordResetController",
    function ($scope,
              User) {

        $scope.success = false;
        $scope.fail = false;

        $scope.send = function () {
            User.password_reset({'email': $scope.email}).$promise.then(function (data) {
                if (data.status == 200) {
                    $scope.fail = false;
                    $scope.success = true;
                } else {
                    $scope.fail = true;
                    $scope.success = false;
                }
            })
        }
    });

grandclassApp.controller("PaymentsController",
  function($scope, $rootScope, $timeout, $state,
           payments,
           Organization, Payment) {

    $scope.resetHeader();
    $rootScope.pageTitle = 'Платежи';

    $scope.resetMeta();
    $rootScope.metaObj.title = $scope.organization.title + ' — Платежи';

    $scope.resetDatepicker = true;
    $scope.isAllSelected = true;
    $scope.pagination = {
      currentPage: 1,
      perPage: 20
    }
    $scope.filter = {
      dateFrom: null,
      dateTo: null,
      date: {
        date_start: null,
        date_end: null
      }
    };

    $scope.update = function(type) {
      if (type === 'reset') {
        $scope.pagination.currentPage = 1;
      };
      $scope.loading = true;
      Payment.get({
        user: $rootScope.profile.id,
        page: $scope.pagination.currentPage,
        date_from: $scope.filter.dateFrom,
        date_to: $scope.filter.dateTo,
      }).$promise.then(data => {
          $scope.payments = data;
          $scope.loading = false;
      })
    }

    $scope.payments = payments;

    $scope.filterByDate = function(type) {
      $scope.filter.dateFrom = null;
      $scope.filter.dateTo = null;
      if ($scope.filter.date.date_start && $scope.filter.date.date_end) {
        $scope.filter.dateFrom = moment($scope.filter.date.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD');
        $scope.filter.dateTo = moment($scope.filter.date.date_end, 'DD.MM.YYYY').format('YYYY-MM-DD');
        $scope.update('reset');
      }
    }

    $scope.getTariffName = function() {
      angular.forEach($scope.tariffs, function(tariff) {
        if (tariff.id === $scope.organization.tariff) {
          $scope.tariffName = tariff.title;
        }
      });
    };

    $scope.categories = [{
        id: 0,
        title: 'Поступления',
        selected: true
      },
      {
        id: 1,
        title: 'Расходы',
        selected: true
      }
    ];

    $scope.pay = function(payment) {
      $scope.order = {
        id: payment.id,
        email: $scope.profile.email,
        sign: payment.sign,
        merchantId: payment.merchantId,
        price: payment.amount
      };

      $timeout(function() {
        document.getElementById('paymentForm').submit();
      });
    };

    $scope.save = function() {
      $scope.sending = true;
      Organization.patch({
        id: $scope.organization.id,
        payments_info: $scope.organization.payments_info
      }).$promise.then(function() {
        $state.reload();
        $timeout(function() {
          $scope.sending = false;
          if ($rootScope.notice) {
            $rootScope.notice.close();
          };
          $rootScope.notice = $rootScope.notification({
            text: "Изменения успешно сохранены"
          });
          $rootScope.notice.result.then(function() {}, function() {});
        }, 500);
      });
    };
  });

grandclassApp.controller("PrivacyController",
    function ($scope, $rootScope,
              organization) {

    	$scope.organization = organization[0];

        $scope.resetHeader();
        $rootScope.pageTitle = 'Политика конфидециальности';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Политика конфидециальности';
    });

grandclassApp.controller("ProfileEditController",
    function ($scope, $rootScope) {

    	$scope.resetHeader();
    	$rootScope.pageTitle = 'Профиль';

    	$scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Профиль';
    });

grandclassApp.controller("ProfileEditInfoController",
    function ($scope, $state, $rootScope, $location, $timeout, $q, $modal, $window,
              Upload, User, Diploma,
              profile, tags, diploms) {

    	$scope.profile = profile;
        $scope.diploms = diploms;
    	$scope.tags = tags;
        $scope.adding = false;
        $scope.cropper = {};
        $scope.validation = {email_format: false};
    	$scope.profile.avatarImage = $scope.profile.avatar;

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.profile.short_name;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() + $scope.profile.avatar;

        $scope.SPECIALITY_TYPES =  [
            'Психолог', 'Клинический психолог', 'Врач-психотерапевт', 'Врач-психиатр'
        ];

        $scope.GRADE_TYPES =  [
            'Нет', 'Кандидат психологических наук', 'Доктор медицинских наук'
        ];

        function check_email(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase())
        }

        $scope.updateDiploms = function() {
            Diploma.query().$promise.then(function(data) {
                $scope.diploms = data;
                $scope.buildDropzoneOptions();
            })
        };

    	// настройки для Dropzone
		$scope.dropzoneProfileOptions = {
			url: '/',
			autoProcessQueue: false,
			acceptedFiles : 'image/jpeg, images/jpg, image/png',
			addRemoveLinks : false,
			uploadMultiple: false,
			thumbnailWidth: 230,
			thumbnailHeight: null,
			dictDefaultMessage: '<span class="button button_outline">Сменить фото</span>',
			previewTemplate: '<img hidden data-dz-thumbnail />'
		};

        $scope.openModalImageCropper = function() {
            return $modal.open({
                templateUrl: '/static/views/app/cropper_modal.html',
                controller: 'ImageCropperCtrl',
                backdrop: 'static',
                windowClass: 'modal__content_big',
                resolve: {
                    instance: function () {
                        return $scope.cropper;
                    }
                }
            });
        };

        $scope.getFileFromImage = function(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        };

		$scope.resizeImage = function(filename, file) {
                $scope.cropper.sourceImage = file;
                $scope.cropper.ratio = $scope.organization.avatar_ratio;
                var modalInstance = $scope.openModalImageCropper();
                modalInstance.result.then(function (instance) {
                    $scope.profile.avatarFile = $scope.getFileFromImage(instance.croppedImage, filename);
                    $scope.profile.avatarImage = instance.croppedImage;
                });
        };

		$scope.dropzoneProfileCallbacks = {
			'thumbnail': function(file, dataUrl) {
			    $scope.resizeImage(file.name, dataUrl);
			},
		};

		$scope.save = function() {

		    if (check_email($scope.profile.email)) {
                $scope.validation.email_format = false;

                $scope.sending = true;
                var diplomsPromises = [];

                angular.forEach($scope.diploms, function (diplom) {
                    diplomsPromises.push(Diploma.patch({
                        id: diplom.id,
                        description: diplom.description,
                    }).$promise.then(function () {
                        if (diplom.photoFile) {
                            Upload.upload({
                                url: '/api/diploma/' + diplom.id,
                                data: {image: diplom.photoFile},
                                method: 'PATCH'
                            })
                        }
                    }));
                });

                $q.all(diplomsPromises).then(function () {
                    User.patch({
                        id: $scope.profile.id,
                        last_name: $scope.profile.last_name,
                        first_name: $scope.profile.first_name,
                        middle_name: $scope.profile.middle_name,
                        phone: $scope.profile.phone,
                        email: $scope.profile.email,
                        about: $scope.profile.about,
                        grade: $scope.profile.grade || "Нет",
                        speciality: $scope.profile.speciality || "Психолог",
                        city: $scope.profile.city,
                        tags: $scope.profile.tags,
                    }).$promise.then(function () {
                        if ($scope.profile.avatarFile) {
                            Upload.upload({
                                url: '/api/users/' + $scope.profile.id,
                                data: {avatar: $scope.profile.avatarFile},
                                method: 'PATCH'
                            })
                        }
                    }).then(function () {
                        $window.location.reload(); // refresh so that avatar/name in topbar become new
                        $timeout(function () {
                            $scope.sending = false;
                            if ($rootScope.notice) {
                                $rootScope.notice.close();
                            }
                            ;
                            $rootScope.notice = $rootScope.notification({
                                text: "Изменения успешно сохранены"
                            });
                            $rootScope.notice.result.then(function () {
                            }, function () {
                            });
                        }, 500);
                    });
                });
            } else {
                $scope.validation.email_format = true;
            }
        };

        $scope.toggleTag = function(tagId) {
            var index = $scope.profile.tags.indexOf(tagId);
            if (index != -1) {
                $scope.profile.tags.splice(index, 1);
            } else {
                $scope.profile.tags.push(tagId);
            };
        };

        $scope.hasTag = function(tagId) {
            if ($scope.profile.tags.indexOf(tagId) != -1) {
                return true;
            } else return false;
        };

        $scope.addDiplom = function() {
            $scope.adding = true;
            Diploma.save({
                description: 'Название диплома',
                user: $scope.profile.id,
                site: $scope.organization.site,
            }).$promise.then(function () {
                $scope.updateDiploms();
                $timeout(function() {
                    $scope.adding = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Диплом успешно добавлен"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            })
        };

        $scope.deleteDiplom = function(diplomId) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить диплом?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Diploma.delete({id: diplomId}).$promise.then(function() {
                    $scope.updateDiploms();
                    $timeout(function() {
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Диплом успешно удален"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                });
            }, function(){});
        };

        $scope.buildDropzoneOptions = function() {
            angular.forEach($scope.diploms, function(diplom) {
                diplom.dropzoneOptions = {
                    url: '/',
                    autoProcessQueue: false,
                    acceptedFiles : 'image/jpeg, images/jpg, image/png',
                    addRemoveLinks : false,
                    uploadMultiple: true,
                    thumbnailWidth: 285,
                    thumbnailHeight: null,
                    dictDefaultMessage: '<span class="button button_white">Сменить изображение</span>',
                    previewTemplate: '<img hidden data-dz-thumbnail />'
                };
                diplom.dropzoneCallbacks = {
                    'thumbnail': function(file, dataUrl) {
                       diplom.photoFile = file;
                       diplom.photoImage = dataUrl;
                    }
                };
            });
        };
    });

grandclassApp.controller("ProfileEditPasswordController",
    function ($scope, $rootScope, $location, $timeout, $state,
              User,
              profile) {

    	$scope.profile = profile;

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.profile.short_name;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() + $scope.profile.avatar;

    	$scope.passwordValidate = function() {
    		if ($scope.profile.new_password === $scope.profile.confirm_password &&
    			$scope.profile.new_password.length >= 6) {
    			return true;
    		} else {
    		    if($scope.profile.new_password !== $scope.profile.confirm_password)
    		        return 'Пароли не совпадают';
    		    else
                {
                    return "Пароль должен состоять минимум из 6 символов"
                }

    		}
    	};

    	$scope.save = function() {
    	    password_confirmation = $scope.passwordValidate();
    		if (password_confirmation===true) {
                $scope.sending = true;
    			User.password_change({id: $scope.profile.id, password: $scope.profile.new_password}).$promise.then(function() {
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Пароль успешно изменен"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                });
                $state.go('base.enter.login');
    		}
    		else{
                $rootScope.notice = $rootScope.errorNotification({
                    text: password_confirmation
                });
                $rootScope.notice.result.then(function(){}, function(){});

            }
    	};
    });

grandclassApp.controller("ProgressController",
    function ($scope, $rootScope) {

    	$scope.resetHeader();
    	$rootScope.pageTitle = 'Статистика';
    });

grandclassApp.controller("ProgressAllController",
    function ($scope, $http, $rootScope,
              students, courses, groups, data) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Успеваемость';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Успеваемость';

        $scope.data = data;

        $scope.filterTypes = [
            {type: 'course', title: 'курсу'},
            {type: 'student', title: 'студенту'},
            {type: 'group',title: 'группе'}
        ];

        $scope.filter = {
            type: 'course',
            user: null,
            group: null,
            course: null
        };

        $scope.students = students.results;
        $scope.courses = courses.results;
        $scope.groups = groups;

        $scope.courses.unshift({
            title: 'все курсы',
            id: null
        });

        $scope.resetFilterData = function() {
            $scope.filter.group = $scope.filter.course = $scope.filter.user = null;
        };

        $scope.generateProgress = function() {
            $scope.data = null;
            $http({
                url: "/api/progress/",
                method: "GET",
                params: {
                    user: $scope.filter.user,
                    group: $scope.filter.group,
                    course: $scope.filter.course
                }
            }).then(function(response) {
                $scope.data = response.data;
            });
        };

        // возвращает нужный класс для вывода оценки
        $scope.addScoreClassname = function(score) {
            score = parseInt(score);
            if (score == 5) {
                return 'score_high';
            } else if (score == 4) {
                return 'score_middle';
            } else {
                return 'score_low';
            }
        };

        // преобразует время в читаемый вид
        $scope.convertTime = function(string) {
            return moment(string, 'HH:mm:ss').format('HH:mm:ss');
        };

        if ($rootScope.isStudent) {
            $scope.filter.user = $scope.profile.id;
            $scope.generateProgress();
        };
    });

grandclassApp.controller("ProgressTimeController",
    function ($scope, $rootScope, $state,
              course, user,
              chapterActions) {

        $scope.course = course;
        $scope.user = user;
        $scope.chapterActions = chapterActions;

        $scope.resetHeader();
        $rootScope.pageTitle = 'Статистика';
        $rootScope.pageTitleClick = function() {
        	$state.go('base.app.progress.all');
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Статистика';
    });

grandclassApp.controller("RegisterController",
    function ($scope, $rootScope, $state, $timeout,
              User,
              tags, Questionnaire) {
        $scope.tags = tags;
        $scope.tagSelect = [];
        $scope.q_result = {};
        $scope.user = {
            role: 'Студент',
            grade: 'Нет',
            speciality: 'Психолог',
            examination: 'Не проводилось'
        };

        $scope.roles = ['Студент', 'Преподаватель'];
        $scope.genders = ['Мужской', 'Женский'];
        $scope.grades = ['Нет', 'Кандидат психологических наук', 'Доктор медицинских наук'];
        $scope.examinations = ['Не проводилось', 'Проводилось'];
        $scope.specialities = ['Психолог', 'Клинический психолог', 'Врач-психотерапевт', 'Врач-психиатр'];
        $scope.validation = {'email': false, 'passwords': false};
        Questionnaire.query().$promise.then(function (data) {
            $scope.questionnairy = data[0];
        });

        function check_questionnaire() {
            return true;  // TODO: it's a stub, currently questionnaire drops
            if($scope.user.role==='teacher')
                return true;
            var sum = 0;
            $scope.questionnairy.items.forEach(function(item){
                if(item.stop_value+'' === $scope.q_result['qi'+item.id]+'' )
                    sum += item.stop_weight;

            });
            alert(sum);
            alert($scope.questionnairy.stop_value);
            return sum < $scope.questionnairy.stop_value;
        }

        function check_custom_fields() {
            let custom_fields = [];
            let i = 0;
            $scope.organization.custom_fields.values.forEach(function(item){
                let value = $scope.q_result['cf'+item.id];
                if (value) {
                    custom_fields[i] = [item.name, value];
                    i += 1;
                }
                else if (item.field_type == 1) { // if checkbox is not checked, do this
                    custom_fields[i] = [item.name, 'False'];
                    i += 1;
                }
            });
            $scope.user.custom_fields = custom_fields;
        }

        function check_email(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase())
        }

        $scope.register = function () {
            if ($scope.user.password === $scope.user.password_confirm && $scope.user.password.length >= 6 && check_questionnaire() && check_email($scope.user.email)) {
                $scope.sending = true;
                $scope.validation.password = false;
                $scope.validation.password_min = false;
                $scope.validation.questionnaire = false;
                $scope.validation.email_format = false;
                $scope.validation.unexist = false;

                $scope.user.name = $scope.user.name || '';

                $scope.user.last_name = $scope.user.name.split(' ')[0];
                $scope.user.first_name = $scope.user.name.split(' ')[1];
                $scope.user.middle_name = $scope.user.name.split(' ')[2];

                if ($scope.user.role === 'Студент') {
                    $scope.user.role = 'student';
                } else {
                    $scope.user.role = 'teacher';
                }
                check_custom_fields();
                User.register($scope.user).$promise.then(function (data) {
                    if (data.status === 209) {
                        $scope.validation.email = true;
                    } else if (data.email == 'invalid') {
                        $scope.validation.unexist = true;
                    } else {
                        $state.go('base.enter.login');
                    }
                })
            }
            else if ($scope.user.password !== $scope.user.password_confirm) {
                $scope.validation.password = true;
                $scope.validation.password_min = false;
            }
            else if ($scope.user.password.length < 6) {
                $scope.validation.password = false;
                $scope.validation.password_min = true;

            }
            else if( !check_questionnaire() )
            {
                $scope.validation.questionnaire = true;
            }
            else if (!check_email($scope.user.email)) {
                $scope.validation.email_format = true;
            }
            $scope.sending = false;


        };

        $scope.toggleSelection = function toggleSelection(tagId) {
            var idx = $scope.tagSelect.indexOf(tagId);

            // is currently selected
            if (idx > -1) {
                $scope.tagSelect.splice(idx, 1);
            }

            // is newly selected
            else {
                $scope.tagSelect.push(tagId);
            }
        };
    });

grandclassApp.controller("RequestsController",
    function ($scope, $rootScope, $modal,
    		  AccessRequest, Payment,
    		  requests) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Заявки';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Заявки';

        $scope.pagination = {
          currentPage: 1,
          perPage: 20
        }
        $scope.loading = true;

        if ($rootScope.isAdmin) {
            $rootScope.addBtnText = 'Добавить заявку';
            $rootScope.addBtnClick = function() {
                $scope.addRequest();
            };
        };

        $scope.requests = requests;

        $scope.addRequest = function() {
        	var modalInstance = $scope.openModalAddRequest({});
            modalInstance.result.then(function (instance) {
            	var data = {
            		user: instance.user,
            		course: instance.course,
            		event: instance.event,
            		webinar: instance.webinar,
            		group: instance.group,
            	};
            	AccessRequest.save(data).$promise.then($scope.update);
            }, function(){});
        };

        $scope.update = function() {
          $scope.loading = true;
          AccessRequest.get({
            page: $scope.pagination.currentPage,
          }).$promise.then(data => {
              $scope.requests = data;
              $scope.loading = false;
              $scope.getDate();
          })
        }

        $scope.getDate = function() {
        	angular.forEach($scope.requests.results, function(request) {
	        	request.date = moment(request.created_at).format('DD.MM.YYYY');
	        });
        };

        $scope.accessArray = [
        	{value: false, title: 'Нет'},
        	{value: true, title: 'Да'}
        ];

        $scope.deleteRequest = function(request) {
        	$rootScope.alert({
                title: "Вы действительно хотите удалить выбранную заявку?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                AccessRequest.delete({id: request.id}).$promise.then($scope.update);
            }, function(){});

        };

        $scope.changeAccess = function (request) {
            AccessRequest.patch({id: request.id, access: request.access}).$promise.then($scope.update);
        };

        $scope.changePayment = function (request) {
            Payment.patch({id: request.payment.id, is_paid: request.payment.is_paid}).$promise.then($scope.update);
        };

        $scope.openModalAddRequest = function(request) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-request.html',
                controller: 'ModalAddRequestController',
                resolve: {
                  instance: function() {
                    return request;
                  },
                  courses: function(Course) {
                    return Course.get({limit: 100000}).$promise;
	                },
	                events: function(Event) {
                    return Event.get({ limit: 1000000 }).$promise;
	                },
	                webinars: function(Webinar) {
                    return Webinar.get({limit: 100000}).$promise;
	                },
	                students: function(User) {
	                	return User.get({role: 'student', limit: 1000000}).$promise;
	                },
	                groups: function(Group) {
                    return Group.query().$promise;
	                }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };
    });

grandclassApp.controller("RequestsGroupsController",
    function ($scope, $rootScope) {
    	$scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Групповые заявки';
    });

grandclassApp.controller("RequestsUsersController",
    function ($scope, $rootScope) {
    	$scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Одиночные заявки';
    });

grandclassApp.controller("RequisitesController",
    function ($scope, $rootScope) {
        $scope.resetHeader();
        $rootScope.pageTitle = 'Реквизиты';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Реквизиты';
    });

grandclassApp.controller("ShopController",
    function($scope, $rootScope, $state,
        Course, Organization,
        courses, organization) {

        $rootScope.pageTitle = 'Магазин';
        $rootScope.addBtnText = '';
        $scope.courses = courses.results;
        $scope.organization = organization[0];

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Магазин';

        $scope.check = function() {
            angular.forEach($scope.courses, function(course) {
                if ($scope.organization.courses_shop.indexOf(course.id) != -1) {
                    course.added = true;
                } else {
                    course.added = false;
                }
            })
        };

        $scope.toggleCourse = function(courseId) {
            idx = $scope.organization.courses_shop.indexOf(courseId);
            if (idx != -1) {
                $scope.organization.courses_shop.splice(idx, 1);
            } else {
                $scope.organization.courses_shop.push(courseId);
            }

            Organization.patch({
                id: $scope.organization.id,
                courses_shop: $scope.organization.courses_shop
            }).$promise.then($scope.check);
        }
    });

grandclassApp.controller("StatisticController",
    function ($scope, $rootScope,
              User,
              users) {

        $scope.resetHeader();
    	$rootScope.pageTitle = 'Статистика';

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Статистика';

        $scope.users = users;

        $scope.filteredUsers = [];

        $scope.statisticType = {};
        $scope.type = {};
        $scope.type.value = 'регистрациям';
        $scope.types = ['регистрациям', 'продажам'];

        $scope.labels = [];
        $scope.series = ['Студенты', 'Преподаватели'];


        $scope.filter = {
            date: {
                date_start: moment().subtract(30, 'days').format('DD.MM.YYYY'),
                date_end: moment().format('DD.MM.YYYY')
            }
        };

        $scope.build = function () {
            if ($scope.type.value == 'регистрациям') {
                $scope.data = [[], []];
                $scope.labels = [];
                angular.forEach($scope.users, function(user) {
                    var date = moment(user.registered_at);
                    filterDateStart = moment($scope.filter.date.date_start, 'DD.MM.YYYY');
                    filterDateEnd = moment($scope.filter.date.date_end, 'DD.MM.YYYY');
                    if (filterDateEnd.isValid() && date >= filterDateStart && date <= filterDateEnd) {
                        var label = moment(date).format('DD.MM');
                        var index = $scope.labels.indexOf(label);
                        if (index === -1) {
                            $scope.labels.push(label);
                            $scope.data[0].push(0);
                            $scope.data[1].push(0);
                        };
                        index = $scope.labels.indexOf(label);
                        if (index !== -1) {
                            if (user.role.value === 'student') {
                                $scope.data[0][index] += 1;
                            } else {
                                $scope.data[1][index] += 1;
                            }
                        }
                    }
                });
                if ($scope.data[0].length === 1 && $scope.data[1].length === 1) {
                    $scope.data[0].unshift(0);
                    $scope.data[0].push(0);
                    $scope.data[1].unshift(0);
                    $scope.data[1].push(0);
                    $scope.labels.push('');
                    $scope.labels.unshift('');
                };
            } else {
                User.sales({start: $scope.filter.date.date_start, end: $scope.filter.date.date_end}).$promise.then(function (data) {
                    $scope.teachers = data;
                })
            }
        };
    });

grandclassApp.controller("TestsAdminController",
    function ($scope, $http, $rootScope, $state,
			  students, test, attempts, course) {

        $scope.resetHeader();
        $rootScope.pageTitle = 'Результаты теста';
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.view.about', {id: course.id});
        };
        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — Результаты теста';
		var users = [];
		$scope.test = test;
		$scope.students = students;
		$scope.attempts = attempts;
		var all_attempts = attempts;
		angular.forEach(attempts, function(attempt) {
			angular.forEach($scope.students, function(student) {
				if (student.id == attempt.user) {
					users.push(student);
				}
			});
		});
		$scope.students = users.filter( function( item, index, inputArray ) {
       		return inputArray.indexOf(item) == index;
		});

		$scope.students.selectedStudent = $scope.students[0];

    	$scope.$watch('students.selectedStudent', function(value) {
    		let attempts = [];
    		angular.forEach(all_attempts, function(attempt) {
				if (attempt.user == value.id) {
					attempts.push(attempt);
				}
    		});
    		$scope.attempts = attempts;
	    	$scope.attempts.selectedAttempt = $scope.attempts[0];
    	});

        $scope.$watch('attempts.selectedAttempt', function(value) {
    		angular.forEach($scope.test.questions, function(question) {
				angular.forEach(value.answers, function(answer) {
					if (answer.question === question.id) {
						question.userAnswer = answer;
					};
				})
    		});
    	});
    });


grandclassApp.controller("TestsEditController",
    function ($scope, $rootScope, $state, $location,
              test, modules, tests, course) {

    	$scope.test = test;
    	$scope.tests = tests;
        $scope.modules = modules;
        $scope.course = course;
        $scope.isEditPage = true;

        $scope.resetHeader();
    	$rootScope.pageTitle = $scope.course.title;
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.edit.properties', {id: $scope.course.id});
        };

        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function () {
                $state.go('base.app.tests.view', {id: $scope.test.id});
            };
        };
        
        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.test.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;
    });

grandclassApp.controller("TestsEditPropertiesController",
    function ($scope, $state, $rootScope, $location, $timeout,
              Test,
              test, chapters, homeworks, module) {

        $scope.test = test;
        $scope.chapters = chapters;
        $scope.homeworks = homeworks;
        $scope.resetHeader();
        $rootScope.pageTitle = $scope.course.title;
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.view.about', {id: $scope.course.id, slug: $rootScope.slug($scope.course.title)});
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.test.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        $scope.my = {
            access: false,
            condition: 'chapter'
        };

        $scope.show_demo = true;

        var elements = module.elements;
        var current_index = null;
        angular.forEach(elements, function (element) {
           if ($scope.test.id == element.id) {
               current_index = elements.indexOf(element);
           }
        });
        if (current_index > 0) {
            if (elements[current_index - 1].is_demo == false) {
                $scope.show_demo = false;
            }
        }

        $scope.results = [
            {score: 3, title: 'удовлетворительно'},
            {score: 4, title: 'хорошо'},
            {score: 5,title: 'отлично'}
        ];

        if ($scope.test.chapter_for_access) {
            $scope.my.access = true;
            $scope.my.condition = 'chapter';
        };

        if ($scope.test.test_for_access) {
            $scope.my.access = true;
            $scope.my.condition = 'test';
        };

        if ($scope.test.homework_for_access) {
            $scope.my.access = true;
            $scope.my.condition = 'homework';
        };

        // находим все тесты курса, кроме текущего
        $scope.anotherTests = $scope.tests.map(function(test) {
            if ((test.id != $scope.test.id) && (test.course === $scope.test.course)) {
                return test;
            }
        });

    	if ($scope.test.passage_time) {
    		var time = moment($scope.test.passage_time, 'hh:mm:ss')
    		$scope.test.time = {
    			hours: + time.hours(),
    			minutes: + time.minutes(),
    			seconds: + time.seconds()
    		};
    	}

    	$scope.save = function() {

            $scope.sending = true;

            var data = {
                id: $scope.test.id,
                title: $scope.test.title,
                max_attempts: $scope.test.max_attempts,
                score3: $scope.test.score3,
                score4: $scope.test.score4,
                score5: $scope.test.score5,
                is_sequence: $scope.test.is_sequence,
                is_demo: $scope.test.is_demo,
                is_time_limited: $scope.test.is_time_limited
            };

            data.chapter_for_access = data.homework_for_access = data.homework_for_access_mark =
            data.test_for_access = data.test_for_access_mark = null;

            if ($scope.my.access) {
                switch ($scope.my.condition) {
                    case 'chapter':
                        data.chapter_for_access = $scope.test.chapter_for_access;
                        break;
                    case 'test':
                        data.test_for_access = $scope.test.test_for_access;
                        data.test_for_access_mark = $scope.test.test_for_access_mark;
                        break;
                    case 'homework':
                        data.homework_for_access = $scope.test.homework_for_access;
                        data.homework_for_access_mark = $scope.test.homework_for_access_mark;
                        break;
                };
            };

    		if ($scope.test.is_time_limited) {
                data.passage_time = $scope.test.time.hours + ':' + $scope.test.time.minutes + ':' + $scope.test.time.seconds;
    		} else {
    			data.passage_time = '01:00:00';
    		};

    		Test.patch(data).$promise.then(function(){
                $state.reload();
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Изменения успешно сохранены"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            });
    	};
    });

grandclassApp.controller("TestsEditQuestionsController",
    function ($scope, $modal, $state, $rootScope, $q, $location,
              Question, Choice, Upload) {

        $scope.groupQuestions = {};
        $scope.questions = $scope.test.questions;

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.test.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        $scope.anotherTests = $scope.tests.map(function(test) {
            if (test.id != $scope.test.id) {
                return test;
            };
        });

        $scope.selectAnotherTest = function() {
            var addingTest = $scope.test.adding;
            var questionPromises = [];
            angular.forEach(addingTest.questions, function(question) {
                questionPromises.push(
                   Question.duplicate({id: question.id, test: $scope.test.id})
                );
            });
            // обработка всех вопросов и перезагрузка
            $q.all(questionPromises).then(function() {$state.reload()});
        };

        angular.forEach($scope.questions, function(question) {
            if (!$scope.groupQuestions[question.origin.id]) {
                $scope.groupQuestions[question.origin.id] = {
                    origin: {
                        id: question.origin.id,
                        title: question.origin.title
                    },
                    questions: []
                };
            };
            $scope.groupQuestions[question.origin.id].questions.push(question);
        });

        $scope.deleteQuestion = function(question) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить вопрос?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Question.delete({id: question.id}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.deleteGroup = function(group) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить выбранные вопросы?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                var deletePromises = [];
                angular.forEach(group.questions, function(question) {
                    deletePromises.push(Question.delete({id: question.id}));
                });
                $q.all(deletePromises).then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.addQuestion = function() {
            var modalInstance = $scope.openModalAddQuestion({
                description: '',
                answer: '',
                score: ''
            });
            modalInstance.result.then(function (instance) {
                instance.answer = (instance.type === 1) ? instance.answer : '';
                instance.score = (instance.score) ? instance.score : 1;

                Question.save({
                    description: instance.description,
                    answer: instance.answer,
                    type: instance.type,
                    score: instance.score,
                    origin: $scope.test.id,
                    test: $scope.test.id,
                    comment: instance.comment
                }).$promise.then(function(data) {
                    if (instance.fileImage) {
                        Upload.upload({
                            url: '/api/test_questions/' + data.id,
                            data: {image: instance.fileImage},
                            method: 'Patch'
                        })
                    };
                    if (instance.type === 2) {
                        var answerPromises = [];
                        angular.forEach(instance.answers, function(answer) {
                            if (!answer.is_delete) {
                                answerPromises.push(Choice.save({
                                    question: data.id,
                                    answer: answer.answer,
                                    is_right: answer.is_right
                                }).$promise.then(function(data) {
                                    if (answer.fileImage) {
                                        Upload.upload({
                                            url: '/api/test_choices/' + data.id,
                                            data: {image: answer.fileImage},
                                            method: 'PATCH'
                                        });
                                    }
                                }));
                            }
                        });
                        $q.all(answerPromises).then(function() {
                            $state.reload();
                        });
                    } else if (instance.type === 3) {
                        var answerPromises = [];
                        angular.forEach(instance.defaultAnswers, function(answer) {
                            answerPromises.push(Choice.save({
                                question: data.id,
                                answer: answer.answer,
                                is_right: answer.is_right
                            }));
                        });
                         $q.all(answerPromises).then(function() {
                            $state.reload();
                        });

                    } else {
                        $state.reload();
                    }
                });
            }, function(){});
        };

        $scope.editQuestion = function(question) {
            var modalInstance = $scope.openModalEditQuestion(question);
            modalInstance.result.then(function (instance) {

                instance.description = instance.changedDescription;
                instance.type = instance.changedType;
                instance.answer = instance.changedAnswer;
                instance.score = instance.changedScore;

                instance.answer = (instance.type === 1) ? instance.answer : '';
                instance.score = (instance.score) ? instance.score : 1;

                Question.patch({
                    id: instance.id,
                    description: instance.description,
                    answer: instance.answer,
                    type: instance.type,
                    score: instance.score,
                    comment: instance.comment
                }).$promise.then(function(data) {
                    if (instance.fileImage) {
                        Upload.upload({
                            url: '/api/test_questions/' + data.id,
                            data: {image: instance.fileImage},
                            method: 'Patch'
                        })
                    };
                    if (instance.type === 2) {
                        var answerPromises = [];
                        angular.forEach(instance.answers, function(answer) {
                            if (answer.is_new && !answer.is_delete) {
                                answerPromises.push(Choice.save({
                                    question: data.id,
                                    answer: answer.answer,
                                    is_right: answer.is_right
                                }).$promise.then(function(data) {
                                    if (answer.fileImage) {
                                        Upload.upload({
                                            url: '/api/test_choices/' + data.id,
                                            data: {image: answer.fileImage},
                                            method: 'PATCH'
                                        });
                                    }
                                }));
                            } else if (answer.is_delete && !answer.is_new) {
                                answerPromises.push(Choice.delete({id: answer.id}));
                            } else if (!answer.is_delete && !answer.is_new) {
                                answerPromises.push(Choice.patch({
                                    id: answer.id,
                                    answer: answer.answer,
                                    is_right: answer.is_right
                                }).$promise.then(function(data) {
                                    if (answer.fileImage) {
                                        Upload.upload({
                                            url: '/api/test_choices/' + data.id,
                                            data: {image: answer.fileImage},
                                            method: 'PATCH'
                                        });
                                    }
                                }));
                            }
                        });
                        $q.all(answerPromises).then(function() {
                            $state.reload();
                        });

                    } else if (instance.type === 3) {
                        var answerPromises = [];
                        angular.forEach(instance.defaultAnswers, function(answer) {
                            if (answer.is_new) {
                                answerPromises.push(Choice.save({
                                    question: data.id,
                                    answer: answer.answer,
                                    is_right: answer.is_right
                                }));
                            } else {
                                answerPromises.push(Choice.patch({
                                    id: answer.id,
                                    answer: answer.answer,
                                    is_right: answer.is_right
                                }));
                            }
                        });
                         $q.all(answerPromises).then(function() {
                            $state.reload();
                        });

                    } else {
                        $state.reload();
                    }
                });
            }, function(){});
        };

        $scope.openModalAddQuestion = function(question) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-question.html',
                controller: 'ModalAddQuestionController',
                resolve: {
                    instance: function() {
                        return question;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.openModalEditQuestion = function(question) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-question.html',
                controller: 'ModalEditQuestionController',
                resolve: {
                    instance: function() {
                        return question;
                    },
                    answers: function(Choice) {
                        return Choice.query({question: question.id}).$promise;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.dragularOptions = {};

        angular.forEach($scope.groupQuestions, function(group) {
            $scope.dragularOptions[group.origin.id] = {
                scope: $scope,
                containersModel: group.questions,
                nameSpace: group.origin.id,
                revertOnSpill: true,
                moves: function (el, container, handle) {
                    return !$rootScope.isTouchDevice;
                }
            }
        });

        $scope.$on('dragulardrop', function(e, el) {
            angular.forEach($scope.groupQuestions, function(group) {
                var index = 0;
                angular.forEach(group.questions, function(question) {
                    question.changedOrder = index;
                    index++;
                    var data = {
                        id: question.id
                    };
                    question.changed = false;
                    // проверка на изменение порядка элемента
                    if (question.changedOrder != question.order) {
                        data.order = question.order = question.changedOrder;
                        question.changed = true;
                    };
                    if (question.changed) {
                        Question.patch(data);
                    };
                });
            });
        })


    });

grandclassApp.controller("TestsResultController",
    function ($scope, $rootScope, $state, $location,
    		  test, modules, attempts, course) {

    	$scope.test = test;
    	$scope.modules = modules;
    	$scope.attempts = attempts;
        $scope.course = course;
		$scope.isEmpty = false;

        $scope.resetHeader();
        $rootScope.pageTitle = $scope.organization.title + ' — ' + $scope.test.title;
		$rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.view.about', {id: $scope.course.id});
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.test.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

    	angular.forEach($scope.attempts, function(attempt, index) {
    		if (index === 0) {
    			attempt.active = true;
    		} else {
    			attempt.active = false;
    		}
    	});

		$scope.attempts.selectedAttempt = $scope.attempts[0];

    	$scope.$watch('attempts.selectedAttempt', function(value) {
    		angular.forEach($scope.test.questions, function(question) {
				angular.forEach(value.answers, function(answer) {
					$scope.isEmpty = false;
					if (answer.question === question.id) {
						question.userAnswer = answer;
						if (answer.choices.length == 0) {
							$scope.isEmpty = true;
						}
					};
				})
    		});
    	});

    });


grandclassApp.controller("TestsViewController",
    function ($scope, $rootScope, $state, $q, $location,
              test, modules, course,
              Attempt, Answer) {

    	$scope.attempt = null;
    	$scope.test = test;
    	$scope.modules = modules;
        $scope.course = course;
        $scope.isEditPage = false;
		if ($scope.test.attempts_left <= 0) {
            $state.go('base.app.courses.view.about', {id: $scope.course.id});
		}
        $scope.resetHeader();
        $rootScope.pageTitle = $scope.organization.title + ' — ' + $scope.course.title;
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.courses.view.about', {id: $scope.course.id});
        };

        if ($scope.course.user_can_edit) {
            $rootScope.btnText = 'Редактировать';
            $rootScope.btnClick = function () {
                $state.go('base.app.tests.edit.properties', {id: $scope.test.id});
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.test.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.course.cover;

        if ($scope.profile.role.value === 'student' && $scope.test.attempts_left > 0) {
    		Attempt.save({
    			test: $scope.test.id,
    			user: $scope.profile.id,
    			is_started: true
    		}).$promise.then(function(data) {
    			$scope.attempt = data.id;
    		});
    	};
    	$scope.setTimer = function() {
    		$scope.timer = {
    			countdown: moment.duration($scope.test.passage_time).asSeconds(),
    			element: angular.element('#timer'),
    			circle: angular.element('#circle'),
    			finished: function() {
    				$scope.timer.element.addClass('timer_end');
    				$scope.saveAll();
    				$rootScope.notice = $rootScope.notification({
						text: "Время на выполнение теста истекло"
    				});
                    $rootScope.notice.result.then(function() {}, function() {});
    			},
    			progress: 0
    		};
    		$scope.timer.progressLength = 2 * Math.PI * $scope.timer.element.width()/2;
    		$scope.timer.circle.css({
    			'stroke-dasharray': $scope.timer.progressLength + 'px'
    		});
    	};

    	$scope.$on('timer-tick', function(event, data) {
    		var timeLeft = data.hours * 3600 + data.minutes * 60 + data.seconds;
    		$scope.timer.progress = (1 - (timeLeft / $scope.timer.countdown));
    		if (timeLeft <= 30) {
    			$scope.timer.element.addClass('timer_out');
    		};
    		$scope.timer.circle.css({
    			'stroke-dashoffset': ($scope.timer.progressLength * $scope.timer.progress) + 'px'
    		});
    	})

    	$scope.activeQuestion = 0;

    	$scope.getIndexes = function() {
    		angular.forEach($scope.test.questions, function(question, index) {
    			question.index = index;
    		});
    	};

    	$scope.makeActive = function(index) {
    		$scope.activeQuestion = index;
    		angular.forEach($scope.test.questions, function(question) {
    			if (question.index === index) {
    				question.active = true;
    			} else {
    				question.active = false;
    			};
    		});
    	};

    	$scope.next = function() {
    		if ($scope.activeQuestion + 1 < $scope.test.questions.length) {
    			$scope.makeActive($scope.activeQuestion + 1);
    		};
    	};

    	$scope.saveAll = function() {
    		var answerPromises = [];
    		angular.forEach($scope.test.questions, function(question) {
    			var data = {
    				attempt: $scope.attempt,
    				question: question.id
    			};
    			if (question.type === 1) {
    				data.answer = question.userAnswer;
    			} else {
    				data.choices = question.userAnswer;
    			};
    			answerPromises.push(Answer.save(data));
    		});
    		$q.all(answerPromises).then(function() {
    			Attempt.check({id: $scope.attempt}).$promise.then(function(data) {
					$state.go('base.app.tests.result', {id: $scope.test.id});
    			})
    		});
    	};

    	$scope.checkAnswer = function(question) {
    		question.userAnswer = [];
    		angular.forEach(question.choices, function(choice) {
    			if (choice.checked) {
    				question.userAnswer.push(choice.id);
    			};
    		});
    	};

    });
grandclassApp.controller("UsersListController",
    function ($scope, $rootScope, $modal, $state, $window, $timeout,
              User, Upload, Group, Organization,
              users, groups, tags) {

        $scope.resetMeta();
        $scope.resetHeader();
        $rootScope.pageTitle = 'Пользователи';
        $rootScope.metaObj.title = $scope.organization.title + ' — Пользователи';

        $scope.groups = groups;
        $scope.tags = tags;
        $scope.users = users;

        $scope.booleans = {
          isAllSelected: true,
          allUsersChecked: false
        };
        $scope.countOfChecks = 0;
        $scope.pagination = {
          currentPage: 1,
          perPage: 10
        }
        $scope.filter = {
          search: '',
          role: ['admin', 'student', 'teacher']
        };
        $scope.sort = {
          name: 'full_name',
          asc: true
        }

        $scope.changeSort = function(name) {
          $scope.sort.asc = (name === $scope.sort.name) ? !$scope.sort.asc : true;
          $scope.sort.name = name;
          $scope.update('reset')
        }

        $scope.update = function(type) {
          if (type === 'reset') {
            $scope.pagination.currentPage = 1;
          };
          $scope.loading = true;
          User.get({
            page: $scope.pagination.currentPage,
            limit: $scope.pagination.perPage,
            roles: $scope.filter.role,
            tags: $scope.tags.activeTags,
            sort_name: $scope.sort.name,
            sort_order: ($scope.sort.asc) ? 'asc' : 'desc',
            search: $scope.filter.search ? $scope.filter.search : null }).$promise.then(data => {
              $scope.users = data;
              $scope.loading = false;
          })
        };

        // ROLES
        $scope.roles = [
            {title: 'Студент', value: 'student'},
            {title: 'Преподаватель', value: 'teacher'},
            {title: 'Администратор', value: 'admin'}
        ];
        $scope.toggleAll = function() {
          $scope.tags.forEach(item => item.selected = false);
          $scope.buildTagsArray();
        };
        $scope.toggleTag = function() {
          $scope.booleans.isAllSelected = false;
          $scope.buildTagsArray();
        };
        $scope.buildTagsArray = function() {
          $scope.tags.activeTags = [];
          angular.forEach($scope.tags, function(tag) {
            if (tag.selected) { $scope.tags.activeTags.push(tag.id) };
          });
          $scope.booleans.isAllSelected = $scope.tags.every(item => !item.selected)
          $scope.update('reset')
        };

        // SELECT AND COUNT USERS
        $scope.checkCount = function() {
          $scope.countOfChecks = 0;
          angular.forEach($scope.users.results, function (user) {
            if (user.is_checked) {
                $scope.countOfChecks++;
            };
          });
        };
        $scope.isAllUsersChecked = function () {
          var result = true;
          $scope.selected_users = '';
          angular.forEach($scope.users.results, function(user) {
            if (!user.is_checked) {
              result = false;
            } else {
              $scope.selected_users += 'users=' + user.id + '&'
            }
          });
          $scope.booleans.allUsersChecked = result;
          return result;
        };
        $scope.checkAllUsers = function() {
          angular.forEach($scope.users.results, function (user) {
            user.is_checked = $scope.booleans.allUsersChecked;
          });
          $scope.checkCount();
        };

        $scope.deleteUser = function(user) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить пользователя \"" + user.full_name + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function() {
                User.delete({id: user.id}).$promise.then($scope.update('reset'));
            }, function(){});
        };

        $scope.deleteUsers = function() {
            $rootScope.alert({
                title: "Вы действительно хотите удалить выбранных пользователей?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function() {
                var checkedUsers = [];
                angular.forEach($scope.users.results, function(user) {
                    if (user.is_checked) {
                        checkedUsers.push(user.id);
                    };
                });
                User.batch_delete({ids: checkedUsers}).$promise.then($scope.update('reset'));
            }, function(){});
        };

        $scope.addUsers = function(activeTab) {
            var modalInstance = $scope.openModalAddUsers({
                avatar: '/static/images/default-profile.jpg'
            }, activeTab);
            modalInstance.result.then(function (instance) {

                // если один пользователь
                if (instance.type === 'user') {
                    var groupArr = [];
                    angular.forEach(instance.groups, function(group) {
                        groupArr.push(group.id);
                    });

                    User.register({
                        first_name: instance.name.split(' ')[1],
                        middle_name: instance.name.split(' ')[2],
                        last_name: instance.name.split(' ')[0],
                        role: instance.role.value,
                        email: instance.email,
                        phone: instance.phone,
                        city: instance.city,
                        speciality: instance.speciality,
                        grade: instance.grade,
                        groups: groupArr,
                        custom_fields: instance.custom_fields
                    }).$promise.then(function(data) {
                        if (instance.avatarFile) {
                            Upload.upload({
                                url: '/api/users/' + data.id,
                                data: {avatar: instance.avatarFile},
                                method: 'PATCH'
                            })
                        };
                      }).then(function () {
                          $rootScope.notification({text: 'Пользователю выслано приглашение'}).result.then(function (instance) { $scope.update('reset') }, function() {} );
                          $scope.update('reset');
                      });

                // если несколько пользователей
                } else if (instance.type === 'users') {
                    var groupArr = [];
                    angular.forEach(instance.users.groups, function(group) {
                        groupArr.push(group.id);
                    });
                    angular.forEach(instance.users.emails, function(email) {
                        var username = email.split('@')[0];
                        User.register({
                            first_name: username,
                            last_name: username,
                            email: email,
                            groups: groupArr,
                            role: 'student'
                        }).$promise.then(function () {
                            $rootScope.notification({text: 'Пользователям высланы приглашения'}).result.then(function (instance) { $scope.update('reset') }, function() {} );
                            $scope.update('reset');
                        });
                    });
                // если список пользователей импортируется
                } else if (instance.type === 'import') {
                    Upload.upload({
                        url: '/api/users_import/' + instance.fileImport.name,
                        file: instance.fileImport,
                        method: 'POST'
                    }).then(function (resp) {
                        if (resp.data.error) {
                            var message = resp.data.error
                        } else {
                            var message = 'Создано пользователей: ' + resp.data.counts.created
                        }
                        $rootScope.notification({text: message}).result.then(function (instance) {}, function() {} );
                        $scope.update('reset');
                    });
                }

            }, function(){});
        };

        $scope.editUser = function(user) {
            var modalInstance = $scope.openModalEditUser(user);
            modalInstance.result.then(function(instance) {
                var groupArr = [];
                angular.forEach(instance.groups, function(group) {
                    groupArr.push(group.id);
                });
                User.patch({
                    id: instance.id,
                    first_name: instance.name.split(' ')[1],
                    middle_name: instance.name.split(' ')[2],
                    last_name: instance.name.split(' ')[0],
                    role: instance.role.value,
                    email: instance.email,
                    phone: instance.phone,
                    city: instance.city,
                    speciality: instance.speciality,
                    grade: instance.grade,
                    groups: groupArr
                }).$promise.then(function(data) {
                    if (instance.avatarFile) {
                        Upload.upload({
                            url: '/api/users/' + data.id,
                            data: {avatar: instance.avatarFile},
                            method: 'PATCH'
                        }).then($scope.update('reset'));
                    } else {
                      $scope.update('reset')
                    }
                })
            }, function() {});
        };

        $scope.openModalAddUsers = function(user, activeTab) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-users.html',
                controller: 'ModalAddUsersController',
                resolve: {
                    instance: function() {
                        return user;
                    },
                    groups: function() {
                        return groups;
                    },
                    activeTab: function() {
                        return activeTab;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.openModalEditUser = function(user) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-edit-user.html',
                controller: 'ModalEditUserController',
                resolve: {
                    instance: function() {
                        return user;
                    },
                    groups: function() {
                        return groups;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_big'
            })
        };

        $scope.addToGroup = function() {
            var modalInstance = $scope.openModalAddToGroup({
                group: null
            });
            modalInstance.result.then(function(instance) {
                var currentUsers = [];
                angular.forEach($scope.groups, function(group) {
                    if (group.id === instance.group) {
                        angular.forEach(group.users, function(user) {
                            currentUsers.push(user.id);
                        })
                    };
                });
                angular.forEach($scope.users.results, function(user) {
                    if (user.is_checked) {
                        currentUsers.push(user.id);
                    };
                });
                Group.patch({
                    id: instance.group,
                    users: currentUsers
                }).$promise.then($scope.update('reset'));
            }, function() {});
        };

        $scope.batchReset = function() {
            User.batch_reset({ids: $scope.selected_users}).$promise.then(function (data) {
                console.log(data);
            })
        };

        $scope.openModalAddToGroup = function(instance) {
            return $modal.open({
                templateUrl: '/static/views/app/modal-add-to-group.html',
                controller: 'ModalAddToGroupController',
                resolve: {
                    instance: function() {
                        return instance;
                    },
                    groups: function() {
                        return groups;
                    }
                },
                closeOnClick: true,
                backdrop: true,
                windowClass: 'modal__content_small'
            })
        };


    });

grandclassApp.controller("UsersProfileController",
    function ($scope, $rootScope, $state, $location, $timeout,
              Note, User,
              user, tags, therapies, shopCourses, webinars, news, notes, isMyStudent, diploms) {

        $scope.user = user;
        $scope.tags = tags;
        $scope.therapies = therapies;
        $scope.shopCourses = shopCourses;
        $scope.webinars = webinars;
        $scope.news = news;
        $scope.notes = notes;
        $scope.activeContent = 1;
        $scope.isMyStudent = isMyStudent;
        $scope.diploms = diploms;

        $scope.resetHeader();
        $rootScope.pageTitle = 'Пользователи';
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.users.list');
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.user.short_name;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.user.avatar;

        $scope.noteComment = {
            type: '',
            title: '',
            text: ''
        };

        $scope.approveUser = function(){
            User.patch({id: $scope.user.id, is_approved: true}).$promise.then(function(data){
                $scope.user.is_approved = data.is_approved;
            });
        };

        $scope.update = function() {
            Note.query({user: $scope.user.id}).$promise.then(function(data) {
                $scope.notes = data;
            });
        };

        $scope.hasTag = function(tagId) {
            if ($scope.tagSelect.indexOf(tagId) != -1) {
                return true;
            } else return false;
        };

        angular.forEach($scope.notes, function(note) {
            note.dateObj = {
                date: moment(note.creates_at).format('DD.MM.YYYY'),
                time: moment(note.creates_at).format('HH:mm')
            };
        });

        $scope.noteTypes = [
            {title: 'Клинический диагноз'},
            {title: 'Жалобы'},
            {title: 'Текущее состояние'},
            {title: 'Заметки'}
        ];

        $scope.filterNotes = function(type) {
            angular.forEach($scope.notes, function(note) {
                if (note.type === type) {
                    note.filtered = true;
                } else {
                    note.filtered = false;
                };
            });
        };

        $scope.save = function() {
            $scope.sending = true;
            Note.save({
                author: $scope.profile.id,
                type: $scope.noteComment.type,
                title: $scope.noteComment.title,
                text: $scope.noteComment.text,
                user: $scope.user.id
            }).$promise.then(function() {
                $state.reload();
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Запись успешно опубликована"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            })
        };

        $scope.editNote = function(note) {
            note.sending = true;
            Note.patch({
                id: note.id,
                title: note.title,
                text: note.text
            }).$promise.then(function() {
                $timeout(function() {
                    note.sending = false;
                    note.noteIsEdit = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Запись успешно изменена"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);
            })
        };

        $scope.deleteNote = function(noteId) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить запись?",
                ok: 'Удалить',
                cancel: 'Отмена'
            }).result.then(function(){
                Note.delete({id: noteId}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.slickCertConfig = {
            variableWidth: true,
            infinite: false,
            swipeToSlide: true,
            prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>',
            nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>'
        }


    });

grandclassApp.controller("WebinarsController",
    function ($scope, $rootScope, $state, $timeout, $q,
              Webinar,
              webinars, webinarTags) {

        $scope.resetMeta();
        $scope.resetHeader();
        $rootScope.pageTitle = 'Вебинары';
        $rootScope.metaObj.title = $scope.organization.title + ' — Вебинары';
        if ($rootScope.isAdmin || $rootScope.isTeacher) {
            $rootScope.addBtnText = 'Добавить вебинар';
            $rootScope.addBtnClick = function() {
                $scope.addWebinar();
            };
        };

        $scope.webinars = webinars;
        $scope.webinarTags = webinarTags;

        $scope.resetDatepicker = true;
        $scope.tagsAreVisible = false;
        $scope.isAllSelected = true;
        $scope.isMySelected = false;
        $scope.pagination = {
          currentPage: 1,
          perPage: 12
        }
        $scope.filter = {
          isFree: false,
          search: '',
          dateFrom: null,
          dateTo: null,
          datepicker: {
            date_start: null,
            date_end: null
          }
        };

        $scope.update = function(type, datepicker) {
          if (type === 'reset') {
            $scope.pagination.currentPage = 1;
          };
          if (datepicker === 'reset_datepicker') {
            $scope.resetDatepicker = false;
            setTimeout(() => {
              $scope.resetDatepicker = true;
            }, 10)
          }
          $scope.loading = true;
          Webinar.get({
            limit: $scope.pagination.perPage,
            page: $scope.pagination.currentPage,
            date_from: $scope.filter.dateFrom,
            date_to: $scope.filter.dateTo,
            tags: $scope.webinarTags.activeTags,
            my: $scope.isMySelected,
            search: $scope.filter.search ? $scope.filter.search : null,
            is_free: $scope.filter.isFree ? $scope.filter.isFree : null }).$promise.then(data => {
              $scope.webinars = data;
              $scope.loading = false;
          })
        };

        // DATES
        // type = 'all' || 'thisWeek' || 'nextWeek' || 'past' || 'dates'
        $scope.filterByDate = function(type) {
          let today = moment();
          $scope.filter.dateFrom = null;
          $scope.filter.dateTo = null;
          switch (type) {
            case 'all':
              $scope.filter.datepicker = null;
              $scope.update('reset', 'reset_datepicker');
              break;
            case 'thisWeek':
              $scope.filter.datepicker = null;
              $scope.filter.dateFrom = today.startOf('isoWeek').format('YYYY-MM-DD');
              $scope.filter.dateTo = today.endOf('isoWeek').format('YYYY-MM-DD');
              $scope.update('reset', 'reset_datepicker');
              break;
            case 'nextWeek':
              $scope.filter.datepicker = null;
              $scope.filter.dateFrom = today.add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
              $scope.filter.dateTo = today.add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
              $scope.update('reset', 'reset_datepicker');
              break;
            case 'past':
              $scope.filter.datepicker = null;
              $scope.filter.dateTo = today.format('YYYY-MM-DD');
              $scope.update('reset', 'reset_datepicker');
              break;
            case 'datepicker':
              if ($scope.filter.datepicker.date_start && $scope.filter.datepicker.date_end) {
                $scope.filter.dateFrom = moment($scope.filter.datepicker.date_start, 'DD.MM.YYYY').format('YYYY-MM-DD');
                $scope.filter.dateTo = moment($scope.filter.datepicker.date_end, 'DD.MM.YYYY').format('YYYY-MM-DD');
                $scope.update('reset');
              }
              break;
          }
        }

        // TAGS
        $scope.toggleAll = function() {
          $scope.isMySelected = false;
          $scope.webinarTags.forEach(item => item.selected = false);
          $scope.buildTagsArray();
        };
        $scope.toggleTag = function() {
          $scope.isAllSelected = false;
          $scope.isMySelected = false;
          $scope.buildTagsArray();
        };
        $scope.toggleMy = function() {
          $scope.isAllSelected = false;
          $scope.webinarTags.forEach(item => item.selected = false);
          $scope.buildTagsArray();
        }
        $scope.buildTagsArray = function() {
          $scope.webinarTags.activeTags = [];
          angular.forEach($scope.webinarTags, function(tag) {
            if (tag.selected) {
              $scope.webinarTags.activeTags.push('' + tag.id);
            };
          });
          $scope.isAllSelected = $scope.webinarTags.every(item => !item.selected) && !$scope.isMySelected
          $scope.update('reset')
        };

        $scope.dragularOptions = {
            scope: $scope,
            containersModel: $scope.webinars.results,
            direction: 'horizontal',
            moves: function (el, container, handle) {
                return !$rootScope.isTouchDevice;
            }
        };

        // создает мероприятие и редиректит на страницу редактирования
        $scope.addWebinar = function() {
            Webinar.save({title: 'Новый вебинар', date: moment()}, function(data) {
                newId = data.id;
            }).$promise.then(function () {
                $state.go('base.app.webinars.edit', {id: newId});
            })
        };

        // удаление мероприятие
        $scope.deleteWebinar = function ($webinar, webinar) {
            $rootScope.alert({
                title: "Вы действительно хотите удалить вебинар \"" + webinar.title + "\"?",
                ok: 'Удалить',
                cancel: 'отмена'
            }).result.then(function(){
                Webinar.delete({id: webinar.id}).$promise.then(function() {
                    $state.reload();
                });
            }, function(){});
        };

        $scope.notice = null;
        $scope.changeSize = function(webinar, size) {
            webinar.sizeX = size;
            if (!$scope.sizeNoticed) {
                if ($scope.notice) {
                     $scope.notice.close();
                };
                $scope.notice = $rootScope.notification({
                    text: "Вы изменили размер карточки вебинара. Чтобы применить изменения, нажите кнопку «Сохранить»"
                });
                $scope.notice.result.then(function(){}, function(){});
                $scope.sizeNoticed = true;
            };
        };

        $scope.$on('dragulardrop', function(e, el) {
            if (!$scope.posNoticed) {
                if ($scope.notice) {
                     $scope.notice.close();
                };
                $scope.notice = $rootScope.notification({
                    text: "Вы изменили позицию карточки вебинара. Чтобы применить изменения, нажите кнопку «Сохранить»"
                });
                $scope.posNoticed = true;
                $scope.notice.result.then(function(){}, function(){})
            }
        });

        // сохранянт порядок и размер измененных курсов
        $scope.save = function() {
            $scope.sending = true;
            var cardsPromises = [];
            var index = 0;

            angular.forEach($scope.webinars.results, function(webinar) {
                webinar.changedOrder = index;
                var data = {
                    id: webinar.id
                };
                webinar.changed = false;

                // проверка на изменение размера карточки
                if (webinar.sizeX && (webinar.sizeX != webinar.size)) {
                    data.size = webinar.size = webinar.sizeX;
                    webinar.changed = true;
                };

                // проверка на изменение порядка карточки
                if (webinar.changedOrder != webinar.order) {
                    data.order = webinar.order = webinar.changedOrder;
                    webinar.changed = true;
                };

                if (webinar.changed) {
                    cardsPromises.push(Webinar.patch(data));
                };
                index++;
            });

            if (cardsPromises.length) {
                $q.all(cardsPromises).then(function() {
                    $timeout(function() {
                        $scope.sending = false;
                        if ($rootScope.notice) {
                            $rootScope.notice.close();
                        };
                        $rootScope.notice = $rootScope.notification({
                            text: "Изменения успешно сохранены"
                        });
                        $rootScope.notice.result.then(function(){}, function(){});
                    }, 500);
                })
            } else {
                $scope.sending = false;
            }
        };

    });

grandclassApp.controller("WebinarsEditController",
    function ($scope, $rootScope, $state, $modal, $location, $timeout,
    		  Webinar, Upload, Tag,
              webinar, tags, teachers) {

        $scope.webinar = webinar;
        $scope.tags = tags;
        $scope.teachers = teachers;
        $scope.cropper = {};

        $scope.resetHeader();
        $rootScope.pageTitle = 'Вебинары';
        $rootScope.pageTitleClick = function() {
            $state.go('base.app.webinars.list');
        };

        if ($scope.webinar.user_can_edit) {
            $rootScope.btnText = 'Просмотр';
            $rootScope.btnClick = function() {
                $state.go('base.app.webinars.view', {id: $scope.webinar.id, slug: $rootScope.slug($scope.webinar.title)});
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.webinar.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.webinar.cover;

        $scope.webinar.dateObj = {
        	date: {
        		date_start: moment($scope.webinar.date).format('DD.MM.YYYY')
        	},
        	time: moment($scope.webinar.date).format('HH:mm')
        };

        $scope.updateTags = function() {
            angular.forEach($scope.tags, function(tag) {
                tag.selected = $scope.hasTag(tag.id);
            });
        };

        $scope.hasTag = function(tagId) {
            if ($scope.webinar.tags.indexOf(''+tagId) != -1) {
                return true;
            } else return false;
        };

        $scope.updateTags();

        $scope.bannerImage = $scope.webinar.banner;
        $scope.coverImage = $scope.webinar.cover;

        $scope.dropzoneBannerOptions = {
            url: '/',
            autoProcessQueue: false,
            acceptedFiles : 'image/jpeg, images/jpg, image/png',
            addRemoveLinks : false,
            uploadMultiple: true,
            thumbnailWidth: 960,
            thumbnailHeight: null,
            dictDefaultMessage: '<span class="button button_white button_edit-bg button_icon-left">\
                                 <span class="icon-camera icon-left"></span>\
                                 <span class="button__text">Редактировать фон</span></span>',
            previewTemplate: '<img hidden data-dz-thumbnail />'
        };

        $scope.dropzoneBannerCallbacks = {
            'thumbnail': function(file, dataUrl) {
                $scope.resizeImage(dataUrl, file.name, 'banner');
            }
        };

		$scope.dropzoneCoverOptions = {
			url: '/',
			autoProcessQueue: false,
			acceptedFiles : 'image/jpeg, images/jpg, image/png',
			addRemoveLinks : false,
			uploadMultiple: true,
			thumbnailWidth: 960,
			thumbnailHeight: null,
			dictDefaultMessage: '<span class="button button_white">Сменить фото</span>',
			previewTemplate: '<img hidden data-dz-thumbnail />'
		};

		$scope.dropzoneCoverCallbacks = {
			'thumbnail': function(file, dataUrl) {
			    $scope.resizeImage(dataUrl, file.name, 'cover');
			}
		};

        $scope.saveTitle = function() {
            if (!$scope.webinar.title) {
                return false;
            };
            Webinar.patch({
                id: $scope.webinar.id,
                title: $scope.webinar.title
            }).$promise;
        };

        $scope.save = function() {
            var no_save_event = false;
            if ($scope.webinar.is_published && !$scope.profile.is_approved) {
                $scope.webinar.is_published = false;
                $rootScope.notice = $rootScope.errorNotification({
                    text: "Изменения успешно сохранены, но Вы не можете публиковать свой контент, пока Администрация проекта не одобрит Вашу кандидатуру."
                });
                no_save_event = true;
            }

            $scope.sending = true;
            var authorsId = [];
            angular.forEach($scope.webinar.authors, function (author) {
                authorsId.push(author.id);
            });
            $scope.webinar.date = moment($scope.webinar.dateObj.date.date_start + ' ' + $scope.webinar.dateObj.time, 'DD.MM.YYYY HH:mm');
            Webinar.patch({
                id: $scope.webinar.id,
                description_short: $scope.webinar.description_short,
                description_full: $scope.webinar.description_full,
                tags: $scope.webinar.tags,
                authors: authorsId,
                price: $scope.webinar.price,
                place: $scope.webinar.place,
                date: $scope.webinar.date,
                is_published: $scope.webinar.is_published
            }).$promise.then(function () {
                var uploadData = {};
                if ($scope.webinar.coverFile) {
                    uploadData.cover = $scope.webinar.coverFile;
                }
                ;
                Upload.upload({
                    url: '/api/webinars/' + $scope.webinar.id,
                    data: uploadData,
                    method: 'PATCH'
                }).then(function () {
                    $timeout(function () {
                        $scope.sending = false;
                        if (!no_save_event) {
                            if ($rootScope.notice) {
                                $rootScope.notice.close();
                            }
                            $rootScope.notice = $rootScope.notification({
                                text: "Изменения успешно сохранены"
                            });
                            $rootScope.notice.result.then(function () {
                            }, function () {
                            });
                        }
                    }, 500);
                })
            })
        };

        $scope.openModalImageCropper = function() {
            return $modal.open({
                templateUrl: '/static/views/app/cropper_modal.html',
                controller: 'ImageCropperCtrl',
                backdrop: 'static',
                windowClass: 'modal__content_big',
                resolve: {
                    instance: function () {
                        return $scope.cropper;
                    }
                }
            });
        };

        $scope.getFileFromImage = function(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        };

		$scope.resizeImage = function(file, filename, type) {
                $scope.cropper.sourceImage = file;
                if (type == 'banner') {
                    var ratio = $scope.organization.banner_ratio;
                } else {
                    var ratio = $scope.organization.cover_ratio;
                }
                $scope.cropper.ratio = ratio;
                var modalInstance = $scope.openModalImageCropper();
                modalInstance.result.then(function (instance) {
                    let img = instance.croppedImage;
                    if (type == 'banner') {
                        $scope.webinar.bannerFile = $scope.getFileFromImage(img, filename);
                        $scope.bannerImage = img;
                        Upload.upload({
                            url: '/api/webinars/' + $scope.webinar.id,
                            data: {
                                banner: $scope.getFileFromImage(img, filename)
                            },
                            method: 'PATCH'
                        });
                    } else {
                        $scope.webinar.coverFile = $scope.getFileFromImage(img, filename);
                        $scope.coverImage = img;
                    }
                });
        };

        $scope.toggleTag = function(tag) {
            var index = $scope.webinar.tags.indexOf(''+tag.id);
            if (index != -1) {
                $scope.webinar.tags.splice(index, 1);
            } else {
                if (tag.selected) {
                    $scope.webinar.tags.push(''+tag.id);
                }
            }

        };


    });

grandclassApp.controller("WebinarsViewController",
    function ($scope, $rootScope, $timeout, $sce, $http, $state, $location,
              webinar, webinarLink, profile,
              AccessRequest, Webinar) {

        $scope.webinar = webinar;
        $scope.profile = profile;
        $scope.webinar.webinarLink = webinarLink[0] ? $sce.trustAsResourceUrl(webinarLink[0].link) : '';
        $scope.webinar.record_link = $sce.trustAsResourceUrl($scope.webinar.record_link);

        $scope.resetHeader();
        $rootScope.pageTitle = 'Вебинары';

        $rootScope.pageTitleClick = function() {
            $state.go('base.app.webinars.list');
        };

        if ($scope.webinar.user_can_edit) {
            $rootScope.btnText = 'Редактировать';
            $rootScope.btnClick = function() {
                $state.go('base.app.webinars.edit', {id: $scope.webinar.id});
            };
        };

        $scope.resetMeta();
        $rootScope.metaObj.title = $scope.organization.title + ' — ' + $scope.webinar.title;
        $rootScope.metaObj.image = $location.protocol() + '://' + $location.host() + ':' + $location.port() +  $scope.webinar.cover;

        $scope.webinar.dateObj = {
            date: moment($scope.webinar.date).format('DD.MM.YYYY'),
            time: moment($scope.webinar.date).format('HH:mm')
        };

        $scope.webinar.isPast = moment($scope.webinar.date) > moment() ? false : true;

        $scope.time = 15;

        $scope.timer = function(){
            $timeout(function(){
                if($scope.time > 0){
                    $scope.time -= 1;
                    $scope.timer();
                }
            }, 1000);
        };

        $scope.webinarTurn = function (command) {
            var url = "http://my.webinar.ru/api0/Status.php?key=" +
                      $scope.organization.webinar + "&event_id=" +
                      $scope.webinar.webinar_id + "&stage=" +
                      command;
            $http({
                url: url,
                method: 'POST'
            }).then(function(response) {

            }, function(response) {
                $scope.timer();
                if (command == 'START') {
                    $timeout(function () {
                        $state.reload();
                    }, 15000);
                } else {
                    $state.reload();
                };
            });
        };

        $scope.createRequest = function () {
            $scope.sending = true;
            AccessRequest.save({user: $scope.profile.id, webinar: $scope.webinar.id}).$promise.then(function (data) {
                if (data.email != 'invalid') {
                $state.reload();
                $timeout(function() {
                    $scope.sending = false;
                    if ($rootScope.notice) {
                        $rootScope.notice.close();
                    };
                    $rootScope.notice = $rootScope.notification({
                        text: "Заявка на вебинар успешно отправлена"
                    });
                    $rootScope.notice.result.then(function(){}, function(){});
                }, 500);}
            });
        };

        $scope.goToPayments = function () {
            $scope.sending = true;
            $state.go('base.app.payments');
        };
    });

grandclassApp.controller("SiteController",
    function ($scope) {});
